import { Description } from '@mui/icons-material'
import { Button, Checkbox, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useCompany } from '../../hooks/useCompany'
import { useSelectController } from '../../hooks/useSelectController'
import { useSet } from '../../hooks/useSet'
import {
    formatClientName,
    formatMaterialAmount,
    formatMaterialIdentifier,
    usdFormatter,
} from '../../lib/formatting'
import { isMobile } from '../../lib/navigator'
import { TicketObject } from '../../lib/types/gqlTypeAbbreviations'
import { DashboardTicketsDatagridToolbar } from '../datagrid/DashboardTicketsDatagridToolbar'
import { GridContainer } from '../datagrid/GridContainer'
import { Link } from '../Link'
import { TicketModal } from '../ticket/TicketModal'

type Props = {
    tickets: TicketObject[]
    refetch: () => any
    loading?: boolean
    showMaterials?: boolean
    showPayroll?: boolean
    showRevenue?: boolean
}

export const TicketCollectionTable: React.FC<Props> = ({
    tickets,
    refetch,
    loading,
    showMaterials,
    showPayroll,
    showRevenue,
}) => {
    const { isSupplierCompany, isTruckingCompany, employeeMap } = useCompany()
    const {
        toggle: toggleSelectedTicket,
        has: isSelectedTicket,
        selectedItems: selectedTickets,
        addMany: selectManyTickets,
        clear: clearSelectedTickets,
    } = useSet(tickets, ticket => ticket.id)
    const {
        selectedItem: viewingTicket,
        removeSelection: closeModal,
        setId: setViewingTicketId,
    } = useSelectController(tickets, t => t.id)
    return (
        <>
            {viewingTicket && (
                <TicketModal
                    onClose={closeModal}
                    open={Boolean(viewingTicket)}
                    ticketId={viewingTicket.id}
                    refetch={refetch}
                />
            )}
            <GridContainer flexGrow={1} marginTop={2} minHeight={750}>
                <DataGrid
                    rows={tickets}
                    columnVisibilityModel={{
                        materialAmount: showMaterials,
                        materialName: showMaterials,
                        revenue: showRevenue,
                        payroll: showPayroll,
                        job: isTruckingCompany,
                        transporter: isSupplierCompany,
                        employee: isTruckingCompany,
                    }}
                    autoHeight={isMobile}
                    loading={loading}
                    disableSelectionOnClick
                    columns={[
                        {
                            field: 'selected',
                            headerName: '',
                            disableExport: true,
                            disableColumnMenu: true,
                            disableReorder: true,
                            sortable: false,
                            minWidth: 100,
                            renderHeader: () => {
                                const allClicked =
                                    tickets.length !== 0 &&
                                    selectedTickets.length === tickets.length
                                const someClicked =
                                    selectedTickets.length > 0 &&
                                    selectedTickets.length !== tickets.length
                                return (
                                    <Checkbox
                                        checked={allClicked}
                                        indeterminate={someClicked}
                                        onChange={() => {
                                            if (someClicked || allClicked) {
                                                clearSelectedTickets()
                                            } else {
                                                selectManyTickets(tickets)
                                            }
                                        }}
                                    />
                                )
                            },
                            renderCell: params => (
                                <Checkbox
                                    checked={isSelectedTicket(params.row)}
                                    onChange={() => toggleSelectedTicket(params.row)}
                                />
                            ),
                        },
                        {
                            field: 'date',
                            headerName: 'Date',
                            minWidth: 100,
                            valueGetter: params =>
                                moment(params.row.timestamp).format('MM-DD-YYYY'),
                            renderCell: params => (
                                <Typography>
                                    {moment(params.row.timestamp).format('MMM D')}
                                </Typography>
                            ),
                        },
                        {
                            field: 'transporter',
                            headerName: 'Transporter',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => formatClientName(params.row.transporter),
                        },
                        {
                            field: 'customer',
                            headerName: 'Customer',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => formatClientName(params.row.customer),
                        },
                        {
                            field: 'job',
                            headerName: 'Job',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => params.row.job?.name,
                        },
                        {
                            field: 'identifier',
                            headerName: 'Code',
                            minWidth: 150,
                            valueGetter: params => params.row.identifier,
                            renderCell: params => (
                                <Typography fontWeight="bold">{params.value}</Typography>
                            ),
                        },
                        {
                            field: 'employee',
                            headerName: 'Employee',
                            minWidth: 200,
                            flex: 1,
                            valueGetter: params =>
                                employeeMap.get(params.row.uploader.id).user.displayName,
                            renderCell: params =>
                                employeeMap.get(params.row.uploader.id).user.displayName,
                        },
                        {
                            field: 'materialAmount',
                            headerName: 'Material Amount',
                            minWidth: 200,
                            flex: 1,
                            valueGetter: params => formatMaterialAmount(params.row.materialAmount),
                        },
                        {
                            field: 'materialName',
                            headerName: 'Material Name',
                            minWidth: 200,
                            flex: 1,
                            valueGetter: params => {
                                return formatMaterialIdentifier(params.row.material)
                            },
                        },
                        {
                            field: 'revenue',
                            headerName: 'Revenue',
                            minWidth: 200,
                            flex: 1,
                            valueGetter: params => usdFormatter.format(params.row.revenue),
                        },
                        {
                            field: 'payroll',
                            headerName: 'Payroll',
                            minWidth: 200,
                            flex: 1,
                            valueGetter: params => usdFormatter.format(params.row.employeeEarnings),
                        },
                        {
                            field: 'badges',
                            headerName: '',
                            disableExport: true,
                            disableColumnMenu: true,
                            disableReorder: true,
                            sortable: false,
                            minWidth: 100,
                            renderCell: params => {
                                const hasInvoice = Boolean(params.row.invoice)
                                return (
                                    <>
                                        {hasInvoice && (
                                            <Link
                                                to={`/dashboard/invoice/${params.row.invoice.id}`}
                                            >
                                                <Tooltip
                                                    title={`Invoice - ${params.row.invoice.name}`}
                                                >
                                                    <Description color="primary" />
                                                </Tooltip>
                                            </Link>
                                        )}
                                    </>
                                )
                            },
                        },
                        {
                            field: 'viewBtn',
                            headerName: '',
                            disableExport: true,
                            disableColumnMenu: true,
                            disableReorder: true,
                            sortable: false,
                            minWidth: 100,
                            renderCell: params => {
                                return (
                                    <Button
                                        variant="contained"
                                        onClick={() => setViewingTicketId(params.row.id)}
                                    >
                                        View
                                    </Button>
                                )
                            },
                        },
                    ]}
                    components={{
                        Toolbar: DashboardTicketsDatagridToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            csvOptions: { allColumns: true },
                            selectedTickets,
                            refetch,
                        },
                    }}
                />
            </GridContainer>
        </>
    )
}
