import { useCompanyEmployeeQuery } from '../graphql/gen/hooks'

export const useCompanyEmployee = (id?: number) => {
    const { data, loading, refetch } = useCompanyEmployeeQuery({
        variables: {
            employeeId: id,
        },
        skip: !id,
    })
    return {
        employee: data?.companyEmployee,
        loading,
        refetch,
    }
}
