import { useMemo } from 'react'
import { useCompanyTicketsQuery } from '../graphql/gen/hooks'
import { FilterTicketParams } from '../graphql/gen/schemas'

export const useFilteredTickets = (filters?: FilterTicketParams) => {
    const { data, refetch, loading } = useCompanyTicketsQuery({
        variables: {
            params: {
                ...filters,
            },
        },
    })
    const tickets = useMemo(() => {
        if (data) {
            return data.companyTickets
        }
        return []
    }, [data?.companyTickets])
    return {
        tickets,
        refetch,
        loading,
    }
}
