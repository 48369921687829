import styled from '@emotion/styled'
import { Box } from '@mui/material'

const Video = styled.video`
    filter: blur(10px);
    transform: scale(1.1);
`

export const BackgroundVideo = () => {
    return (
        <Box position="absolute" top={1} left={1} overflow="hidden">
            <Video width={window.innerWidth} height="var(--app-height)" muted autoPlay loop>
                <source src="/static/video/dump-site-night-web.mp4" type="video/mp4" />
            </Video>
        </Box>
    )
}
