import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useEmployeeInviteCode } from '../../hooks/useEmployeeInviteCode'
import { useQueryString } from '../../hooks/queryStringHooks'
import { ChildrenProp } from '../../lib/componentProps'
import { GenericLayout } from './GenericLayout'

export const AuthLayout: React.FC<ChildrenProp> = ({ children }) => {
    const { setEmployeeInviteCode } = useEmployeeInviteCode()
    const inviteCode = useQueryString('inviteCode')
    useEffect(() => {
        if (inviteCode) {
            setEmployeeInviteCode(inviteCode)
        }
    }, [inviteCode, setEmployeeInviteCode])
    return (
        <GenericLayout>
            <Outlet />
            {children}
        </GenericLayout>
    )
}
