import { useContext } from 'react'
import { CompanyContext } from '../components/layouts/CompanyProvider'

export const useCompany = () => {
    const context = useContext(CompanyContext)
    if (!context) {
        throw new Error('You are calling useCompany outside of <CompanyProvider />.')
    }
    return context
}
