import { Control, Controller } from 'react-hook-form'
import { BaseSelectProps, UnmanagedSelect } from './UnmanagedSelect'

type Props<E, Y> = BaseSelectProps<E, Y> & {
    control: Control<any>
}

export function FormSelect<E, Y>({ name, control, ...otherProps }: Props<E, Y>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return (
                    <UnmanagedSelect
                        ref={field.ref}
                        name={name}
                        value={field.value}
                        setValue={val => field.onChange(val)}
                        {...otherProps}
                    />
                )
            }}
        />
    )
}
