import { useNavigate, NavigateOptions } from 'react-router-dom'
import { Link as MuiLink, LinkProps } from '@mui/material'

export const Link: React.FC<
    LinkProps & {
        navOptions?: NavigateOptions
        to: string
    }
> = ({ navOptions, to, ...props }) => {
    const navigate = useNavigate()
    return (
        <MuiLink
            style={{
                cursor: 'pointer',
            }}
            {...props}
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                navigate(to, navOptions)
            }}
        />
    )
}
