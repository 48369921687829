export const groupBy = <E>(items: E[], getGroup: (item: E) => string | number) => {
    const map: {
        [key: string | number]: E[]
    } = {}
    items.forEach(item => {
        const groupId = getGroup(item)
        if (!map[groupId]) {
            map[groupId] = []
        }
        map[groupId].push(item)
    })
    return map
}
