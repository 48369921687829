import { useCallback, useEffect, useState } from 'react'
import { getAuthHeaders } from '../lib/authLogic'
import axios from 'axios'

type FetchOptions = {
    method?: string
    data?: any
}

export const useFetch = <E>(
    url: string,
    options: FetchOptions = {
        method: 'GET',
    },
) => {
    const [data, setData] = useState<E | null>(null)
    const [status, setStatus] = useState<number | null>(null)
    const [loading, setLoading] = useState(false)
    const triggerRequest = useCallback(async () => {
        setLoading(true)
        const authHeaders = await getAuthHeaders()
        const res = await axios<E>({
            url,
            method: options.method,
            headers: authHeaders,
            data: options.data,
        })
        setStatus(res.status)
        setData(res.data)
        setLoading(false)
    }, [url, setLoading, setData, setStatus, options.method, options.data])
    useEffect(() => {
        if (options.method === 'GET') {
            triggerRequest()
        }
    }, [triggerRequest, options.method])
    return {
        fetch: triggerRequest,
        data,
        status,
        loading,
    }
}
