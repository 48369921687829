import { Check, CopyAll } from '@mui/icons-material'
import { Button, Popover, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { hashString } from '../lib/hash'

type Props = {
    text: string
    button?: boolean
}

export const CopyText: React.FC<Props> = ({ text, button }) => {
    const ref = useRef()
    const [showing, setShowing] = useState(false)
    const handleClick = useCallback(async () => {
        await window.navigator.clipboard.writeText(text)
        setShowing(true)
        setTimeout(() => {
            setShowing(false)
        }, 500)
    }, [text])
    const elemId = useMemo(() => hashString(text), [text])
    return (
        <>
            <Box
                display="flex"
                sx={{ cursor: 'pointer', width: 'fit-content' }}
                onClick={handleClick}
            >
                {button ? (
                    <Button
                        ref={ref}
                        aria-aria-describedby={elemId}
                        onClick={handleClick}
                        startIcon={<CopyAll />}
                    >
                        Copy
                    </Button>
                ) : (
                    <>
                        <Typography marginRight={1}>{text}</Typography>
                        <CopyAll aria-aria-describedby={elemId} ref={ref} />
                    </>
                )}
            </Box>
            <Popover
                id={elemId}
                open={showing}
                anchorEl={ref.current}
                onClose={() => setShowing(false)}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            >
                <Box display="flex" padding={1}>
                    <Typography>Coped!</Typography>
                    <Check color="success" />
                </Box>
            </Popover>
        </>
    )
}
