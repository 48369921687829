import { useMemo } from 'react'
import { useCompanySavedContactsQuery } from '../graphql/gen/hooks'

export const useContacts = () => {
    const { data, loading } = useCompanySavedContactsQuery()
    const contacts = useMemo(() => data?.companySavedContacts ?? [], [data?.companySavedContacts])
    return {
        contacts,
        loading,
    }
}
