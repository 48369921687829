import { Box, Button, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DatagridToolbarExportAndFilter } from '../../../../../components/datagrid/DatagridToolbarExportAndFilter'
import { useCompany } from '../../../../../hooks/useCompany'
import { useMapFromArray } from '../../../../../hooks/useMapFromArray'
import moment from 'moment'
import { formatMaterialAmount } from '../../../../../lib/formatting'
import { TicketModal } from '../../../../../components/ticket/TicketModal'
import { useCallback, useMemo, useState } from 'react'
import { isMobile } from '../../../../../lib/navigator'
import { useJobTickets } from '../../../../../hooks/useJobTickets'

export const JobTicketsPage: React.FC = () => {
    const { company } = useCompany()
    const employeeMap = useMapFromArray(company.employees, employee => employee.id)
    const [viewingTicketId, setViewingTicketId] = useState<number | null>(null)
    const closeModal = useCallback(() => {
        setViewingTicketId(null)
    }, [setViewingTicketId])
    const { tickets, refetch } = useJobTickets()
    const viewingTicket = useMemo(() => {
        if (viewingTicketId != null) {
            return tickets.find(ticket => ticket.id === viewingTicketId)
        }
    }, [viewingTicketId, tickets])
    return (
        <>
            {viewingTicket && (
                <TicketModal
                    onClose={closeModal}
                    open={typeof viewingTicketId === 'number'}
                    ticketId={viewingTicket.id}
                    refetch={refetch}
                />
            )}
            <Box flexGrow={1} marginTop={3}>
                <DataGrid
                    rows={tickets}
                    columnVisibilityModel={{
                        timestamp: !isMobile,
                        vehicle: !isMobile,
                    }}
                    autoHeight={isMobile}
                    columns={[
                        {
                            field: 'identifier',
                            headerName: 'Code',
                            minWidth: 150,
                            valueGetter: params => params.row.identifier,
                        },
                        {
                            field: 'timestamp',
                            headerName: 'Timestamp',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params =>
                                moment(params.row.timestamp).format('YYYY-MM-DD-HH-mm'),
                            renderCell: params => (
                                <Typography>
                                    {moment(params.row.timestamp).format('MMM Do h:mm A')}
                                </Typography>
                            ),
                        },
                        {
                            field: 'materialAmount',
                            headerName: 'Material Amount',
                            minWidth: 150,
                            valueFormatter: params => formatMaterialAmount(params.value),
                        },
                        {
                            field: 'materialType',
                            headerName: 'Material Type',
                            minWidth: 150,
                            valueGetter: params =>
                                `${params.row.material.unitPlural} of ${params.row.material.materialName}`,
                        },
                        {
                            field: 'employee',
                            headerName: 'Employee',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params =>
                                employeeMap.get(params.row.uploader.id).user.displayName,
                        },
                        {
                            field: 'viewBtn',
                            headerName: '',
                            disableExport: true,
                            minWidth: 150,
                            renderCell: params => {
                                return (
                                    <Button
                                        variant="contained"
                                        onClick={() => setViewingTicketId(params.row.id)}
                                    >
                                        View
                                    </Button>
                                )
                            },
                        },
                    ]}
                    components={{
                        Toolbar: DatagridToolbarExportAndFilter,
                    }}
                />
            </Box>
        </>
    )
}
