import moment, { Moment } from 'moment'
import { CompanyEmployeeQuery } from '../graphql/gen/operations'

export type ServerShiftObject = CompanyEmployeeQuery['companyEmployee']['pastShifts'][0]

export type ShiftPartialEmployeeObject = {
    id: number
    user: {
        id: string
        displayName: string
    }
}

export type NormalizedShiftObject = {
    shiftId: number
    dt: string
    start: Moment
    end: Moment
    ongoing: boolean
    totalHrs: number
    totalMins: number
    diffHrs: number
    diffMins: number
    employee: ShiftPartialEmployeeObject
    isSplit: boolean
}

const createNormalizedShift = (
    shiftId: number,
    start: Moment,
    end: Moment,
    ongoing: boolean,
    employee: ShiftPartialEmployeeObject,
    isSplit: boolean,
): NormalizedShiftObject => {
    const totalMins = Math.abs(end.diff(start, 'minutes'))
    return {
        shiftId,
        dt: start.format('YYYY-MM-DD'),
        start,
        end,
        ongoing,
        totalMins,
        totalHrs: totalMins / 60,
        diffHrs: Math.floor(totalMins / 60),
        diffMins: totalMins % 60,
        employee: {
            id: employee.id,
            user: employee.user,
        },
        isSplit,
    }
}

export const normalizeShift = (shift: ServerShiftObject, employee: ShiftPartialEmployeeObject) => {
    const normalizedShifts: NormalizedShiftObject[] = []
    const ongoing = !Boolean(shift.timestampEnd)
    const dtStart = moment(shift.timestampStart)
    const dtEnd = ongoing ? moment() : moment(shift.timestampEnd)
    if (dtStart.isSame(dtEnd, 'day')) {
        normalizedShifts.push(
            createNormalizedShift(
                shift.id,
                dtStart.clone(),
                dtEnd.clone(),
                ongoing,
                employee,
                false,
            ),
        )
    } else {
        normalizedShifts.push(
            createNormalizedShift(
                shift.id,
                dtStart.clone(),
                dtStart.clone().endOf('day'),
                ongoing,
                employee,
                true,
            ),
        )
        const counter = dtStart.clone().add(1, 'day')
        while (!counter.isSame(dtEnd, 'day')) {
            normalizedShifts.push(
                createNormalizedShift(
                    shift.id,
                    counter.clone().startOf('day'),
                    counter.clone().endOf('day'),
                    ongoing,
                    employee,
                    true,
                ),
            )
            counter.add(1, 'day')
        }
        normalizedShifts.push(
            createNormalizedShift(
                shift.id,
                dtEnd.clone().startOf('day'),
                dtEnd.clone(),
                ongoing,
                employee,
                true,
            ),
        )
    }
    return normalizedShifts
}

export const formatMinuteDurationString = (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const extraMinutes = minutes % 60
    if (hours > 0) {
        return `${hours}h ${extraMinutes}m`
    }
    return `${minutes}m`
}
