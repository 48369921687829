import { Box } from '@mui/material'
import React from 'react'
import { ChildrenProp } from '../../lib/componentProps'
import { Toolbar } from '../controls/Toolbar'

export const GenericLayout: React.FC<ChildrenProp> = ({ children }) => {
    return (
        <Box>
            <Toolbar />
            <Box display="flex" flexDirection="column" marginTop={8}>
                {children}
            </Box>
        </Box>
    )
}
