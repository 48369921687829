import styled from '@emotion/styled'
import { Delete, Download, ExitToApp } from '@mui/icons-material'
import {
    Box,
    Button,
    Chip,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetTicketQuery } from '../../graphql/gen/operations'
import { useOcrData } from '../../hooks/useOcrData'
import { useVisibilityState } from '../../hooks/useVisibilityState'
import { formatMaterialAmountPhrase, usdFormatter } from '../../lib/formatting'
import { CopyText } from '../CopyText'
import { ConfirmDeleteTicketModal } from './ConfirmDeleteTicketModal'

const ImageComponent = styled.img`
    object-fit: contain;
    max-width: 100%;
`

type Props = {
    ticket: GetTicketQuery['ticket']
    exitView: () => void
    refetch: () => Promise<void>
}

export const TicketDataView: React.FC<Props> = ({ ticket, refetch, exitView }) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const {
        isVisible: isConfirmDeleteModalOpen,
        show: openSubModal,
        hide: closeSubModal,
    } = useVisibilityState()
    const imgUrl = useMemo(() => `/api/file/${ticket.file.id}`, [ticket])
    const ocrData = useOcrData(ticket.ocrFile?.id)
    const newFileName = useMemo(() => {
        const fileName = ticket.file.originalFileName
        const lastIndex = fileName.lastIndexOf('.')
        if (lastIndex !== -1) {
            const ext = fileName.substring(lastIndex + 1, fileName.length)
            return `Ticket-${ticket.identifier}.${ext}`
        }
        return fileName
    }, [ticket])
    return (
        <>
            <ConfirmDeleteTicketModal
                ticketId={ticket.id}
                identifier={ticket.identifier}
                open={isConfirmDeleteModalOpen}
                onClose={closeSubModal}
                onDelete={refetch}
            />
            <Typography variant="h5" marginBottom={2}>
                Ticket - {ticket.identifier}
            </Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight="bold">Timestamp</Typography>
                        </TableCell>
                        <TableCell>
                            {moment(ticket.timestamp).format('MMM Do YYYY, h:mm A')}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight="bold">Identifier</Typography>
                        </TableCell>
                        <TableCell>
                            <CopyText text={ticket.identifier} />
                        </TableCell>
                    </TableRow>
                    {ticket.job && (
                        <TableRow>
                            <TableCell>
                                <Typography fontWeight="bold">Job</Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography marginRight={2}>{ticket.job?.name}</Typography>
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        navigate(
                                            `/${
                                                pathname.startsWith('/dashboard')
                                                    ? 'dashboard'
                                                    : 'console'
                                            }/job/${ticket.job?.id}`,
                                        )
                                    }
                                >
                                    View Job
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                    {ticket.issuer && (
                        <TableRow>
                            <TableCell>
                                <Typography fontWeight="bold">Issuer</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{ticket.issuer?.name}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {ticket.transporter && (
                        <TableRow>
                            <TableCell>
                                <Typography fontWeight="bold">Transporter</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{ticket.transporter?.name}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {ticket.customer && (
                        <TableRow>
                            <TableCell>
                                <Typography fontWeight="bold">Customer</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{ticket.customer?.name}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight="bold">Material</Typography>
                        </TableCell>
                        <TableCell>
                            {formatMaterialAmountPhrase(
                                ticket.material.materialName,
                                ticket.material.unitSingular,
                                ticket.material.unitPlural,
                                ticket.materialAmount,
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight="bold">Revenue</Typography>
                        </TableCell>
                        <TableCell>{usdFormatter.format(ticket.revenue)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight="bold">Notes</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>{ticket.notes}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box
                marginY={2}
                display="flex"
                flex="1 1"
                width="100%"
                maxHeight={window.innerHeight * 0.6}
            >
                <ImageComponent src={imgUrl} />
            </Box>
            <Box display="flex" flexWrap="wrap" marginY={3}>
                {ocrData?.map(item => {
                    return (
                        <Box marginRight={1} marginBottom={1}>
                            <Chip label={item.text} />
                        </Box>
                    )
                })}
            </Box>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        startIcon={<ExitToApp />}
                        onClick={exitView}
                    >
                        Close
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        startIcon={<Download />}
                        onClick={async () => {
                            const resp = await fetch(imgUrl)
                            const respData = await resp.blob()
                            const url = window.URL.createObjectURL(respData)
                            const a = document.createElement('a')
                            a.style.display = 'none'
                            a.href = url
                            a.download = newFileName
                            document.body.appendChild(a)
                            a.click()
                            window.URL.revokeObjectURL(url)
                            document.removeChild(a)
                        }}
                    >
                        Download
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        startIcon={<Delete />}
                        onClick={openSubModal}
                    >
                        Delete
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}
