import { Cancel, Send } from '@mui/icons-material'
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { SimpleModal } from '../../../../components/SimpleModal'
import { useCompany } from '../../../../hooks/useCompany'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateEmployeeInviteMutation } from '../../../../graphql/gen/hooks'
import { useSnackbar } from 'notistack'

type Props = {
    open: boolean
    onClose: () => void
}

const formSchema = yup.object().shape({
    name: yup
        .string()
        .min(2, 'Their name must include at least 2 letters...')
        .required("You must provide your employee's name"),
    phone: yup
        .string()
        .length(10, 'The phone number must have exactly 10 digits')
        .required('You must provide a valid phone number'),
    message: yup.string().optional(),
})

export const InviteEmployeeModal: React.FC<Props> = ({ open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { refetch } = useCompany()
    const [createEmployeeInvite] = useCreateEmployeeInviteMutation()
    const { handleSubmit, register, formState } = useForm<{
        name: string
        phone: string
        message: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const formErrors = formState.errors
    const onSubmit = handleSubmit(async data => {
        await createEmployeeInvite({
            variables: {
                name: data.name,
                message: data.message,
                phone: data.phone,
            },
        })
        await refetch()
        onClose()
        enqueueSnackbar('Sent employee invitation.', {
            variant: 'success',
        })
    })
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Invite New Employee</Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Employee's name"
                        autoComplete="name"
                        error={Boolean(formErrors.name)}
                        helperText={
                            formErrors.name ? (
                                <Typography>{formErrors.name.message}</Typography>
                            ) : null
                        }
                        {...register('name')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Phone number"
                        autoComplete="phone"
                        error={Boolean(formErrors.phone)}
                        helperText={
                            formErrors.phone ? (
                                <Typography>{formErrors.phone.message}</Typography>
                            ) : null
                        }
                        {...register('phone')}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Message to employee"
                        autoComplete="message"
                        error={Boolean(formErrors.message)}
                        helperText={
                            formErrors.message ? (
                                <Typography>{formErrors.message.message}</Typography>
                            ) : null
                        }
                        {...register('message')}
                    />
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<Send />}
                                disabled={!formState.isValid || formState.isSubmitting}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
