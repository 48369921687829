import { Alert, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

type Props = {
    showNoLocationsAlert?: boolean
    showNoVehiclesAlert?: boolean
    showNoSupplierMaterialsAlert?: boolean
    showQuickBooksAlert?: boolean
}

export const DashboardOverviewAlerts: React.FC<Props> = ({
    showNoLocationsAlert,
    showNoVehiclesAlert,
    showNoSupplierMaterialsAlert,
    showQuickBooksAlert,
}) => {
    const navigate = useNavigate()
    return (
        <>
            <Alert severity="info" sx={{ marginBottom: 2 }}>
                Do you have GPS tracking on your truck(s)? We support GPS tracking with external
                services such as "GPS Insight", "Bouncie" and more! Get in contact to learn more.
            </Alert>
            {showQuickBooksAlert && (
                <Alert
                    severity="info"
                    action={
                        <Button color="inherit" onClick={() => navigate('/dashboard/integrations')}>
                            Explore
                        </Button>
                    }
                    sx={{ marginBottom: 2 }}
                >
                    FleetMage can integrate with your QuickBooks account to generate invoices and
                    synchronize customers.
                </Alert>
            )}
            {showNoLocationsAlert && (
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" onClick={() => navigate('/dashboard/locations')}>
                            Solve
                        </Button>
                    }
                    sx={{ marginBottom: 2 }}
                >
                    You have no saved locations on file, please add at least one before creating a
                    job.
                </Alert>
            )}
            {showNoVehiclesAlert && (
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" onClick={() => navigate('/dashboard/vehicles')}>
                            Solve
                        </Button>
                    }
                    sx={{ marginBottom: 2 }}
                >
                    You have no vehicles registered, you need to register at least one before
                    starting to use FleetMage...
                </Alert>
            )}
            {showNoSupplierMaterialsAlert && (
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" onClick={() => navigate('/dashboard/materials')}>
                            Solve
                        </Button>
                    }
                    sx={{ marginBottom: 2 }}
                >
                    You have no materials listed, you need to register at least one before starting
                    to use FleetMage...
                </Alert>
            )}
        </>
    )
}
