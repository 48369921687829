import { Add } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { DatagridToolbarExportAndFilter } from '../../../../../components/datagrid/DatagridToolbarExportAndFilter'
import { useJob } from '../../../../../hooks/useJob'
import { useVisibilityState } from '../../../../../hooks/useVisibilityState'
import { isMobile } from '../../../../../lib/navigator'
import { JobAssignEmployeeModal } from './JobAssignEmployeeModal'

export const JobEmployeesPage: React.FC = () => {
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const { job } = useJob()
    return (
        <>
            <JobAssignEmployeeModal open={isVisible} onClose={closeModal} />
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={openModal} startIcon={<Add />}>
                    Assign more employees
                </Button>
            </Box>
            <Box flexGrow={1} marginTop={3}>
                <DataGrid
                    autoHeight={isMobile}
                    rows={job.jobAssignments}
                    columns={[
                        {
                            field: 'employee',
                            headerName: 'Employee',
                            disableExport: true,
                            flex: 1,
                            valueGetter: params => params.row.employee.user.displayName,
                        },
                        {
                            field: 'vehicle',
                            headerName: 'Vehicle',
                            disableExport: true,
                            flex: 1,
                            valueGetter: params => params.row.vehicle.vehicleId,
                        },
                    ]}
                    components={{
                        Toolbar: DatagridToolbarExportAndFilter,
                    }}
                />
            </Box>
        </>
    )
}
