export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any
}

export type BulkUpdateTicketInput = {
    customerId?: InputMaybe<Scalars['Int']>
    invoiceId?: InputMaybe<Scalars['Int']>
    issuerId?: InputMaybe<Scalars['Int']>
    removeInvoice?: InputMaybe<Scalars['Boolean']>
    ticketIds: Array<Scalars['Int']>
    transporterId?: InputMaybe<Scalars['Int']>
}

export type CloudFile = {
    __typename?: 'CloudFile'
    bucket: Scalars['String']
    createdAt: Scalars['DateTime']
    id: Scalars['Int']
    key: Scalars['String']
    mimeType: Scalars['String']
    originalFileName: Scalars['String']
    size: Scalars['Int']
    updatedAt: Scalars['DateTime']
}

export type Company = {
    __typename?: 'Company'
    companyType: CompanyType
    createdAt: Scalars['DateTime']
    createdByUserId: Scalars['String']
    employees: Array<EmployeeAccount>
    id: Scalars['Int']
    jobs: Array<Job>
    name: Scalars['String']
    recordType: CompanyRecordType
    savedContacts: Array<SavedContact>
    savedLocations: Array<SavedLocation>
    updatedAt: Scalars['DateTime']
    vehicles: Array<Vehicle>
}

export type CompanyJobsArgs = {
    includeDone?: InputMaybe<Scalars['Boolean']>
}

export enum CompanyRecordType {
    Demo = 'DEMO',
    Real = 'REAL',
    Test = 'TEST',
}

export enum CompanyType {
    Supplier = 'SUPPLIER',
    Trucking = 'TRUCKING',
}

export type CreateJobMaterialInput = {
    expectedAmountForJob: Scalars['Float']
    expectedAmountPerTicket: Scalars['Float']
    materialName: Scalars['String']
    pricePerUnit: Scalars['Float']
    pricePerUnitEmployee: Scalars['Float']
    unitPlural: Scalars['String']
    unitSingular: Scalars['String']
}

export type CreateSavedContactInput = {
    emailAddress?: InputMaybe<Scalars['String']>
    name: Scalars['String']
    notes?: InputMaybe<Scalars['String']>
    phoneNumber?: InputMaybe<Scalars['String']>
    quickBooksCustomerId?: InputMaybe<Scalars['String']>
}

export type CreateSupplierMaterialInput = {
    expectedAmountPerTicket: Scalars['Float']
    materialName: Scalars['String']
    pricePerUnit: Scalars['Float']
    unitPlural: Scalars['String']
    unitSingular: Scalars['String']
}

export type CreateTicketInput = {
    customerId?: InputMaybe<Scalars['Int']>
    fileId: Scalars['Int']
    identifier: Scalars['String']
    issuerId?: InputMaybe<Scalars['Int']>
    jobMaterialId?: InputMaybe<Scalars['Int']>
    materialAmount: Scalars['Float']
    notes?: InputMaybe<Scalars['String']>
    supplierMaterialId?: InputMaybe<Scalars['Int']>
    timestamp: Scalars['String']
    transporterId?: InputMaybe<Scalars['Int']>
}

export type EmployeeAccount = {
    __typename?: 'EmployeeAccount'
    activeShift?: Maybe<EmployeeShift>
    assignedVehicle?: Maybe<Vehicle>
    company: Company
    createdAt: Scalars['DateTime']
    employeeType: EmployeeType
    id: Scalars['Int']
    isOwner: Scalars['Boolean']
    pastShifts: Array<EmployeeShift>
    tickets: Array<Ticket>
    updatedAt: Scalars['DateTime']
    user: FirebaseUser
    userId: Scalars['String']
}

export type EmployeeAccountTicketsArgs = {
    skip?: InputMaybe<Scalars['Int']>
    take?: InputMaybe<Scalars['Int']>
}

export type EmployeeEarningsObject = {
    __typename?: 'EmployeeEarningsObject'
    amountEarned: Scalars['Float']
    id: Scalars['Int']
    jobId: Scalars['Int']
    jobMaterialId: Scalars['Int']
    jobName: Scalars['String']
    materialAmount: Scalars['Float']
    materialName: Scalars['String']
    pricePerUnit: Scalars['Float']
    ticketCount: Scalars['Int']
    unitPlural: Scalars['String']
    unitSingular: Scalars['String']
}

export type EmployeeInvite = {
    __typename?: 'EmployeeInvite'
    company: Company
    createdAt: Scalars['DateTime']
    email: Scalars['String']
    id: Scalars['Int']
    message: Scalars['String']
    personName: Scalars['String']
    phoneNumber: Scalars['String']
    updatedAt: Scalars['DateTime']
}

export type EmployeeShift = {
    __typename?: 'EmployeeShift'
    createdAt: Scalars['DateTime']
    id: Scalars['Int']
    notes: Scalars['String']
    timestampEnd?: Maybe<Scalars['DateTime']>
    timestampStart: Scalars['DateTime']
    updatedAt: Scalars['DateTime']
}

export enum EmployeeType {
    Normal = 'NORMAL',
    ShiftOnly = 'SHIFT_ONLY',
}

export type FilterTicketParams = {
    customerId?: InputMaybe<Scalars['Int']>
    employeeId?: InputMaybe<Scalars['Int']>
    endDt?: InputMaybe<Scalars['String']>
    hideInvoicedTickets?: InputMaybe<Scalars['Boolean']>
    invoiceId?: InputMaybe<Scalars['Int']>
    issuerId?: InputMaybe<Scalars['Int']>
    jobId?: InputMaybe<Scalars['Int']>
    startDt?: InputMaybe<Scalars['String']>
    transporterId?: InputMaybe<Scalars['Int']>
}

export type FirebaseUser = {
    __typename?: 'FirebaseUser'
    displayName: Scalars['String']
    email?: Maybe<Scalars['String']>
    employeeAccount?: Maybe<EmployeeAccount>
    id: Scalars['String']
    phoneNumber: Scalars['String']
    uid: Scalars['String']
}

export type GlobalMaterial = {
    __typename?: 'GlobalMaterial'
    expectedAmountPerTicket: Scalars['Float']
    id: Scalars['Int']
    materialName: Scalars['String']
    pricePerUnit: Scalars['Float']
    type: GlobalMaterialType
    unitPlural: Scalars['String']
    unitSingular: Scalars['String']
}

export enum GlobalMaterialType {
    JobTicket = 'JOB_TICKET',
    SupplierTicket = 'SUPPLIER_TICKET',
}

export type Invoice = {
    __typename?: 'Invoice'
    createdAt: Scalars['DateTime']
    customer: SavedContact
    id: Scalars['Int']
    lineItems: Array<InvoiceLineItem>
    name: Scalars['String']
    quickBooksInvoiceId?: Maybe<Scalars['String']>
    tickets: Array<Ticket>
    updatedAt: Scalars['DateTime']
}

export type InvoiceLineItem = {
    __typename?: 'InvoiceLineItem'
    Amount: Scalars['Float']
    Description: Scalars['String']
    DetailType: Scalars['String']
    SalesItemLineDetail: InvoiceLineItemSalesItemLineDetail
}

export type InvoiceLineItemSalesItemLineDetail = {
    __typename?: 'InvoiceLineItemSalesItemLineDetail'
    Qty: Scalars['Float']
    UnitPrice: Scalars['Float']
}

export type Job = {
    __typename?: 'Job'
    client?: Maybe<SavedContact>
    company: Company
    createdAt: Scalars['DateTime']
    destination: SavedLocation
    dispatchedAt: Scalars['String']
    id: Scalars['Int']
    instructions: Scalars['String']
    jobAssignments: Array<JobEmployeeAssignment>
    materials: Array<JobMaterial>
    monitorId: Scalars['String']
    name: Scalars['String']
    origin: SavedLocation
    progress: JobProgressObject
    status: JobStatus
    updatedAt: Scalars['DateTime']
}

export type JobEmployeeAssignment = {
    __typename?: 'JobEmployeeAssignment'
    createdAt: Scalars['DateTime']
    disabledAt: Scalars['String']
    employee: EmployeeAccount
    id: Scalars['Int']
    job: Job
    updatedAt: Scalars['DateTime']
    vehicle: Vehicle
}

export type JobMaterial = {
    __typename?: 'JobMaterial'
    createdAt: Scalars['DateTime']
    disabledAt: Scalars['String']
    effectivePricePerUnit: Scalars['Float']
    expectedAmountPerTicket: Scalars['Float']
    expectedAmountTotal: Scalars['Float']
    id: Scalars['Int']
    job: Job
    materialName: Scalars['String']
    pricePerUnit: Scalars['Float']
    pricePerUnitEmployee: Scalars['Float']
    progress: JobMaterialProgressObject
    tickets: Array<Ticket>
    unitPlural: Scalars['String']
    unitSingular: Scalars['String']
    updatedAt: Scalars['DateTime']
}

export type JobMaterialProgressObject = {
    __typename?: 'JobMaterialProgressObject'
    id: Scalars['Int']
    materialProgress: Scalars['Float']
    materialTarget: Scalars['Float']
    progressPercent: Scalars['Float']
    ticketCount: Scalars['Int']
    ticketTarget: Scalars['Int']
}

export type JobProgressObject = {
    __typename?: 'JobProgressObject'
    id: Scalars['Int']
    progressPercent: Scalars['Float']
    ticketCount: Scalars['Int']
    ticketTarget: Scalars['Int']
}

export enum JobStatus {
    Done = 'DONE',
    InProgress = 'IN_PROGRESS',
    Pending = 'PENDING',
}

export type Mutation = {
    __typename?: 'Mutation'
    assignEmployeeToJob: JobEmployeeAssignment
    assignVehicleToEmployee: Scalars['Boolean']
    bulkUpdateTickets: Array<Ticket>
    claimEmployeeInvite: EmployeeAccount
    createCompany: Company
    createEmployeeInvite: EmployeeInvite
    createInvoice: Invoice
    createJob: Job
    createJobMaterial: JobMaterial
    createSavedContact: SavedContact
    createSavedLocation: SavedLocation
    createSupplierMaterial: SupplierMaterial
    createTicket: Ticket
    createVehicle: Vehicle
    deleteTicket: Scalars['Boolean']
    dispatchJob: Scalars['Boolean']
    endShift: EmployeeShift
    exportInvoiceToQuickBooks: Scalars['String']
    finishJob: Scalars['Boolean']
    reopenJob: Scalars['Boolean']
    startShift: EmployeeShift
    updateJob: Job
    updateJobMaterial: JobMaterial
    updateSavedContact: SavedContact
    updateSupplierMaterial: JobMaterial
    updateTicket: Ticket
    updateVehicle: Vehicle
}

export type MutationAssignEmployeeToJobArgs = {
    employeeId: Scalars['Int']
    jobId: Scalars['Int']
    vehicleId: Scalars['Int']
}

export type MutationAssignVehicleToEmployeeArgs = {
    employeeId?: InputMaybe<Scalars['Int']>
    vehicleId?: InputMaybe<Scalars['String']>
}

export type MutationBulkUpdateTicketsArgs = {
    input: BulkUpdateTicketInput
}

export type MutationClaimEmployeeInviteArgs = {
    inviteCode: Scalars['String']
}

export type MutationCreateCompanyArgs = {
    inviteCode: Scalars['String']
    name: Scalars['String']
}

export type MutationCreateEmployeeInviteArgs = {
    message: Scalars['String']
    name: Scalars['String']
    phone: Scalars['String']
}

export type MutationCreateInvoiceArgs = {
    customerId: Scalars['Int']
    name: Scalars['String']
}

export type MutationCreateJobArgs = {
    clientId?: InputMaybe<Scalars['Int']>
    destinationId: Scalars['Int']
    instructions: Scalars['String']
    name: Scalars['String']
    originId: Scalars['Int']
}

export type MutationCreateJobMaterialArgs = {
    jobId: Scalars['Int']
    materialInput: CreateJobMaterialInput
}

export type MutationCreateSavedContactArgs = {
    input: CreateSavedContactInput
}

export type MutationCreateSavedLocationArgs = {
    city: Scalars['String']
    lat: Scalars['Float']
    line1: Scalars['String']
    lng: Scalars['Float']
    name: Scalars['String']
    state: Scalars['String']
    zip: Scalars['String']
}

export type MutationCreateSupplierMaterialArgs = {
    materialInput: CreateSupplierMaterialInput
}

export type MutationCreateTicketArgs = {
    input: CreateTicketInput
}

export type MutationCreateVehicleArgs = {
    assignedEmployeeId?: InputMaybe<Scalars['Int']>
    description?: InputMaybe<Scalars['String']>
    vehicleId: Scalars['String']
}

export type MutationDeleteTicketArgs = {
    id: Scalars['Int']
}

export type MutationDispatchJobArgs = {
    jobId: Scalars['Int']
}

export type MutationExportInvoiceToQuickBooksArgs = {
    invoiceId: Scalars['Int']
}

export type MutationFinishJobArgs = {
    jobId: Scalars['Int']
}

export type MutationReopenJobArgs = {
    jobId: Scalars['Int']
}

export type MutationUpdateJobArgs = {
    clientId?: InputMaybe<Scalars['Int']>
    destinationId?: InputMaybe<Scalars['Int']>
    id: Scalars['Int']
    instructions?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
    originId?: InputMaybe<Scalars['Int']>
}

export type MutationUpdateJobMaterialArgs = {
    materialId: Scalars['Int']
    materialInput: UpdateJobMaterialInput
}

export type MutationUpdateSavedContactArgs = {
    id: Scalars['Int']
    input: UpdateSavedContactInput
}

export type MutationUpdateSupplierMaterialArgs = {
    materialId: Scalars['Int']
    materialInput: UpdateSupplierMaterialInput
}

export type MutationUpdateTicketArgs = {
    input: UpdateTicketInput
}

export type MutationUpdateVehicleArgs = {
    description?: InputMaybe<Scalars['String']>
    vehicleId: Scalars['String']
}

export type Query = {
    __typename?: 'Query'
    companyEmployee: EmployeeAccount
    companyJobs: Array<Job>
    companySavedContacts: Array<SavedContact>
    companySavedLocations: Array<SavedLocation>
    companyTickets: Array<Ticket>
    doesTicketIdentifierExist: Scalars['Boolean']
    employeeEarningsItems: Array<EmployeeEarningsObject>
    getCompany: Company
    getCompanyEmployeeInvites: Array<EmployeeInvite>
    health: Scalars['Float']
    invoice: Invoice
    job: Job
    listEmployees: Array<EmployeeAccount>
    listInvoices: Array<Invoice>
    myJobAssignments: Array<JobEmployeeAssignment>
    supplierMaterials: Array<SupplierMaterial>
    ticket: Ticket
    whoAmI: FirebaseUser
}

export type QueryCompanyEmployeeArgs = {
    employeeId: Scalars['Int']
}

export type QueryCompanyJobsArgs = {
    includeDone?: InputMaybe<Scalars['Boolean']>
}

export type QueryCompanyTicketsArgs = {
    params: FilterTicketParams
    skip?: InputMaybe<Scalars['Int']>
    take?: InputMaybe<Scalars['Int']>
}

export type QueryDoesTicketIdentifierExistArgs = {
    identifier: Scalars['String']
    jobId?: InputMaybe<Scalars['Int']>
}

export type QueryInvoiceArgs = {
    id: Scalars['Int']
}

export type QueryJobArgs = {
    id: Scalars['Int']
}

export type QueryTicketArgs = {
    ticketId: Scalars['Int']
}

export type SavedContact = {
    __typename?: 'SavedContact'
    company: Company
    createdAt: Scalars['DateTime']
    emailAddress?: Maybe<Scalars['String']>
    id: Scalars['Int']
    name: Scalars['String']
    notes: Scalars['String']
    phoneNumber?: Maybe<Scalars['String']>
    quickBooksCustomerId?: Maybe<Scalars['String']>
    updatedAt: Scalars['DateTime']
}

export type SavedLocation = {
    __typename?: 'SavedLocation'
    city: Scalars['String']
    company: Company
    createdAt: Scalars['DateTime']
    id: Scalars['Int']
    latitude: Scalars['Float']
    line1: Scalars['String']
    line2: Scalars['String']
    longitude: Scalars['Float']
    name: Scalars['String']
    state: Scalars['String']
    updatedAt: Scalars['DateTime']
    zip: Scalars['String']
}

export type SupplierMaterial = {
    __typename?: 'SupplierMaterial'
    company: Company
    createdAt: Scalars['DateTime']
    expectedAmountPerTicket: Scalars['Float']
    id: Scalars['Int']
    materialName: Scalars['String']
    pricePerUnit: Scalars['Float']
    unitPlural: Scalars['String']
    unitSingular: Scalars['String']
    updatedAt: Scalars['DateTime']
}

export type Ticket = {
    __typename?: 'Ticket'
    createdAt: Scalars['DateTime']
    customer?: Maybe<SavedContact>
    employeeEarnings: Scalars['Float']
    file: CloudFile
    id: Scalars['Int']
    identifier: Scalars['String']
    invoice?: Maybe<Invoice>
    issuer?: Maybe<SavedContact>
    job?: Maybe<Job>
    material: GlobalMaterial
    materialAmount: Scalars['Float']
    notes: Scalars['String']
    ocrFile?: Maybe<CloudFile>
    revenue: Scalars['Float']
    timestamp: Scalars['DateTime']
    transporter?: Maybe<SavedContact>
    updatedAt: Scalars['DateTime']
    uploader: EmployeeAccount
}

export type UpdateJobMaterialInput = {
    expectedAmountForJob?: InputMaybe<Scalars['Float']>
    expectedAmountPerTicket?: InputMaybe<Scalars['Float']>
    pricePerUnit?: InputMaybe<Scalars['Float']>
    pricePerUnitEmployee?: InputMaybe<Scalars['Float']>
}

export type UpdateSavedContactInput = {
    emailAddress?: InputMaybe<Scalars['String']>
    name?: InputMaybe<Scalars['String']>
    notes?: InputMaybe<Scalars['String']>
    phoneNumber?: InputMaybe<Scalars['String']>
    quickBooksCustomerId?: InputMaybe<Scalars['String']>
}

export type UpdateSupplierMaterialInput = {
    expectedAmountPerTicket?: InputMaybe<Scalars['Float']>
    pricePerUnit?: InputMaybe<Scalars['Float']>
}

export type UpdateTicketInput = {
    customerId?: InputMaybe<Scalars['Int']>
    invoiceId?: InputMaybe<Scalars['Int']>
    issuerId?: InputMaybe<Scalars['Int']>
    removeInvoice?: InputMaybe<Scalars['Boolean']>
    ticketId: Scalars['Int']
    transporterId?: InputMaybe<Scalars['Int']>
}

export type Vehicle = {
    __typename?: 'Vehicle'
    assignedEmployee?: Maybe<EmployeeAccount>
    company: Company
    createdAt: Scalars['DateTime']
    description: Scalars['String']
    id: Scalars['Int']
    updatedAt: Scalars['DateTime']
    vehicleId: Scalars['String']
}
