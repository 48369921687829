import styled from '@emotion/styled'
import { Alert, Box, Button, Card, Typography } from '@mui/material'

const UnstyledAnchor = styled.a`
    color: inherit;
`

export const DashboardBillingPage = () => {
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            <Typography variant="h3" marginBottom={3}>
                Billing
            </Typography>
            <Box marginBottom={3}>
                <Alert severity="info">
                    Billing is handled by FleetMage's financial services vendor - Stripe, a global
                    complete payments platform.
                </Alert>
            </Box>
            <Typography variant="body1" marginBottom={3}>
                To manage your billing, you can click the button below to be redirected to a Stripe
                customer portal.
            </Typography>
            <UnstyledAnchor
                href="https://billing.fleetmage.com/p/login/dR629K04S6dN3YsaEE"
                target="_blank"
            >
                <Button variant="contained">Visit Billing Portal</Button>
            </UnstyledAnchor>
        </Card>
    )
}
