import { useCallback, useMemo } from 'react'
import { useAssignVehicleToEmployeeMutation } from '../../graphql/gen/hooks'
import { GetCompanyQuery } from '../../graphql/gen/operations'
import { useCompany } from '../../hooks/useCompany'
import { useSnackbar } from 'notistack'
import { SPECIAL_OPTIONS, UnmanagedSelect } from './Select/UnmanagedSelect'

type Props = {
    vehicle: GetCompanyQuery['getCompany']['vehicles'][number]
}

export const VehicleAssignedEmployeeDropdown: React.FC<Props> = ({ vehicle }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { company, refetch } = useCompany()
    const [assignVehicleToEmployee] = useAssignVehicleToEmployeeMutation()
    const callback = useCallback(
        async (employeeId?: string | number) => {
            if (employeeId !== vehicle.vehicleId) {
                await assignVehicleToEmployee({
                    variables: {
                        vehicleId: vehicle.vehicleId,
                        employeeId:
                            employeeId === 'none' ? undefined : parseInt(employeeId.toString()),
                    },
                })
                await refetch()
                enqueueSnackbar(`Successfully assigned employee to vehicle ${vehicle.vehicleId}.`, {
                    variant: 'success',
                })
            }
        },
        [assignVehicleToEmployee, vehicle, refetch],
    )
    const freeEmployees = useMemo(
        () =>
            company.employees.filter(
                e => e.assignedVehicle?.vehicleId === vehicle?.vehicleId || !e.assignedVehicle,
            ),
        [company.employees],
    )
    return (
        <UnmanagedSelect
            value={vehicle.assignedEmployee?.id ?? 'none'}
            setValue={v => callback(v)}
            specialOptions={[SPECIAL_OPTIONS.NONE]}
            items={freeEmployees}
            getValueFn={e => e.id}
            getLabelFn={e => e.user.displayName}
        />
    )
}
