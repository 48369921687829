import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAuthHeaders } from '../lib/authLogic'

export const apolloCache = new InMemoryCache()

const authLink = setContext(async (_, context) => {
    const authHeaders = await getAuthHeaders(context.headers)
    return {
        headers: authHeaders,
    }
})

export const apollo = new ApolloClient({
    cache: apolloCache,
    link: authLink.concat(
        new HttpLink({
            uri: '/api/graphql',
        }),
    ),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
})
