import React, { useMemo } from 'react'
import { appConfig } from '../../appConfig'
import { useVehicleGps } from '../../hooks/useVehicleGps'
import { MapMarker } from './MapMarker'

type Props = {
    vehicleIdentifiers?: string[]
}

export const MapVehicleMarkers: React.FC<Props> = ({ vehicleIdentifiers }) => {
    const gpsData = useVehicleGps()
    const trackedVehicles = useMemo(() => {
        return gpsData.filter(vehicle => {
            if (!appConfig.isProduction || vehicleIdentifiers === undefined) {
                return true
            }
            for (const identifier of vehicleIdentifiers) {
                if (vehicle.label.includes(identifier)) {
                    return true
                }
            }
            return false
        })
    }, [gpsData, vehicleIdentifiers])
    return (
        <>
            {(trackedVehicles ?? []).map(vehicle => (
                <MapMarker
                    key={vehicle.id}
                    loc={{ lat: vehicle.latitude, lng: vehicle.longitude }}
                    color="purple"
                    text={vehicle.label}
                />
            ))}
        </>
    )
}
