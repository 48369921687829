import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { useMemo } from 'react'

export const useQueryObject = () => {
    const location = useLocation()
    const queryObject = useMemo(() => {
        return parse(location.search)
    }, [location.search])
    return queryObject
}

export const useQueryString = (key: string) => {
    const queryObject = useQueryObject()
    if (key in queryObject) {
        return queryObject[key] as string
    }
    return null
}

export const useQueryNumber = (key: string) => {
    const str = useQueryString(key)
    if (!str) {
        return null
    }
    return parseInt(str)
}
