import { Cancel, Delete, Save } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography,
} from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { SimpleModal } from '../../../../../components/SimpleModal'
import { useCompany } from '../../../../../hooks/useCompany'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useJob } from '../../../../../hooks/useJob'
import { useCallback, useMemo, useState } from 'react'
import { useAssignEmployeeToJobMutation } from '../../../../../graphql/gen/hooks'
import { useSnackbar } from 'notistack'
import { JobStatus } from '../../../../../graphql/gen/schemas'

type Props = {
    open: boolean
    onClose: () => void
}

const formSchema = yup.object().shape({
    assignments: yup
        .array(
            yup.object().shape({
                employeeId: yup.number().integer().required('Employee selection is required'),
                vehicleId: yup.number().integer().required('Vehicle selection is required'),
            }),
        )
        .min(1),
})

export const JobAssignEmployeeModal: React.FC<Props> = ({ open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [version, setVersion] = useState(0)
    const bumpVersion = useCallback(() => setVersion(version + 1), [setVersion, version])
    const { handleSubmit, formState, control, watch, setValue } = useForm<{
        assignments: {
            employeeId: number
            vehicleId: number | null
        }[]
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
        defaultValues: {
            assignments: [],
        },
    })
    const formErrors = formState.errors
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'assignments',
    })
    const { company } = useCompany()
    const { job, refetch } = useJob()
    const formValues = watch()
    const { busyEmployeeIds, busyVehicleIds } = useMemo(() => {
        const busyEmployeeIds = new Set<number>()
        const busyVehicleIds = new Set<number>()
        job.jobAssignments.forEach(assignment => {
            busyEmployeeIds.add(assignment.employee.id)
            busyVehicleIds.add(assignment.vehicle.id)
        })
        formValues.assignments.forEach(assignment => {
            busyEmployeeIds.add(assignment.employeeId)
            busyVehicleIds.add(assignment.vehicleId)
        })
        return { busyEmployeeIds, busyVehicleIds }
    }, [job, formValues.assignments, version])
    const freeEmployees = useMemo(() => {
        return company.employees.filter(employee => !busyEmployeeIds.has(employee.id))
    }, [busyEmployeeIds, company])
    const freeVehicles = useMemo(() => {
        return company.vehicles.filter(vehicle => !busyVehicleIds.has(vehicle.id))
    }, [busyVehicleIds, company.vehicles])
    const freeVehicleIds = useMemo(() => new Set(freeVehicles.map(v => v.id)), [freeVehicles])
    const [assignEmployeeToJob] = useAssignEmployeeToJobMutation()
    const onSubmit = handleSubmit(async data => {
        for (const assignment of data.assignments) {
            await assignEmployeeToJob({
                variables: {
                    jobId: job.id,
                    employeeId: assignment.employeeId,
                    vehicleId: assignment.vehicleId,
                },
            })
        }
        await refetch()
        onClose()
        enqueueSnackbar(
            `Successfully assigned ${data.assignments.length} employee${
                data.assignments.length > 1 ? 's' : ''
            } to job.`,
            {
                variant: 'success',
            },
        )
    })
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Assign employees to job</Typography>
                {job.status === JobStatus.InProgress && (
                    <Alert
                        severity="warning"
                        sx={{
                            marginY: 2,
                        }}
                    >
                        This job is already dispatched. When you add these employees to the running
                        job a text message will be sent to them via SMS with instructions.
                    </Alert>
                )}
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ marginTop: 2 }}>
                    <Box display="flex" flexDirection="column">
                        {fields.map((field, index) => {
                            const error = formErrors.assignments
                                ? formErrors.assignments[0]
                                : undefined
                            const currentSelections = formValues.assignments[index]
                            return (
                                <Grid
                                    key={field.id}
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    marginBottom={1}
                                >
                                    <Grid item sm={6} md={5}>
                                        <FormControl fullWidth>
                                            <InputLabel id={`select-assignment-employee-${index}`}>
                                                Employee
                                            </InputLabel>
                                            <Select
                                                labelId={`select-assignment-employee-${index}`}
                                                label="Employee"
                                                disabled
                                                value={currentSelections.employeeId}
                                            >
                                                {currentSelections?.employeeId && (
                                                    <MenuItem
                                                        key={currentSelections.employeeId}
                                                        value={currentSelections.employeeId}
                                                    >
                                                        {
                                                            company.employees.find(
                                                                e =>
                                                                    e.id ===
                                                                    currentSelections.employeeId,
                                                            ).user.displayName
                                                        }
                                                    </MenuItem>
                                                )}
                                            </Select>
                                            <FormHelperText error={Boolean(error?.employeeId)}>
                                                {error?.employeeId?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} md={5}>
                                        <FormControl fullWidth>
                                            <InputLabel id={`select-assignment-vehicle-${index}`}>
                                                Vehicle
                                            </InputLabel>
                                            <Select
                                                labelId={`select-assignment-vehicle-${index}`}
                                                label="Vehicle"
                                                onChange={e => {
                                                    setValue(
                                                        `assignments.${index}.vehicleId`,
                                                        e.target.value as number,
                                                        {
                                                            shouldDirty: true,
                                                            shouldValidate: true,
                                                        },
                                                    )
                                                    bumpVersion()
                                                }}
                                                value={
                                                    formValues.assignments[index]?.vehicleId ?? ''
                                                }
                                                defaultValue=""
                                            >
                                                {company.vehicles.map(vehicle => (
                                                    <MenuItem
                                                        key={vehicle.id}
                                                        value={vehicle.id}
                                                        disabled={!freeVehicleIds.has(vehicle.id)}
                                                    >
                                                        {vehicle.vehicleId}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText error={Boolean(error?.vehicleId)}>
                                                {error?.vehicleId?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <Button
                                            fullWidth
                                            color="warning"
                                            variant="contained"
                                            startIcon={<Delete />}
                                            onClick={() => {
                                                remove(index)
                                                bumpVersion()
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Select
                            value={-1}
                            disabled={freeEmployees.length === 0}
                            onChange={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                const employee = company.employees.find(
                                    emp => emp.id === e.target.value,
                                )
                                const freeVehicle = freeVehicles.find(
                                    v => v.id === employee.assignedVehicle?.id,
                                )
                                append({
                                    employeeId: employee.id,
                                    vehicleId: freeVehicle?.id ?? null,
                                })
                                bumpVersion()
                            }}
                        >
                            <MenuItem key="prompt" value={-1}>
                                {freeEmployees.length === 0
                                    ? 'All employes assigned...'
                                    : 'Add Employee'}
                            </MenuItem>
                            {freeEmployees.map(employee => (
                                <MenuItem key={employee.id} value={employee.id}>
                                    {employee.user.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<Save />}
                                disabled={!formState.isValid || formState.isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
