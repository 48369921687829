import { Upload } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    Card,
    FormControl,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PhotoPicker } from '../../../components/inputs/PhotoPicker'
import {
    useCompanySavedContactsQuery,
    useCreateTicketMutation,
    useDoesTicketIdentifierExistQuery,
    useSupplierMaterialsQuery,
} from '../../../graphql/gen/hooks'
import { CloudFile } from '../../../graphql/gen/schemas'
import { useAuth } from '../../../hooks/useAuth'
import { useSnackbar } from 'notistack'
import { getAuthOverrideConfig } from '../../../lib/authLogic'
import moment from 'moment'
import { usdFormatter } from '../../../lib/formatting'
import { UnmanagedSelect } from '../../../components/inputs/Select/UnmanagedSelect'

export const SupplierUploadTicketPage: React.FC = () => {
    const [identifier, setIdentifier] = useState('')
    const [materialId, setMaterialId] = useState<number | undefined>()
    const [transporterId, setTransporterId] = useState<number | undefined>()
    const [customerId, setCustomerId] = useState<number | undefined>()
    const [materialAmount, setMaterialAmount] = useState<string>()
    const [photo, setPhoto] = useState<File | undefined>()
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [time, setTime] = useState(moment().format('HH:mm'))
    const [notes, setNotes] = useState('')
    const [isOneContact, setIsOneContact] = useState(true)
    const { enqueueSnackbar } = useSnackbar()
    const { firebaseUser } = useAuth()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const materialAmountError = useMemo(() => {
        try {
            const num = parseFloat(materialAmount)
            if (isNaN(num)) {
                return `Not a valid number`
            } else if (num < 0) {
                return 'Must be a positive number'
            }
            return false
        } catch (e) {
            return (e as Error)?.message
        }
    }, [materialAmount])
    const isValid = useMemo(
        () =>
            identifier.length > 3 &&
            Boolean(photo) &&
            typeof materialId !== 'undefined' &&
            !Boolean(materialAmountError),
        [identifier, photo, materialId, materialAmountError, transporterId, customerId],
    )
    const { data: savedContactsData } = useCompanySavedContactsQuery()
    const contacts = useMemo(
        () => savedContactsData?.companySavedContacts ?? [],
        [savedContactsData?.companySavedContacts],
    )
    useEffect(() => {
        if (isOneContact && customerId) {
            setTransporterId(customerId)
        }
    }, [isOneContact, setTransporterId, customerId])
    const { data: materialsData } = useSupplierMaterialsQuery()
    const materials = useMemo(
        () => materialsData?.supplierMaterials ?? [],
        [materialsData?.supplierMaterials],
    )
    const selectedMaterial = useMemo(
        () => materials.find(mat => mat.id === materialId),
        [materials, materialId],
    )
    const { data: ticketExistsData } = useDoesTicketIdentifierExistQuery({
        variables: {
            identifier: identifier,
        },
        skip: !identifier,
    })
    const isIdentifierUnique = useMemo(
        () => !ticketExistsData?.doesTicketIdentifierExist,
        [ticketExistsData],
    )
    useEffect(() => {
        if (selectedMaterial) {
            setMaterialAmount(selectedMaterial.expectedAmountPerTicket.toString())
        }
    }, [selectedMaterial, setMaterialAmount])
    const [createTicket] = useCreateTicketMutation()
    const resetForm = useCallback(() => {
        setPhoto(undefined)
        setIdentifier('')
        setMaterialAmount(selectedMaterial.expectedAmountPerTicket.toString())
    }, [setPhoto, setIdentifier, setMaterialAmount, selectedMaterial])
    const handleFormSubmit = useCallback(async () => {
        setIsSubmitting(true)
        const timestamp = moment(`${date}T${time}`).format()
        const formData = new FormData()
        formData.set('file', photo)
        const { headers: authOverrideHeaders } = getAuthOverrideConfig()
        const resp = await fetch('/api/file/upload', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': await firebaseUser.getIdToken(),
                ...authOverrideHeaders,
            },
        })
        if (resp.status !== 200) {
            throw new Error(`Ran into error uploading photo`)
        }
        const respData: CloudFile = await resp.json()
        await createTicket({
            variables: {
                input: {
                    supplierMaterialId: materialId,
                    fileId: respData.id,
                    identifier: identifier,
                    transporterId: transporterId,
                    customerId: customerId,
                    materialAmount: parseFloat(materialAmount.toString()),
                    timestamp,
                    notes: notes,
                },
            },
        })
        resetForm()
        enqueueSnackbar('Successfully uploaded ticket.', {
            variant: 'success',
        })
        setIsSubmitting(false)
    }, [
        materialId,
        identifier,
        transporterId,
        customerId,
        materialAmount,
        date,
        time,
        notes,
        photo,
        enqueueSnackbar,
        resetForm,
    ])
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            <Box component="form" onSubmit={handleFormSubmit} noValidate sx={{ mt: 1 }}>
                <Typography variant="h5" marginBottom={2}>
                    Upload Ticket
                </Typography>
                <FormControl fullWidth>
                    <PhotoPicker value={photo} onChange={file => setPhoto(file)} />
                </FormControl>
                <FormControl fullWidth sx={{ marginTop: 4, marginBottom: 2 }}>
                    <UnmanagedSelect
                        placeholder="Material"
                        value={materialId}
                        setValue={v => setMaterialId(parseInt(v.toString()))}
                        items={materials}
                        getValueFn={e => e.id}
                        getLabelFn={m =>
                            `${m.unitPlural} of ${m.materialName} (${usdFormatter.format(
                                m.pricePerUnit,
                            )})`
                        }
                        selectProps={{
                            noOptionsMessage: () => (
                                <Typography>
                                    No materials added yet. Please contact your administrator.
                                </Typography>
                            ),
                        }}
                    />
                </FormControl>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Ticket ID"
                    value={identifier}
                    onChange={e => setIdentifier(e.currentTarget.value)}
                />
                {selectedMaterial && (
                    <>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label={
                                selectedMaterial
                                    ? `${selectedMaterial.unitPlural ?? 'Amount'} of ${
                                          selectedMaterial.materialName ?? 'material'
                                      } in this ticket`
                                    : 'Amount of material'
                            }
                            value={materialAmount}
                            onChange={e => setMaterialAmount(e.currentTarget.value)}
                        />
                        {materialAmountError && (
                            <Alert severity="error">{materialAmountError}</Alert>
                        )}
                    </>
                )}
                <FormControlLabel
                    control={
                        <Switch
                            checked={isOneContact}
                            onChange={() => setIsOneContact(!isOneContact)}
                        />
                    }
                    label="Transporter and customer are the same"
                />
                <Grid container alignItems="flex-end" marginBottom={2}>
                    <Grid item xs={12} md={isOneContact ? 12 : 6} paddingRight={1}>
                        <FormControl fullWidth>
                            <UnmanagedSelect
                                value={customerId}
                                placeholder="Customer"
                                setValue={v => setCustomerId(parseInt(v.toString()))}
                                items={contacts}
                                getValueFn={e => e.id}
                                getLabelFn={m => m.name}
                                selectProps={{
                                    noOptionsMessage: () => (
                                        <Typography>
                                            No contacts added yet. Please contact your
                                            administrator.
                                        </Typography>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {!isOneContact && (
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <UnmanagedSelect
                                    value={transporterId}
                                    placeholder="Transporter"
                                    setValue={v => setTransporterId(parseInt(v.toString()))}
                                    items={contacts}
                                    getValueFn={e => e.id}
                                    getLabelFn={m => m.name}
                                    selectProps={{
                                        noOptionsMessage: () => (
                                            <Typography>
                                                No contacts added yet. Please contact your
                                                administrator.
                                            </Typography>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={date}
                            onChange={e => setDate(e.currentTarget.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Time"
                            type="time"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={time}
                            onChange={e => setTime(e.currentTarget.value)}
                        />
                    </Grid>
                </Grid>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Notes (optional)"
                    type="notes"
                    placeholder="Additional information about this ticket..."
                    multiline
                    rows={3}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={notes}
                    onChange={e => setNotes(e.currentTarget.value)}
                />
                {!isIdentifierUnique && (
                    <Alert severity="error">
                        A ticket with the code "{identifier}" already exists...
                    </Alert>
                )}
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<Upload />}
                    disabled={!isValid || !isIdentifierUnique || isSubmitting}
                    onClick={handleFormSubmit}
                >
                    Upload
                </Button>
            </Box>
        </Card>
    )
}
