import React, { useCallback } from 'react'
import { Button } from '@mui/material'
import { useFetch } from '../../hooks/useFetch'

type Props = {
    onDisconnect?: () => any
}

export const QuickBooksDisconnectButton: React.FC<Props> = ({ onDisconnect }) => {
    const { fetch: revokeToken, loading: isRevokingToken } = useFetch('/api/intuit/revoke_token', {
        method: 'DELETE',
    })
    const callback = useCallback(async () => {
        await revokeToken()
        await onDisconnect()
    }, [revokeToken])
    return (
        <Button variant="contained" color="error" disabled={isRevokingToken} onClick={callback}>
            Disconnect from QuickBooks.
        </Button>
    )
}
