import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { firebaseAuth } from '../connections/firebase'
import { getAuthHeaders } from '../lib/authLogic'

export const useAuth = () => {
    const [jwt, setJwt] = useState<string | null>(null)
    const [firebaseUser, loading, error] = useAuthState(firebaseAuth)
    const [authHeaders, setAuthHeaders] = useState<Record<string, string>>({})
    useEffect(() => {
        if (firebaseUser) {
            firebaseUser
                .getIdToken()
                .then(jwt => setJwt(jwt))
                .catch(() => console.error(`Failed to get ID token for user "${firebaseUser.uid}"`))
        }
    }, [firebaseUser, setJwt])
    useEffect(() => {
        getAuthHeaders().then(headers => setAuthHeaders(headers))
    }, [setAuthHeaders, firebaseUser])
    return {
        firebaseUser,
        loading,
        error,
        jwt,
        authHeaders,
    }
}
