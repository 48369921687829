import { useCallback, useMemo, useState } from 'react'

export const useSelectController = <E, K = string | number>(
    items: E[],
    getIdFn: (item: E) => K,
    initialValue: K | null = null,
) => {
    const [selectedId, setSelectedId] = useState<K | null>(initialValue)
    const selectedItem = useMemo(() => {
        return items.find(item => selectedId === getIdFn(item))
    }, [items, selectedId]) as E
    const removeSelection = useCallback(() => setSelectedId(null), [setSelectedId])
    return {
        selectedItem,
        selectedId,
        setId: setSelectedId,
        removeSelection,
    }
}
