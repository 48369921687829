import styled from '@emotion/styled'
import { Card, Divider, Typography } from '@mui/material'
import { QuickBooksIntegrationView } from '../../../../components/quickbooks/QuickBooksIntegrationView'

const SectionDivider = styled(Divider)`
    padding-top: 20px;
    margin-bottom: 20px;
`

export const DashboardIntegrationsPage: React.FC = () => {
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            <Typography variant="h3">Integrations</Typography>
            <Typography variant="body1">
                Here you can find multiple services that FleetMage integrates with in order to
                enhance your operational capabilities.
            </Typography>
            <SectionDivider />
            <QuickBooksIntegrationView />
        </Card>
    )
}
