import { Alert, Box, Card, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import moment from 'moment'
import { useMemo } from 'react'
import { useEmployeeEarningsItemsQuery } from '../../../graphql/gen/hooks'
import { EmployeeEarningsItemsQuery } from '../../../graphql/gen/operations'
import { formatMaterialAmount, usdFormatter } from '../../../lib/formatting'

type EmployeeJobEarningsObject = {
    id: number
    jobName: string
    items: EmployeeEarningsItemsQuery['employeeEarningsItems']
}

const dtStart = moment().startOf('isoWeek')
const dtEnd = moment().endOf('isoWeek')

export const ConsoleEarningsPage: React.FC = () => {
    const { data } = useEmployeeEarningsItemsQuery()
    const jobData = useMemo((): EmployeeJobEarningsObject[] => {
        const jobs = new Map<number, EmployeeEarningsItemsQuery['employeeEarningsItems']>()
        if (data) {
            data.employeeEarningsItems.forEach(item => {
                if (!jobs.has(item.jobId)) {
                    jobs.set(item.jobId, [])
                }
                jobs.get(item.jobId).push(item)
            })
        }
        return Array.from(jobs.keys()).map(jobId => {
            const items = jobs.get(jobId)
            if (items.length === 0) {
                throw new Error('Impossible scenario - ConsoleEarningsPage.tsx')
            }
            const firstItem = items[0]
            return {
                id: jobId,
                jobName: firstItem.jobName,
                items,
            }
        })
    }, [data])
    const totalEarnings = useMemo(() => {
        return jobData.reduce((sum, job) => {
            const jobEarnings = job.items.reduce((sum, item) => sum + item.amountEarned, 0)
            return sum + jobEarnings
        }, 0)
    }, [jobData])
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            <Box display="flex" flexDirection="column">
                <Typography variant="h5">My Earnings</Typography>
                <Typography variant="subtitle1">
                    {dtStart.format('MMM Do')} - {dtEnd.format('MMM Do')}
                </Typography>
            </Box>
            {jobData.length > 0 ? (
                <>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        marginY={3}
                        color="green"
                    >
                        <Typography variant="h5">This week's earnings</Typography>
                        <Typography variant="h5">{usdFormatter.format(totalEarnings)}</Typography>
                    </Box>
                    <Box flexGrow={1}>
                        {jobData.map(job => {
                            const totalJobEarnings = job.items.reduce(
                                (sum, cur) => sum + cur.amountEarned,
                                0,
                            )
                            return (
                                <Box key={job.id} marginBottom={2}>
                                    <Typography variant="h6">Job - {job.jobName}</Typography>
                                    <Table>
                                        <TableBody>
                                            {job.items.map(material => {
                                                return (
                                                    <TableRow key={material.id}>
                                                        <TableCell>
                                                            {formatMaterialAmount(
                                                                material.materialAmount,
                                                            )}{' '}
                                                            {material.unitPlural} of{' '}
                                                            {material.materialName}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography>
                                                                {usdFormatter.format(
                                                                    material.amountEarned,
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            <TableRow>
                                                <TableCell>
                                                    <Typography fontWeight="bold">Total</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography fontWeight="bold">
                                                        {usdFormatter.format(totalJobEarnings)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            )
                        })}
                    </Box>
                </>
            ) : (
                <Box marginTop={4}>
                    <Alert severity="warning">
                        You have not worked on any jobs in this period of time.
                    </Alert>
                </Box>
            )}
        </Card>
    )
}
