import { LockOutlined, Phone } from '@mui/icons-material'
import {
    Alert,
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    TextField,
    Typography,
    Link as MuiLink,
    useTheme,
} from '@mui/material'
import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithCustomToken,
} from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { firebaseAuth } from '../../../connections/firebase'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from '../../../components/Link'
import { GoogleLoginButton, createButton } from 'react-social-login-buttons'
import { useCallback, useEffect } from 'react'
import { useWhoAmI } from '../../../hooks/useWhoAmI'
import { calcLogic } from '../../../lib/calcLogic'

const PhoneSignInButton = createButton({
    text: 'Sign in with phone',
    icon: Phone,
    style: { background: '#ffffff', color: '#000000' },
    activeStyle: { background: '#eeeeee' },
})

const formSchema = yup.object().shape({
    email: yup
        .string()
        .email('Invalid email address')
        .required('You must provide your email address'),
    password: yup.string().required('You must provide your password'),
})

// Override User Auth
const OVERRIDE_USER_AUTH_QUERY_KEY = 'orua'

export const SignInPage: React.FC = () => {
    const [searchParams] = useSearchParams()
    useEffect(() => {
        const f = async () => {
            if (searchParams.has(OVERRIDE_USER_AUTH_QUERY_KEY)) {
                const uid = searchParams.get(OVERRIDE_USER_AUTH_QUERY_KEY)
                const resp = await fetch(`/api/auth/override/${uid}`, {
                    method: 'POST',
                })
                const data = await resp.json()
                const token = data.token
                await signInWithCustomToken(firebaseAuth, token!)
                navigate('/signin/success')
            }
        }
        f()
    }, [searchParams])
    const navigate = useNavigate()
    const theme = useTheme()
    const { handleSubmit, register, formState } = useForm<{
        email: string
        password: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const onSubmit = handleSubmit(async data => {
        await signInWithEmailAndPassword(firebaseAuth, data.email, data.password)
        // TODO: Handle email and password signin errors
        navigate('/signin/success')
    })
    const signInWithGoogleCallback = useCallback(async () => {
        await signInWithPopup(firebaseAuth, new GoogleAuthProvider())
        // TODO: Handle google signin errors
        // One possible error - https://firebase.google.com/docs/auth/web/google-signin#handling-account-exists-with-different-credential-errors
        navigate('/signin/success')
    }, [])
    const { whoAmI } = useWhoAmI()
    const socialLoginStyle =
        theme.palette.mode === 'dark'
            ? {
                  default: {
                      background: theme.palette.grey[900],
                      color: theme.palette.text.primary,
                  },
                  active: {
                      background: theme.palette.grey[800],
                  },
              }
            : undefined
    return (
        <Container component="main" maxWidth="sm">
            <div id="recaptcha-container" />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                marginBottom={2}
            >
                {whoAmI && (
                    <Alert
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 3,
                        }}
                        action={calcLogic(() => {
                            const page = whoAmI.employeeAccount.isOwner ? 'dashboard' : 'console'
                            return (
                                <Button color="inherit" onClick={() => navigate(`/${page}`)}>
                                    Go to {page}
                                </Button>
                            )
                        })}
                    >
                        You are already signed in
                    </Alert>
                )}
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        type="email"
                        autoComplete="email"
                        error={Boolean(formState.errors.email)}
                        helperText={
                            formState.errors.email ? (
                                <Typography>{formState.errors.email.message}</Typography>
                            ) : null
                        }
                        {...register('email')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        autoComplete="password"
                        error={Boolean(formState.errors.password)}
                        helperText={
                            formState.errors.password ? (
                                <Typography>{formState.errors.password.message}</Typography>
                            ) : null
                        }
                        {...register('password')}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!formState.isValid}
                    >
                        Sign In
                    </Button>
                    <Link to="/signup">No account? Sign up</Link>
                </Box>
            </Box>
            <Divider />
            <Box marginTop={2}>
                <GoogleLoginButton
                    style={socialLoginStyle?.default}
                    activeStyle={socialLoginStyle?.active}
                    onClick={signInWithGoogleCallback}
                    text="Sign in with Google"
                />
            </Box>
            <Box marginTop={1}>
                <PhoneSignInButton
                    style={socialLoginStyle?.default}
                    activeStyle={socialLoginStyle?.active}
                    onClick={() => navigate('/signin/phone')}
                />
            </Box>
        </Container>
    )
}
