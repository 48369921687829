export const ALL_MATERIALS = [
    'fill',
    'white sand',
    'asphalt',
    'roadbase',
    'yard waste',
    'demolition',
    'milling',
    'clay',
    'clay stabilizer',
    'shell stabilizer',
    '#57 stone',
    'concrete (fine)',
]

export type MaterialUnitPreset = {
    singular: string
    plural: string
    defaultExpectedAmount: number
    decimalPoints: number
}

export const ALL_MATERIAL_UNIT_PRESETS: MaterialUnitPreset[] = [
    {
        singular: 'load',
        plural: 'loads',
        defaultExpectedAmount: 1,
        decimalPoints: 0,
    },
    {
        singular: 'yard',
        plural: 'yards',
        defaultExpectedAmount: 18,
        decimalPoints: 2,
    },
    {
        singular: 'ton',
        plural: 'tons',
        defaultExpectedAmount: 20,
        decimalPoints: 2,
    },
    {
        singular: 'hour',
        plural: 'hours',
        defaultExpectedAmount: 1,
        decimalPoints: 2,
    },
]
