import { Box, Card, CardContent, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import React from 'react'
import { ChildrenProp } from '../lib/componentProps'

type Props = ChildrenProp & {
    title: string
}

export const ErrorView: React.FC<Props> = ({ title, children }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop={10}
            paddingBottom={10}
        >
            <Card
                sx={{
                    marginTop: 5,
                }}
            >
                <CardContent>
                    <Typography variant="h4" color={red[500]} align="center">
                        {title}
                    </Typography>
                    {children}
                </CardContent>
            </Card>
        </Box>
    )
}
