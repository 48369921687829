import { useTheme } from '@mui/material'
import { Line } from 'rc-progress'

type Props = {
    progress: number
}

export const ProgressBar: React.FC<Props> = ({ progress }) => {
    const theme = useTheme()
    return (
        <Line
            percent={progress}
            trailColor={theme.palette.mode === 'dark' ? theme.palette.grey[800] : undefined}
        />
    )
}
