import { useCompany } from '../../hooks/useCompany'
import {
    Agriculture,
    Dashboard,
    Map,
    PeopleAlt,
    Receipt,
    SupportAgent,
    Work,
    Extension,
    Paid,
    AddRoad,
    Description,
} from '@mui/icons-material'
import { NavigationUiItem } from '../../lib/navigationUiHelpers'
import { AppLayout } from './AppLayout'
import { CompanyType } from '../../graphql/gen/schemas'

const navItems: NavigationUiItem[] = [
    {
        title: 'Overview',
        pathPrefix: '/dashboard',
        rootPath: '/dashboard',
        exactMatch: true,
        icon: <Dashboard />,
    },
    {
        title: 'Jobs',
        pathPrefix: '/dashboard/job',
        rootPath: '/dashboard/jobs',
        icon: <Work />,
        companyTypes: [CompanyType.Trucking],
    },
    {
        title: 'Materials',
        pathPrefix: '/dashboard/material',
        rootPath: '/dashboard/materials',
        icon: <AddRoad />,
        companyTypes: [CompanyType.Supplier],
    },
    {
        title: 'Invoices',
        pathPrefix: '/dashboard/invoice',
        rootPath: '/dashboard/invoices',
        icon: <Description />,
    },
    {
        title: 'Tickets',
        pathPrefix: '/dashboard/ticket',
        rootPath: '/dashboard/tickets',
        icon: <Receipt />,
    },
    {
        title: 'Vehicles',
        pathPrefix: '/dashboard/vehicle',
        rootPath: '/dashboard/vehicles',
        icon: <Agriculture />,
        companyTypes: [CompanyType.Trucking],
    },
    {
        title: 'Employees',
        pathPrefix: '/dashboard/employee',
        rootPath: '/dashboard/employees',
        icon: <PeopleAlt />,
    },
    {
        title: 'Contacts',
        pathPrefix: '/dashboard/contact',
        rootPath: '/dashboard/contacts',
        icon: <SupportAgent />,
    },
    {
        title: 'Locations',
        pathPrefix: '/dashboard/locations',
        rootPath: '/dashboard/locations',
        icon: <Map />,
        companyTypes: [CompanyType.Trucking],
    },
]

const secondaryNavItems: NavigationUiItem[] = [
    {
        title: 'Integrations',
        pathPrefix: '/dashboard/integrations',
        rootPath: '/dashboard/integrations',
        exactMatch: true,
        icon: <Extension />,
    },
    {
        title: 'Billing',
        pathPrefix: '/dashboard/billing',
        rootPath: '/dashboard/billing',
        icon: <Paid />,
    },
]

export const DashboardLayout: React.FC = () => {
    const { company } = useCompany()
    const relevantNavItems = navItems.filter(item => {
        return (
            // !appConfig.isProduction ||
            !item.companyTypes ||
            item.companyTypes.length === 0 ||
            item.companyTypes.indexOf(company.companyType) !== -1
        )
    })
    return (
        <AppLayout
            layoutTitle={`Dashboard - ${company.name}`}
            navItems={relevantNavItems}
            secondaryNavItems={secondaryNavItems}
        />
    )
}
