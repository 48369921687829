import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../connections/firebase'
import { apolloCache } from '../../connections/graphql'
import { useEmployeeInviteCode } from '../../hooks/useEmployeeInviteCode'

export const SignOutPage: React.FC = () => {
    const navigate = useNavigate()
    const { deleteEmployeeInviteCode } = useEmployeeInviteCode()
    useEffect(() => {
        async function run() {
            deleteEmployeeInviteCode()
            if (firebaseAuth.currentUser) {
                await firebaseAuth.signOut()
            }
            await apolloCache.reset()
            navigate('/signin')
        }
        run()
    }, [])
    return null
}
