import { LockOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Container, TextField, Typography } from '@mui/material'
import { PhoneAuthProvider, signInWithCredential, updatePhoneNumber } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../../connections/firebase'
import { useQueryString } from '../../../hooks/queryStringHooks'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const formSchema = yup.object().shape({
    confirmationCode: yup
        .string()
        .length(6, 'The confirmation code is 6 digits long')
        .matches(/^\d+$/, 'The confirmation code only contains digits')
        .required('You must provide the confirmation code that was sent through text'),
})

export const SignInWithPhoneConfirmationPage: React.FC = () => {
    const navigate = useNavigate()
    const verificationId = useQueryString('verificationId')
    const { handleSubmit, register, formState } = useForm<{
        confirmationCode?: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const onSubmit = handleSubmit(async data => {
        const creds = PhoneAuthProvider.credential(verificationId, data.confirmationCode)
        if (firebaseAuth.currentUser) {
            await updatePhoneNumber(firebaseAuth.currentUser, creds)
        } else {
            await signInWithCredential(firebaseAuth, creds)
        }
        // TODO: Handle confirm phone number errors
        navigate('/signin/success')
    })
    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Confirm verification code
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirmation Code"
                        autoComplete="confirmation-code"
                        error={Boolean(formState.errors.confirmationCode)}
                        helperText={
                            formState.errors.confirmationCode ? (
                                <Typography>{formState.errors.confirmationCode.message}</Typography>
                            ) : null
                        }
                        {...register('confirmationCode')}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!formState.isValid}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}
