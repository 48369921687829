import React from 'react'
import { useInvoiceQuery } from '../../graphql/gen/hooks'
import { InvoiceQuery } from '../../graphql/gen/operations'
import { ChildrenProp } from '../../lib/componentProps'
import { useParams } from 'react-router-dom'
import { NotFound404View } from '../NotFound404'
import { LoadingView } from './LoadingView'

type InvoiceObject = Omit<InvoiceQuery['invoice'], '__typename'>

type InvoiceContextObject = {
    invoice: InvoiceObject | null
    refetch: ReturnType<typeof useInvoiceQuery>['refetch']
}

export const InvoiceContext = React.createContext<InvoiceContextObject>(null)

export const InvoiceProvider: React.FC<ChildrenProp> = ({ children }) => {
    const { invoiceId } = useParams<'invoiceId'>()
    const { data, loading, refetch } = useInvoiceQuery({
        variables: {
            id: parseInt(invoiceId),
        },
    })
    if (!loading && !data) {
        return <NotFound404View />
    }
    // TODO: Handle company errors
    if (loading && !data) {
        return <LoadingView />
    }
    return (
        <InvoiceContext.Provider
            value={{
                invoice: data.invoice,
                refetch,
            }}
        >
            {children}
        </InvoiceContext.Provider>
    )
}
