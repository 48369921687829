import React, { useCallback, useMemo, useState } from 'react'
import { useFetch } from '../../hooks/useFetch'
import { calcLogic } from '../../lib/calcLogic'

type Props = {
    disabled?: boolean
}

export const QuickBooksConnectButton: React.FC<Props> = ({ disabled = false }) => {
    const [isHovered, setIsHovered] = useState(false)
    const { data } = useFetch<{
        oauthUri: string
    }>('/api/intuit/oauth_uri')
    const isEnabled = useMemo(
        () => Boolean(data?.oauthUri) && !disabled,
        [disabled, data?.oauthUri],
    )
    const callback = useCallback(() => {
        if (isEnabled) {
            window.location.href = data?.oauthUri
        }
    }, [data?.oauthUri, isEnabled])
    return (
        <img
            src={calcLogic(() => {
                const validBtn = `/static/icon/quickbooks/C2QB_green_btn_med_${
                    isHovered ? 'hover' : 'default'
                }.svg`
                return isEnabled
                    ? validBtn
                    : '/static/icon/quickbooks/C2QB_transparent_btn_med_default.svg'
            })}
            style={{
                cursor: isEnabled ? 'pointer' : 'not-allowed',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={callback}
        />
    )
}
