import styled from '@emotion/styled'
import { CameraAlt, Delete } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const FileInput = styled.input`
    display: none;
`

const PhotoImg = styled.img`
    border-radius: 5px;
    max-height: 150px;
`

type Props = {
    value?: File
    onChange: (file?: File) => void | Promise<void>
}

export const PhotoPicker: React.FC<Props> = ({ value, onChange }) => {
    const onDrop = useCallback(
        (files: File[]) => {
            if (files.length !== 1) {
                console.error(
                    `Received an invalid number of files: ${files.length}`,
                )
            }
            onChange(files[0])
        },
        [onChange],
    )
    const { getInputProps, open } = useDropzone({
        onDrop,
        multiple: false,
    })
    return (
        <Box
            display="flex"
            flex="1 1"
            justifyContent="center"
            alignItems="center"
        >
            <FileInput {...getInputProps()} />
            {value ? (
                <Box display="flex" flexDirection="column">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginBottom={1}
                    >
                        <PhotoImg alt="" src={URL.createObjectURL(value)} />
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => onChange()}
                    >
                        Delete Photo
                    </Button>
                </Box>
            ) : (
                <Button
                    fullWidth
                    variant="contained"
                    startIcon={<CameraAlt />}
                    onClick={open}
                >
                    Upload ticket photo
                </Button>
            )}
        </Box>
    )
}
