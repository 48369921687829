import { useContext } from 'react'
import { InvoiceContext } from '../components/layouts/InvoiceProvider'

export const useInvoice = () => {
    const { invoice, refetch } = useContext(InvoiceContext)
    if (!invoice) {
        throw new Error('You are calling useInvoice outside of <InvoiceProvider />.')
    }
    return { invoice, refetch }
}
