import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import mapboxgl from 'mapbox-gl'
import React, { useEffect, useRef } from 'react'
import { useMapboxMap } from '../../hooks/useMap'
import { GeocoderResultObject } from '../../lib/geo'

type Props = {
    onResult?: (result: GeocoderResultObject) => void
}

export const MapGeocoder: React.FC<Props> = ({ onResult }) => {
    const geocoder = useRef(
        new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            zoom: 16,
            marker: false,
            placeholder: 'Enter an address...',
            mapboxgl: mapboxgl as any,
            reverseGeocode: true,
        }),
    )
    const map = useMapboxMap()
    useEffect(() => {
        if (!map.hasControl(geocoder.current)) {
            map.addControl(geocoder.current)
        }
        return () => {
            map.removeControl(geocoder.current)
        }
    }, [])
    useEffect(() => {
        if (onResult) {
            geocoder.current.on('result', e => {
                onResult(e)
            })
        }
    }, [onResult])
    return null
}
