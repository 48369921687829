import { Button, Grid, Modal, Typography } from '@mui/material'
import { SimpleModal } from '../SimpleModal'
import { Cancel, Save } from '@mui/icons-material'
import { useBulkUpdateTicketsMutation, useListInvoicesQuery } from '../../graphql/gen/hooks'
import { useMemo } from 'react'
import { UnmanagedSelect } from '../inputs/Select/UnmanagedSelect'
import { useSelectController } from '../../hooks/useSelectController'
import { TicketObject } from '../../lib/types/gqlTypeAbbreviations'

type Props = {
    tickets: TicketObject[]
    open: boolean
    onClose: () => void
    refetch: () => any
}

export const BulkTicketAssignInvoiceModal: React.FC<Props> = ({
    open,
    onClose,
    tickets,
    refetch,
}) => {
    const { data: invoiceListData } = useListInvoicesQuery()
    const invoices = useMemo(
        () => invoiceListData?.listInvoices ?? [],
        [invoiceListData?.listInvoices],
    )
    const { selectedId, setId, selectedItem } = useSelectController(invoices, c => c.id)
    const [bulkUpdateTickets, { loading }] = useBulkUpdateTicketsMutation()
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h3" marginBottom={2}>
                    Add To Invoice
                </Typography>
                <UnmanagedSelect
                    placeholder="Select Invoice"
                    items={invoices}
                    getValueFn={c => c.id}
                    getLabelFn={c => c.name}
                    value={selectedId}
                    setValue={v => setId(v as number)}
                />
                <Grid marginTop={2} container spacing={2}>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="error"
                            startIcon={<Cancel />}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<Save />}
                            onClick={async () => {
                                await bulkUpdateTickets({
                                    variables: {
                                        input: {
                                            ticketIds: tickets.map(t => t.id),
                                            invoiceId: selectedItem.id,
                                        },
                                    },
                                })
                                onClose()
                                refetch()
                            }}
                            disabled={!selectedItem || loading}
                        >
                            Save ({tickets.length})
                        </Button>
                    </Grid>
                </Grid>
            </SimpleModal>
        </Modal>
    )
}
