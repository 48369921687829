import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWhoAmIQuery } from '../graphql/gen/hooks'
import { useAuth } from './useAuth'

export const useWhoAmI = () => {
    const { firebaseUser, loading: loadingFirebase, error: firebaseError } = useAuth()
    const { loading, data, error } = useWhoAmIQuery({
        skip: !firebaseUser || loadingFirebase,
    })
    const navigate = useNavigate()
    useEffect(() => {
        if (error) {
            if ((error.networkError as any)?.bodyText?.indexOf('Firebase ID token has expired.')) {
                navigate('/signout')
            }
            for (const err of (error.networkError as any)?.result?.errors ?? []) {
                if (err.message?.startsWith('Access denied!')) {
                    navigate('/signout')
                }
            }
        }
    }, [error])
    return {
        loading: loading || loadingFirebase,
        whoAmI: firebaseUser ? data?.whoAmI : undefined,
        error: firebaseError ?? error,
    }
}
