import React from 'react'
import { useGetCompanyQuery } from '../../graphql/gen/hooks'
import { GetCompanyQuery } from '../../graphql/gen/operations'
import { useAuth } from '../../hooks/useAuth'
import { ChildrenProp } from '../../lib/componentProps'
import { useWhoAmI } from '../../hooks/useWhoAmI'
import { NotFound404View } from '../NotFound404'
import { LoadingView } from './LoadingView'
import { Navigate } from 'react-router-dom'
import { CompanyType } from '../../graphql/gen/schemas'
import { useMapFromArray } from '../../hooks/useMapFromArray'
import { CompanyEmployeeObject } from '../../lib/types/gqlTypeAbbreviations'

type CompanyObject = Omit<GetCompanyQuery['getCompany'], '__typename'>

type CompanyContextObject = {
    company: CompanyObject | null
    isTruckingCompany: boolean
    isSupplierCompany: boolean
    refetch: ReturnType<typeof useGetCompanyQuery>['refetch']
    employeeMap: Map<number, CompanyEmployeeObject>
}

export const CompanyContext = React.createContext<CompanyContextObject>(null)

export const CompanyProvider: React.FC<ChildrenProp> = ({ children }) => {
    const { firebaseUser, loading: loadingFirebase, error: errorFirebase } = useAuth()
    const { whoAmI, loading: loadingWhoAmI, error: errorWhoAmI } = useWhoAmI()
    const {
        loading: loadingCompany,
        data: dataCompany,
        error: errorCompany,
        refetch,
    } = useGetCompanyQuery({
        skip: Boolean(!firebaseUser || !whoAmI || !whoAmI?.employeeAccount),
    })
    const employeeMap = useMapFromArray(
        dataCompany?.getCompany.employees ?? [],
        employee => employee.id,
    )
    // TODO: Handle company errors
    const loading = loadingFirebase || loadingWhoAmI || loadingCompany
    if (!whoAmI && !loadingWhoAmI) {
        return <Navigate to="/signin" />
    }
    if (!loading && !dataCompany) {
        return <NotFound404View />
    }
    if (loading && !dataCompany) {
        return <LoadingView />
    }
    return (
        <CompanyContext.Provider
            value={{
                company: dataCompany.getCompany,
                isTruckingCompany: dataCompany.getCompany.companyType === CompanyType.Trucking,
                isSupplierCompany: dataCompany.getCompany.companyType === CompanyType.Supplier,
                refetch,
                employeeMap,
            }}
        >
            {children}
        </CompanyContext.Provider>
    )
}
