import { useCompany } from '../../hooks/useCompany'
import {
    Receipt,
    Work,
    List as ListIcon,
    Paid,
    SupportAgent,
    WorkHistory,
} from '@mui/icons-material'
import { NavigationUiItem } from '../../lib/navigationUiHelpers'
import { AppLayout } from './AppLayout'
import { CompanyType, EmployeeType } from '../../graphql/gen/schemas'
import { useWhoAmI } from '../../hooks/useWhoAmI'
import { useMemo } from 'react'

const navItems: NavigationUiItem[] = [
    {
        title: 'Upload Ticket',
        pathPrefix: '/console/upload',
        rootPath: '/console/upload',
        icon: <Receipt />,
        companyTypes: [CompanyType.Trucking],
        employeeTypes: [EmployeeType.Normal],
    },
    {
        title: 'Upload Ticket',
        pathPrefix: '/console/supplier_upload',
        rootPath: '/console/supplier_upload',
        icon: <Receipt />,
        companyTypes: [CompanyType.Supplier],
        employeeTypes: [EmployeeType.Normal],
    },
    {
        title: 'Shifts',
        pathPrefix: '/console/shifts',
        rootPath: '/console/shifts',
        icon: <WorkHistory />,
        companyTypes: [CompanyType.Supplier],
    },
    {
        title: 'Jobs',
        pathPrefix: '/console/job',
        rootPath: '/console/jobs',
        icon: <Work />,
        companyTypes: [CompanyType.Trucking],
        employeeTypes: [EmployeeType.Normal],
    },
    {
        title: 'Tickets',
        pathPrefix: '/console/tickets',
        rootPath: '/console/tickets',
        icon: <ListIcon />,
        companyTypes: [CompanyType.Trucking],
        employeeTypes: [EmployeeType.Normal],
    },
    {
        title: 'Tickets',
        pathPrefix: '/console/supplier_tickets',
        rootPath: '/console/supplier_tickets',
        icon: <ListIcon />,
        companyTypes: [CompanyType.Supplier],
        employeeTypes: [EmployeeType.Normal],
    },
    {
        title: 'Earnings',
        pathPrefix: '/console/earnings',
        rootPath: '/console/earnings',
        icon: <Paid />,
        companyTypes: [CompanyType.Trucking],
        employeeTypes: [EmployeeType.Normal],
    },
    {
        title: 'Contacts',
        pathPrefix: '/console/contacts',
        rootPath: '/console/contacts',
        icon: <SupportAgent />,
        companyTypes: [CompanyType.Supplier],
        employeeTypes: [EmployeeType.Normal],
    },
]

export const ConsoleLayout: React.FC = () => {
    const { company } = useCompany()
    const { whoAmI } = useWhoAmI()
    const relevantNavItems = useMemo(() => {
        return navItems.filter(item => {
            if (
                item.companyTypes &&
                item.companyTypes.length > 0 &&
                item.companyTypes.indexOf(company.companyType) === -1
            ) {
                return false
            }
            if (
                item.employeeTypes &&
                item.employeeTypes.length > 0 &&
                item.employeeTypes.indexOf(whoAmI?.employeeAccount.employeeType) === -1
            ) {
                return false
            }
            return true
        })
    }, [company, whoAmI])
    return <AppLayout layoutTitle={`Console - ${company.name}`} navItems={relevantNavItems} />
}
