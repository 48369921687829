import * as Types from './operations'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const

export const CreateCompanyDocument = gql`
    mutation CreateCompany($name: String!, $inviteCode: String!) {
        createCompany(name: $name, inviteCode: $inviteCode) {
            id
            name
            createdAt
            createdByUserId
        }
    }
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
    Types.CreateCompanyMutation,
    Types.CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useCreateCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateCompanyMutation,
        Types.CreateCompanyMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateCompanyMutation, Types.CreateCompanyMutationVariables>(
        CreateCompanyDocument,
        options,
    )
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>
export type CreateCompanyMutationResult = Apollo.MutationResult<Types.CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateCompanyMutation,
    Types.CreateCompanyMutationVariables
>
export const CreateVehicleDocument = gql`
    mutation CreateVehicle($vehicleId: String!, $description: String, $assignedEmployeeId: Int) {
        createVehicle(
            vehicleId: $vehicleId
            description: $description
            assignedEmployeeId: $assignedEmployeeId
        ) {
            id
            vehicleId
            description
            company {
                id
            }
        }
    }
`
export type CreateVehicleMutationFn = Apollo.MutationFunction<
    Types.CreateVehicleMutation,
    Types.CreateVehicleMutationVariables
>

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      description: // value for 'description'
 *      assignedEmployeeId: // value for 'assignedEmployeeId'
 *   },
 * });
 */
export function useCreateVehicleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateVehicleMutation,
        Types.CreateVehicleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateVehicleMutation, Types.CreateVehicleMutationVariables>(
        CreateVehicleDocument,
        options,
    )
}
export type CreateVehicleMutationHookResult = ReturnType<typeof useCreateVehicleMutation>
export type CreateVehicleMutationResult = Apollo.MutationResult<Types.CreateVehicleMutation>
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateVehicleMutation,
    Types.CreateVehicleMutationVariables
>
export const CreateEmployeeInviteDocument = gql`
    mutation CreateEmployeeInvite($name: String!, $message: String!, $phone: String!) {
        createEmployeeInvite(name: $name, message: $message, phone: $phone) {
            id
        }
    }
`
export type CreateEmployeeInviteMutationFn = Apollo.MutationFunction<
    Types.CreateEmployeeInviteMutation,
    Types.CreateEmployeeInviteMutationVariables
>

/**
 * __useCreateEmployeeInviteMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeInviteMutation, { data, loading, error }] = useCreateEmployeeInviteMutation({
 *   variables: {
 *      name: // value for 'name'
 *      message: // value for 'message'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateEmployeeInviteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateEmployeeInviteMutation,
        Types.CreateEmployeeInviteMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.CreateEmployeeInviteMutation,
        Types.CreateEmployeeInviteMutationVariables
    >(CreateEmployeeInviteDocument, options)
}
export type CreateEmployeeInviteMutationHookResult = ReturnType<
    typeof useCreateEmployeeInviteMutation
>
export type CreateEmployeeInviteMutationResult =
    Apollo.MutationResult<Types.CreateEmployeeInviteMutation>
export type CreateEmployeeInviteMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateEmployeeInviteMutation,
    Types.CreateEmployeeInviteMutationVariables
>
export const ClaimEmployeeInviteDocument = gql`
    mutation ClaimEmployeeInvite($inviteCode: String!) {
        claimEmployeeInvite(inviteCode: $inviteCode) {
            id
        }
    }
`
export type ClaimEmployeeInviteMutationFn = Apollo.MutationFunction<
    Types.ClaimEmployeeInviteMutation,
    Types.ClaimEmployeeInviteMutationVariables
>

/**
 * __useClaimEmployeeInviteMutation__
 *
 * To run a mutation, you first call `useClaimEmployeeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimEmployeeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimEmployeeInviteMutation, { data, loading, error }] = useClaimEmployeeInviteMutation({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useClaimEmployeeInviteMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ClaimEmployeeInviteMutation,
        Types.ClaimEmployeeInviteMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.ClaimEmployeeInviteMutation,
        Types.ClaimEmployeeInviteMutationVariables
    >(ClaimEmployeeInviteDocument, options)
}
export type ClaimEmployeeInviteMutationHookResult = ReturnType<
    typeof useClaimEmployeeInviteMutation
>
export type ClaimEmployeeInviteMutationResult =
    Apollo.MutationResult<Types.ClaimEmployeeInviteMutation>
export type ClaimEmployeeInviteMutationOptions = Apollo.BaseMutationOptions<
    Types.ClaimEmployeeInviteMutation,
    Types.ClaimEmployeeInviteMutationVariables
>
export const AssignVehicleToEmployeeDocument = gql`
    mutation AssignVehicleToEmployee($vehicleId: String, $employeeId: Int) {
        assignVehicleToEmployee(vehicleId: $vehicleId, employeeId: $employeeId)
    }
`
export type AssignVehicleToEmployeeMutationFn = Apollo.MutationFunction<
    Types.AssignVehicleToEmployeeMutation,
    Types.AssignVehicleToEmployeeMutationVariables
>

/**
 * __useAssignVehicleToEmployeeMutation__
 *
 * To run a mutation, you first call `useAssignVehicleToEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVehicleToEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVehicleToEmployeeMutation, { data, loading, error }] = useAssignVehicleToEmployeeMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useAssignVehicleToEmployeeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.AssignVehicleToEmployeeMutation,
        Types.AssignVehicleToEmployeeMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.AssignVehicleToEmployeeMutation,
        Types.AssignVehicleToEmployeeMutationVariables
    >(AssignVehicleToEmployeeDocument, options)
}
export type AssignVehicleToEmployeeMutationHookResult = ReturnType<
    typeof useAssignVehicleToEmployeeMutation
>
export type AssignVehicleToEmployeeMutationResult =
    Apollo.MutationResult<Types.AssignVehicleToEmployeeMutation>
export type AssignVehicleToEmployeeMutationOptions = Apollo.BaseMutationOptions<
    Types.AssignVehicleToEmployeeMutation,
    Types.AssignVehicleToEmployeeMutationVariables
>
export const CreateSavedContactDocument = gql`
    mutation CreateSavedContact($input: CreateSavedContactInput!) {
        createSavedContact(input: $input) {
            id
        }
    }
`
export type CreateSavedContactMutationFn = Apollo.MutationFunction<
    Types.CreateSavedContactMutation,
    Types.CreateSavedContactMutationVariables
>

/**
 * __useCreateSavedContactMutation__
 *
 * To run a mutation, you first call `useCreateSavedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedContactMutation, { data, loading, error }] = useCreateSavedContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSavedContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateSavedContactMutation,
        Types.CreateSavedContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.CreateSavedContactMutation,
        Types.CreateSavedContactMutationVariables
    >(CreateSavedContactDocument, options)
}
export type CreateSavedContactMutationHookResult = ReturnType<typeof useCreateSavedContactMutation>
export type CreateSavedContactMutationResult =
    Apollo.MutationResult<Types.CreateSavedContactMutation>
export type CreateSavedContactMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateSavedContactMutation,
    Types.CreateSavedContactMutationVariables
>
export const UpdateSavedContactDocument = gql`
    mutation UpdateSavedContact($id: Int!, $input: UpdateSavedContactInput!) {
        updateSavedContact(id: $id, input: $input) {
            id
        }
    }
`
export type UpdateSavedContactMutationFn = Apollo.MutationFunction<
    Types.UpdateSavedContactMutation,
    Types.UpdateSavedContactMutationVariables
>

/**
 * __useUpdateSavedContactMutation__
 *
 * To run a mutation, you first call `useUpdateSavedContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedContactMutation, { data, loading, error }] = useUpdateSavedContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSavedContactMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateSavedContactMutation,
        Types.UpdateSavedContactMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.UpdateSavedContactMutation,
        Types.UpdateSavedContactMutationVariables
    >(UpdateSavedContactDocument, options)
}
export type UpdateSavedContactMutationHookResult = ReturnType<typeof useUpdateSavedContactMutation>
export type UpdateSavedContactMutationResult =
    Apollo.MutationResult<Types.UpdateSavedContactMutation>
export type UpdateSavedContactMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateSavedContactMutation,
    Types.UpdateSavedContactMutationVariables
>
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($vehicleId: String!, $description: String) {
        updateVehicle(vehicleId: $vehicleId, description: $description) {
            vehicleId
        }
    }
`
export type UpdateVehicleMutationFn = Apollo.MutationFunction<
    Types.UpdateVehicleMutation,
    Types.UpdateVehicleMutationVariables
>

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateVehicleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateVehicleMutation,
        Types.UpdateVehicleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateVehicleMutation, Types.UpdateVehicleMutationVariables>(
        UpdateVehicleDocument,
        options,
    )
}
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>
export type UpdateVehicleMutationResult = Apollo.MutationResult<Types.UpdateVehicleMutation>
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateVehicleMutation,
    Types.UpdateVehicleMutationVariables
>
export const CreateSavedLocationDocument = gql`
    mutation CreateSavedLocation(
        $name: String!
        $line1: String!
        $city: String!
        $state: String!
        $zip: String!
        $lat: Float!
        $lng: Float!
    ) {
        createSavedLocation(
            name: $name
            line1: $line1
            city: $city
            state: $state
            zip: $zip
            lat: $lat
            lng: $lng
        ) {
            id
            line1
            state
            zip
        }
    }
`
export type CreateSavedLocationMutationFn = Apollo.MutationFunction<
    Types.CreateSavedLocationMutation,
    Types.CreateSavedLocationMutationVariables
>

/**
 * __useCreateSavedLocationMutation__
 *
 * To run a mutation, you first call `useCreateSavedLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedLocationMutation, { data, loading, error }] = useCreateSavedLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      line1: // value for 'line1'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useCreateSavedLocationMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateSavedLocationMutation,
        Types.CreateSavedLocationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.CreateSavedLocationMutation,
        Types.CreateSavedLocationMutationVariables
    >(CreateSavedLocationDocument, options)
}
export type CreateSavedLocationMutationHookResult = ReturnType<
    typeof useCreateSavedLocationMutation
>
export type CreateSavedLocationMutationResult =
    Apollo.MutationResult<Types.CreateSavedLocationMutation>
export type CreateSavedLocationMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateSavedLocationMutation,
    Types.CreateSavedLocationMutationVariables
>
export const CreateJobDocument = gql`
    mutation CreateJob(
        $name: String!
        $instructions: String!
        $originId: Int!
        $destinationId: Int!
        $clientId: Int
    ) {
        createJob(
            name: $name
            instructions: $instructions
            originId: $originId
            destinationId: $destinationId
            clientId: $clientId
        ) {
            id
        }
    }
`
export type CreateJobMutationFn = Apollo.MutationFunction<
    Types.CreateJobMutation,
    Types.CreateJobMutationVariables
>

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      name: // value for 'name'
 *      instructions: // value for 'instructions'
 *      originId: // value for 'originId'
 *      destinationId: // value for 'destinationId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useCreateJobMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateJobMutation,
        Types.CreateJobMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateJobMutation, Types.CreateJobMutationVariables>(
        CreateJobDocument,
        options,
    )
}
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>
export type CreateJobMutationResult = Apollo.MutationResult<Types.CreateJobMutation>
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateJobMutation,
    Types.CreateJobMutationVariables
>
export const UpdateJobDocument = gql`
    mutation UpdateJob(
        $id: Int!
        $name: String
        $instructions: String
        $originId: Int
        $destinationId: Int
        $clientId: Int
    ) {
        updateJob(
            id: $id
            name: $name
            instructions: $instructions
            originId: $originId
            destinationId: $destinationId
            clientId: $clientId
        ) {
            id
        }
    }
`
export type UpdateJobMutationFn = Apollo.MutationFunction<
    Types.UpdateJobMutation,
    Types.UpdateJobMutationVariables
>

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      instructions: // value for 'instructions'
 *      originId: // value for 'originId'
 *      destinationId: // value for 'destinationId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useUpdateJobMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateJobMutation,
        Types.UpdateJobMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateJobMutation, Types.UpdateJobMutationVariables>(
        UpdateJobDocument,
        options,
    )
}
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>
export type UpdateJobMutationResult = Apollo.MutationResult<Types.UpdateJobMutation>
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateJobMutation,
    Types.UpdateJobMutationVariables
>
export const CreateJobMaterialDocument = gql`
    mutation CreateJobMaterial($jobId: Int!, $materialInput: CreateJobMaterialInput!) {
        createJobMaterial(jobId: $jobId, materialInput: $materialInput) {
            id
        }
    }
`
export type CreateJobMaterialMutationFn = Apollo.MutationFunction<
    Types.CreateJobMaterialMutation,
    Types.CreateJobMaterialMutationVariables
>

/**
 * __useCreateJobMaterialMutation__
 *
 * To run a mutation, you first call `useCreateJobMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMaterialMutation, { data, loading, error }] = useCreateJobMaterialMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      materialInput: // value for 'materialInput'
 *   },
 * });
 */
export function useCreateJobMaterialMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateJobMaterialMutation,
        Types.CreateJobMaterialMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.CreateJobMaterialMutation,
        Types.CreateJobMaterialMutationVariables
    >(CreateJobMaterialDocument, options)
}
export type CreateJobMaterialMutationHookResult = ReturnType<typeof useCreateJobMaterialMutation>
export type CreateJobMaterialMutationResult = Apollo.MutationResult<Types.CreateJobMaterialMutation>
export type CreateJobMaterialMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateJobMaterialMutation,
    Types.CreateJobMaterialMutationVariables
>
export const UpdateJobMaterialDocument = gql`
    mutation UpdateJobMaterial($materialId: Int!, $materialInput: UpdateJobMaterialInput!) {
        updateJobMaterial(materialId: $materialId, materialInput: $materialInput) {
            id
        }
    }
`
export type UpdateJobMaterialMutationFn = Apollo.MutationFunction<
    Types.UpdateJobMaterialMutation,
    Types.UpdateJobMaterialMutationVariables
>

/**
 * __useUpdateJobMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateJobMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMaterialMutation, { data, loading, error }] = useUpdateJobMaterialMutation({
 *   variables: {
 *      materialId: // value for 'materialId'
 *      materialInput: // value for 'materialInput'
 *   },
 * });
 */
export function useUpdateJobMaterialMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateJobMaterialMutation,
        Types.UpdateJobMaterialMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.UpdateJobMaterialMutation,
        Types.UpdateJobMaterialMutationVariables
    >(UpdateJobMaterialDocument, options)
}
export type UpdateJobMaterialMutationHookResult = ReturnType<typeof useUpdateJobMaterialMutation>
export type UpdateJobMaterialMutationResult = Apollo.MutationResult<Types.UpdateJobMaterialMutation>
export type UpdateJobMaterialMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateJobMaterialMutation,
    Types.UpdateJobMaterialMutationVariables
>
export const AssignEmployeeToJobDocument = gql`
    mutation AssignEmployeeToJob($jobId: Int!, $employeeId: Int!, $vehicleId: Int!) {
        assignEmployeeToJob(jobId: $jobId, employeeId: $employeeId, vehicleId: $vehicleId) {
            id
        }
    }
`
export type AssignEmployeeToJobMutationFn = Apollo.MutationFunction<
    Types.AssignEmployeeToJobMutation,
    Types.AssignEmployeeToJobMutationVariables
>

/**
 * __useAssignEmployeeToJobMutation__
 *
 * To run a mutation, you first call `useAssignEmployeeToJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignEmployeeToJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignEmployeeToJobMutation, { data, loading, error }] = useAssignEmployeeToJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      employeeId: // value for 'employeeId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAssignEmployeeToJobMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.AssignEmployeeToJobMutation,
        Types.AssignEmployeeToJobMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.AssignEmployeeToJobMutation,
        Types.AssignEmployeeToJobMutationVariables
    >(AssignEmployeeToJobDocument, options)
}
export type AssignEmployeeToJobMutationHookResult = ReturnType<
    typeof useAssignEmployeeToJobMutation
>
export type AssignEmployeeToJobMutationResult =
    Apollo.MutationResult<Types.AssignEmployeeToJobMutation>
export type AssignEmployeeToJobMutationOptions = Apollo.BaseMutationOptions<
    Types.AssignEmployeeToJobMutation,
    Types.AssignEmployeeToJobMutationVariables
>
export const DeleteTicketDocument = gql`
    mutation DeleteTicket($id: Int!) {
        deleteTicket(id: $id)
    }
`
export type DeleteTicketMutationFn = Apollo.MutationFunction<
    Types.DeleteTicketMutation,
    Types.DeleteTicketMutationVariables
>

/**
 * __useDeleteTicketMutation__
 *
 * To run a mutation, you first call `useDeleteTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketMutation, { data, loading, error }] = useDeleteTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DeleteTicketMutation,
        Types.DeleteTicketMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.DeleteTicketMutation, Types.DeleteTicketMutationVariables>(
        DeleteTicketDocument,
        options,
    )
}
export type DeleteTicketMutationHookResult = ReturnType<typeof useDeleteTicketMutation>
export type DeleteTicketMutationResult = Apollo.MutationResult<Types.DeleteTicketMutation>
export type DeleteTicketMutationOptions = Apollo.BaseMutationOptions<
    Types.DeleteTicketMutation,
    Types.DeleteTicketMutationVariables
>
export const CreateTicketDocument = gql`
    mutation CreateTicket($input: CreateTicketInput!) {
        createTicket(input: $input) {
            id
        }
    }
`
export type CreateTicketMutationFn = Apollo.MutationFunction<
    Types.CreateTicketMutation,
    Types.CreateTicketMutationVariables
>

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTicketMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateTicketMutation,
        Types.CreateTicketMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateTicketMutation, Types.CreateTicketMutationVariables>(
        CreateTicketDocument,
        options,
    )
}
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>
export type CreateTicketMutationResult = Apollo.MutationResult<Types.CreateTicketMutation>
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateTicketMutation,
    Types.CreateTicketMutationVariables
>
export const UpdateTicketDocument = gql`
    mutation UpdateTicket($input: UpdateTicketInput!) {
        updateTicket(input: $input) {
            id
        }
    }
`
export type UpdateTicketMutationFn = Apollo.MutationFunction<
    Types.UpdateTicketMutation,
    Types.UpdateTicketMutationVariables
>

/**
 * __useUpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketMutation, { data, loading, error }] = useUpdateTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTicketMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateTicketMutation,
        Types.UpdateTicketMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.UpdateTicketMutation, Types.UpdateTicketMutationVariables>(
        UpdateTicketDocument,
        options,
    )
}
export type UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicketMutation>
export type UpdateTicketMutationResult = Apollo.MutationResult<Types.UpdateTicketMutation>
export type UpdateTicketMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateTicketMutation,
    Types.UpdateTicketMutationVariables
>
export const BulkUpdateTicketsDocument = gql`
    mutation BulkUpdateTickets($input: BulkUpdateTicketInput!) {
        bulkUpdateTickets(input: $input) {
            id
        }
    }
`
export type BulkUpdateTicketsMutationFn = Apollo.MutationFunction<
    Types.BulkUpdateTicketsMutation,
    Types.BulkUpdateTicketsMutationVariables
>

/**
 * __useBulkUpdateTicketsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTicketsMutation, { data, loading, error }] = useBulkUpdateTicketsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateTicketsMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.BulkUpdateTicketsMutation,
        Types.BulkUpdateTicketsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.BulkUpdateTicketsMutation,
        Types.BulkUpdateTicketsMutationVariables
    >(BulkUpdateTicketsDocument, options)
}
export type BulkUpdateTicketsMutationHookResult = ReturnType<typeof useBulkUpdateTicketsMutation>
export type BulkUpdateTicketsMutationResult = Apollo.MutationResult<Types.BulkUpdateTicketsMutation>
export type BulkUpdateTicketsMutationOptions = Apollo.BaseMutationOptions<
    Types.BulkUpdateTicketsMutation,
    Types.BulkUpdateTicketsMutationVariables
>
export const DispatchJobDocument = gql`
    mutation DispatchJob($jobId: Int!) {
        dispatchJob(jobId: $jobId)
    }
`
export type DispatchJobMutationFn = Apollo.MutationFunction<
    Types.DispatchJobMutation,
    Types.DispatchJobMutationVariables
>

/**
 * __useDispatchJobMutation__
 *
 * To run a mutation, you first call `useDispatchJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDispatchJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dispatchJobMutation, { data, loading, error }] = useDispatchJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDispatchJobMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.DispatchJobMutation,
        Types.DispatchJobMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.DispatchJobMutation, Types.DispatchJobMutationVariables>(
        DispatchJobDocument,
        options,
    )
}
export type DispatchJobMutationHookResult = ReturnType<typeof useDispatchJobMutation>
export type DispatchJobMutationResult = Apollo.MutationResult<Types.DispatchJobMutation>
export type DispatchJobMutationOptions = Apollo.BaseMutationOptions<
    Types.DispatchJobMutation,
    Types.DispatchJobMutationVariables
>
export const FinishJobDocument = gql`
    mutation FinishJob($jobId: Int!) {
        finishJob(jobId: $jobId)
    }
`
export type FinishJobMutationFn = Apollo.MutationFunction<
    Types.FinishJobMutation,
    Types.FinishJobMutationVariables
>

/**
 * __useFinishJobMutation__
 *
 * To run a mutation, you first call `useFinishJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishJobMutation, { data, loading, error }] = useFinishJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFinishJobMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.FinishJobMutation,
        Types.FinishJobMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.FinishJobMutation, Types.FinishJobMutationVariables>(
        FinishJobDocument,
        options,
    )
}
export type FinishJobMutationHookResult = ReturnType<typeof useFinishJobMutation>
export type FinishJobMutationResult = Apollo.MutationResult<Types.FinishJobMutation>
export type FinishJobMutationOptions = Apollo.BaseMutationOptions<
    Types.FinishJobMutation,
    Types.FinishJobMutationVariables
>
export const ReOpenJobDocument = gql`
    mutation ReOpenJob($jobId: Int!) {
        reopenJob(jobId: $jobId)
    }
`
export type ReOpenJobMutationFn = Apollo.MutationFunction<
    Types.ReOpenJobMutation,
    Types.ReOpenJobMutationVariables
>

/**
 * __useReOpenJobMutation__
 *
 * To run a mutation, you first call `useReOpenJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReOpenJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reOpenJobMutation, { data, loading, error }] = useReOpenJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useReOpenJobMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ReOpenJobMutation,
        Types.ReOpenJobMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.ReOpenJobMutation, Types.ReOpenJobMutationVariables>(
        ReOpenJobDocument,
        options,
    )
}
export type ReOpenJobMutationHookResult = ReturnType<typeof useReOpenJobMutation>
export type ReOpenJobMutationResult = Apollo.MutationResult<Types.ReOpenJobMutation>
export type ReOpenJobMutationOptions = Apollo.BaseMutationOptions<
    Types.ReOpenJobMutation,
    Types.ReOpenJobMutationVariables
>
export const CreateSupplierMaterialDocument = gql`
    mutation CreateSupplierMaterial($materialInput: CreateSupplierMaterialInput!) {
        createSupplierMaterial(materialInput: $materialInput) {
            id
        }
    }
`
export type CreateSupplierMaterialMutationFn = Apollo.MutationFunction<
    Types.CreateSupplierMaterialMutation,
    Types.CreateSupplierMaterialMutationVariables
>

/**
 * __useCreateSupplierMaterialMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMaterialMutation, { data, loading, error }] = useCreateSupplierMaterialMutation({
 *   variables: {
 *      materialInput: // value for 'materialInput'
 *   },
 * });
 */
export function useCreateSupplierMaterialMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateSupplierMaterialMutation,
        Types.CreateSupplierMaterialMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.CreateSupplierMaterialMutation,
        Types.CreateSupplierMaterialMutationVariables
    >(CreateSupplierMaterialDocument, options)
}
export type CreateSupplierMaterialMutationHookResult = ReturnType<
    typeof useCreateSupplierMaterialMutation
>
export type CreateSupplierMaterialMutationResult =
    Apollo.MutationResult<Types.CreateSupplierMaterialMutation>
export type CreateSupplierMaterialMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateSupplierMaterialMutation,
    Types.CreateSupplierMaterialMutationVariables
>
export const UpdateSupplierMaterialDocument = gql`
    mutation UpdateSupplierMaterial(
        $materialId: Int!
        $materialInput: UpdateSupplierMaterialInput!
    ) {
        updateSupplierMaterial(materialId: $materialId, materialInput: $materialInput) {
            id
        }
    }
`
export type UpdateSupplierMaterialMutationFn = Apollo.MutationFunction<
    Types.UpdateSupplierMaterialMutation,
    Types.UpdateSupplierMaterialMutationVariables
>

/**
 * __useUpdateSupplierMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMaterialMutation, { data, loading, error }] = useUpdateSupplierMaterialMutation({
 *   variables: {
 *      materialId: // value for 'materialId'
 *      materialInput: // value for 'materialInput'
 *   },
 * });
 */
export function useUpdateSupplierMaterialMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.UpdateSupplierMaterialMutation,
        Types.UpdateSupplierMaterialMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.UpdateSupplierMaterialMutation,
        Types.UpdateSupplierMaterialMutationVariables
    >(UpdateSupplierMaterialDocument, options)
}
export type UpdateSupplierMaterialMutationHookResult = ReturnType<
    typeof useUpdateSupplierMaterialMutation
>
export type UpdateSupplierMaterialMutationResult =
    Apollo.MutationResult<Types.UpdateSupplierMaterialMutation>
export type UpdateSupplierMaterialMutationOptions = Apollo.BaseMutationOptions<
    Types.UpdateSupplierMaterialMutation,
    Types.UpdateSupplierMaterialMutationVariables
>
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($name: String!, $customerId: Int!) {
        createInvoice(name: $name, customerId: $customerId) {
            id
        }
    }
`
export type CreateInvoiceMutationFn = Apollo.MutationFunction<
    Types.CreateInvoiceMutation,
    Types.CreateInvoiceMutationVariables
>

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.CreateInvoiceMutation,
        Types.CreateInvoiceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.CreateInvoiceMutation, Types.CreateInvoiceMutationVariables>(
        CreateInvoiceDocument,
        options,
    )
}
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>
export type CreateInvoiceMutationResult = Apollo.MutationResult<Types.CreateInvoiceMutation>
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
    Types.CreateInvoiceMutation,
    Types.CreateInvoiceMutationVariables
>
export const ExportInvoiceToQuickBooksDocument = gql`
    mutation ExportInvoiceToQuickBooks($invoiceId: Int!) {
        exportInvoiceToQuickBooks(invoiceId: $invoiceId)
    }
`
export type ExportInvoiceToQuickBooksMutationFn = Apollo.MutationFunction<
    Types.ExportInvoiceToQuickBooksMutation,
    Types.ExportInvoiceToQuickBooksMutationVariables
>

/**
 * __useExportInvoiceToQuickBooksMutation__
 *
 * To run a mutation, you first call `useExportInvoiceToQuickBooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportInvoiceToQuickBooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportInvoiceToQuickBooksMutation, { data, loading, error }] = useExportInvoiceToQuickBooksMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useExportInvoiceToQuickBooksMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ExportInvoiceToQuickBooksMutation,
        Types.ExportInvoiceToQuickBooksMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        Types.ExportInvoiceToQuickBooksMutation,
        Types.ExportInvoiceToQuickBooksMutationVariables
    >(ExportInvoiceToQuickBooksDocument, options)
}
export type ExportInvoiceToQuickBooksMutationHookResult = ReturnType<
    typeof useExportInvoiceToQuickBooksMutation
>
export type ExportInvoiceToQuickBooksMutationResult =
    Apollo.MutationResult<Types.ExportInvoiceToQuickBooksMutation>
export type ExportInvoiceToQuickBooksMutationOptions = Apollo.BaseMutationOptions<
    Types.ExportInvoiceToQuickBooksMutation,
    Types.ExportInvoiceToQuickBooksMutationVariables
>
export const StartShiftDocument = gql`
    mutation StartShift {
        startShift {
            id
        }
    }
`
export type StartShiftMutationFn = Apollo.MutationFunction<
    Types.StartShiftMutation,
    Types.StartShiftMutationVariables
>

/**
 * __useStartShiftMutation__
 *
 * To run a mutation, you first call `useStartShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startShiftMutation, { data, loading, error }] = useStartShiftMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartShiftMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.StartShiftMutation,
        Types.StartShiftMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.StartShiftMutation, Types.StartShiftMutationVariables>(
        StartShiftDocument,
        options,
    )
}
export type StartShiftMutationHookResult = ReturnType<typeof useStartShiftMutation>
export type StartShiftMutationResult = Apollo.MutationResult<Types.StartShiftMutation>
export type StartShiftMutationOptions = Apollo.BaseMutationOptions<
    Types.StartShiftMutation,
    Types.StartShiftMutationVariables
>
export const EndShiftDocument = gql`
    mutation EndShift {
        endShift {
            id
        }
    }
`
export type EndShiftMutationFn = Apollo.MutationFunction<
    Types.EndShiftMutation,
    Types.EndShiftMutationVariables
>

/**
 * __useEndShiftMutation__
 *
 * To run a mutation, you first call `useEndShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endShiftMutation, { data, loading, error }] = useEndShiftMutation({
 *   variables: {
 *   },
 * });
 */
export function useEndShiftMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.EndShiftMutation,
        Types.EndShiftMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Types.EndShiftMutation, Types.EndShiftMutationVariables>(
        EndShiftDocument,
        options,
    )
}
export type EndShiftMutationHookResult = ReturnType<typeof useEndShiftMutation>
export type EndShiftMutationResult = Apollo.MutationResult<Types.EndShiftMutation>
export type EndShiftMutationOptions = Apollo.BaseMutationOptions<
    Types.EndShiftMutation,
    Types.EndShiftMutationVariables
>
export const GetCompanyDocument = gql`
    query GetCompany {
        getCompany {
            id
            name
            companyType
            createdAt
            createdByUserId
            vehicles {
                id
                vehicleId
                description
                assignedEmployee {
                    id
                    user {
                        id
                        uid
                        displayName
                    }
                }
            }
            employees {
                id
                user {
                    id
                    uid
                    displayName
                    phoneNumber
                }
                assignedVehicle {
                    id
                    vehicleId
                }
            }
        }
    }
`

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.GetCompanyQuery, Types.GetCompanyQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetCompanyQuery, Types.GetCompanyQueryVariables>(
        GetCompanyDocument,
        options,
    )
}
export function useGetCompanyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.GetCompanyQuery,
        Types.GetCompanyQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetCompanyQuery, Types.GetCompanyQueryVariables>(
        GetCompanyDocument,
        options,
    )
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>
export type GetCompanyQueryResult = Apollo.QueryResult<
    Types.GetCompanyQuery,
    Types.GetCompanyQueryVariables
>
export const WhoAmIDocument = gql`
    query WhoAmI {
        whoAmI {
            id
            uid
            displayName
            email
            phoneNumber
            employeeAccount {
                id
                isOwner
                employeeType
                company {
                    id
                    name
                    companyType
                }
                user {
                    id
                    displayName
                }
                assignedVehicle {
                    id
                    vehicleId
                }
            }
        }
    }
`

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.WhoAmIQuery, Types.WhoAmIQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.WhoAmIQuery, Types.WhoAmIQueryVariables>(WhoAmIDocument, options)
}
export function useWhoAmILazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.WhoAmIQuery, Types.WhoAmIQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.WhoAmIQuery, Types.WhoAmIQueryVariables>(
        WhoAmIDocument,
        options,
    )
}
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>
export type WhoAmIQueryResult = Apollo.QueryResult<Types.WhoAmIQuery, Types.WhoAmIQueryVariables>
export const DashboardOverviewDataDocument = gql`
    query DashboardOverviewData {
        getCompany {
            id
            vehicles {
                id
            }
            employees {
                id
            }
        }
        companyJobs {
            id
            name
            status
            origin {
                id
                latitude
                longitude
            }
            destination {
                id
                latitude
                longitude
            }
        }
        companySavedContacts {
            id
        }
        companySavedLocations {
            id
        }
    }
`

/**
 * __useDashboardOverviewDataQuery__
 *
 * To run a query within a React component, call `useDashboardOverviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOverviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOverviewDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardOverviewDataQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.DashboardOverviewDataQuery,
        Types.DashboardOverviewDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        Types.DashboardOverviewDataQuery,
        Types.DashboardOverviewDataQueryVariables
    >(DashboardOverviewDataDocument, options)
}
export function useDashboardOverviewDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.DashboardOverviewDataQuery,
        Types.DashboardOverviewDataQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        Types.DashboardOverviewDataQuery,
        Types.DashboardOverviewDataQueryVariables
    >(DashboardOverviewDataDocument, options)
}
export type DashboardOverviewDataQueryHookResult = ReturnType<typeof useDashboardOverviewDataQuery>
export type DashboardOverviewDataLazyQueryHookResult = ReturnType<
    typeof useDashboardOverviewDataLazyQuery
>
export type DashboardOverviewDataQueryResult = Apollo.QueryResult<
    Types.DashboardOverviewDataQuery,
    Types.DashboardOverviewDataQueryVariables
>
export const CompanySavedContactsDocument = gql`
    query CompanySavedContacts {
        companySavedContacts {
            id
            quickBooksCustomerId
            name
            phoneNumber
            emailAddress
            notes
        }
    }
`

/**
 * __useCompanySavedContactsQuery__
 *
 * To run a query within a React component, call `useCompanySavedContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySavedContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySavedContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySavedContactsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.CompanySavedContactsQuery,
        Types.CompanySavedContactsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        Types.CompanySavedContactsQuery,
        Types.CompanySavedContactsQueryVariables
    >(CompanySavedContactsDocument, options)
}
export function useCompanySavedContactsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.CompanySavedContactsQuery,
        Types.CompanySavedContactsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        Types.CompanySavedContactsQuery,
        Types.CompanySavedContactsQueryVariables
    >(CompanySavedContactsDocument, options)
}
export type CompanySavedContactsQueryHookResult = ReturnType<typeof useCompanySavedContactsQuery>
export type CompanySavedContactsLazyQueryHookResult = ReturnType<
    typeof useCompanySavedContactsLazyQuery
>
export type CompanySavedContactsQueryResult = Apollo.QueryResult<
    Types.CompanySavedContactsQuery,
    Types.CompanySavedContactsQueryVariables
>
export const CompanySavedLocationsDocument = gql`
    query CompanySavedLocations {
        companySavedLocations {
            id
            name
            line1
            city
            state
            zip
            latitude
            longitude
        }
    }
`

/**
 * __useCompanySavedLocationsQuery__
 *
 * To run a query within a React component, call `useCompanySavedLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySavedLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySavedLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySavedLocationsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.CompanySavedLocationsQuery,
        Types.CompanySavedLocationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        Types.CompanySavedLocationsQuery,
        Types.CompanySavedLocationsQueryVariables
    >(CompanySavedLocationsDocument, options)
}
export function useCompanySavedLocationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.CompanySavedLocationsQuery,
        Types.CompanySavedLocationsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        Types.CompanySavedLocationsQuery,
        Types.CompanySavedLocationsQueryVariables
    >(CompanySavedLocationsDocument, options)
}
export type CompanySavedLocationsQueryHookResult = ReturnType<typeof useCompanySavedLocationsQuery>
export type CompanySavedLocationsLazyQueryHookResult = ReturnType<
    typeof useCompanySavedLocationsLazyQuery
>
export type CompanySavedLocationsQueryResult = Apollo.QueryResult<
    Types.CompanySavedLocationsQuery,
    Types.CompanySavedLocationsQueryVariables
>
export const JobDocument = gql`
    query Job($id: Int!) {
        job(id: $id) {
            id
            name
            instructions
            status
            monitorId
            client {
                id
                name
            }
            progress {
                id
                ticketCount
                ticketTarget
                progressPercent
            }
            materials {
                id
                materialName
                unitSingular
                unitPlural
                expectedAmountPerTicket
                expectedAmountTotal
                effectivePricePerUnit
                pricePerUnitEmployee
                progress {
                    id
                    ticketCount
                    ticketTarget
                    materialProgress
                    materialTarget
                    progressPercent
                }
            }
            origin {
                id
                name
                line1
                city
                state
                zip
                latitude
                longitude
            }
            destination {
                id
                name
                line1
                city
                state
                zip
                latitude
                longitude
            }
            jobAssignments {
                id
                vehicle {
                    id
                    vehicleId
                }
                employee {
                    id
                    user {
                        id
                        uid
                        displayName
                    }
                }
            }
        }
    }
`

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobQuery(
    baseOptions: Apollo.QueryHookOptions<Types.JobQuery, Types.JobQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.JobQuery, Types.JobQueryVariables>(JobDocument, options)
}
export function useJobLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.JobQuery, Types.JobQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.JobQuery, Types.JobQueryVariables>(JobDocument, options)
}
export type JobQueryHookResult = ReturnType<typeof useJobQuery>
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>
export type JobQueryResult = Apollo.QueryResult<Types.JobQuery, Types.JobQueryVariables>
export const CompanyJobsDocument = gql`
    query CompanyJobs($includeDone: Boolean) {
        companyJobs(includeDone: $includeDone) {
            id
            createdAt
            name
            instructions
            status
            client {
                id
                name
            }
            materials {
                id
                materialName
            }
            origin {
                id
                name
                line1
                city
                state
                zip
                latitude
                longitude
            }
            destination {
                id
                name
                line1
                city
                state
                zip
                latitude
                longitude
            }
            progress {
                id
                ticketCount
                ticketTarget
                progressPercent
            }
        }
    }
`

/**
 * __useCompanyJobsQuery__
 *
 * To run a query within a React component, call `useCompanyJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyJobsQuery({
 *   variables: {
 *      includeDone: // value for 'includeDone'
 *   },
 * });
 */
export function useCompanyJobsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.CompanyJobsQuery, Types.CompanyJobsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.CompanyJobsQuery, Types.CompanyJobsQueryVariables>(
        CompanyJobsDocument,
        options,
    )
}
export function useCompanyJobsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.CompanyJobsQuery,
        Types.CompanyJobsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.CompanyJobsQuery, Types.CompanyJobsQueryVariables>(
        CompanyJobsDocument,
        options,
    )
}
export type CompanyJobsQueryHookResult = ReturnType<typeof useCompanyJobsQuery>
export type CompanyJobsLazyQueryHookResult = ReturnType<typeof useCompanyJobsLazyQuery>
export type CompanyJobsQueryResult = Apollo.QueryResult<
    Types.CompanyJobsQuery,
    Types.CompanyJobsQueryVariables
>
export const ListOfJobsDocument = gql`
    query ListOfJobs {
        companyJobs(includeDone: true) {
            id
            name
        }
    }
`

/**
 * __useListOfJobsQuery__
 *
 * To run a query within a React component, call `useListOfJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOfJobsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.ListOfJobsQuery, Types.ListOfJobsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.ListOfJobsQuery, Types.ListOfJobsQueryVariables>(
        ListOfJobsDocument,
        options,
    )
}
export function useListOfJobsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ListOfJobsQuery,
        Types.ListOfJobsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.ListOfJobsQuery, Types.ListOfJobsQueryVariables>(
        ListOfJobsDocument,
        options,
    )
}
export type ListOfJobsQueryHookResult = ReturnType<typeof useListOfJobsQuery>
export type ListOfJobsLazyQueryHookResult = ReturnType<typeof useListOfJobsLazyQuery>
export type ListOfJobsQueryResult = Apollo.QueryResult<
    Types.ListOfJobsQuery,
    Types.ListOfJobsQueryVariables
>
export const MyJobAssignmentsDocument = gql`
    query MyJobAssignments {
        myJobAssignments {
            id
            job {
                id
                name
                instructions
                client {
                    id
                    name
                }
                materials {
                    id
                    materialName
                    unitSingular
                    unitPlural
                    expectedAmountPerTicket
                    expectedAmountTotal
                }
                origin {
                    id
                    name
                    latitude
                    longitude
                }
                destination {
                    id
                    name
                    latitude
                    longitude
                }
            }
        }
    }
`

/**
 * __useMyJobAssignmentsQuery__
 *
 * To run a query within a React component, call `useMyJobAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyJobAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyJobAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyJobAssignmentsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.MyJobAssignmentsQuery,
        Types.MyJobAssignmentsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.MyJobAssignmentsQuery, Types.MyJobAssignmentsQueryVariables>(
        MyJobAssignmentsDocument,
        options,
    )
}
export function useMyJobAssignmentsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.MyJobAssignmentsQuery,
        Types.MyJobAssignmentsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.MyJobAssignmentsQuery, Types.MyJobAssignmentsQueryVariables>(
        MyJobAssignmentsDocument,
        options,
    )
}
export type MyJobAssignmentsQueryHookResult = ReturnType<typeof useMyJobAssignmentsQuery>
export type MyJobAssignmentsLazyQueryHookResult = ReturnType<typeof useMyJobAssignmentsLazyQuery>
export type MyJobAssignmentsQueryResult = Apollo.QueryResult<
    Types.MyJobAssignmentsQuery,
    Types.MyJobAssignmentsQueryVariables
>
export const MyTicketsDocument = gql`
    query MyTickets {
        whoAmI {
            id
            employeeAccount {
                id
                tickets {
                    id
                    identifier
                    timestamp
                    materialAmount
                    material {
                        id
                        type
                        materialName
                        unitSingular
                        unitPlural
                        expectedAmountPerTicket
                    }
                    job {
                        id
                        name
                    }
                    employeeEarnings
                }
            }
        }
    }
`

/**
 * __useMyTicketsQuery__
 *
 * To run a query within a React component, call `useMyTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTicketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTicketsQuery(
    baseOptions?: Apollo.QueryHookOptions<Types.MyTicketsQuery, Types.MyTicketsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.MyTicketsQuery, Types.MyTicketsQueryVariables>(
        MyTicketsDocument,
        options,
    )
}
export function useMyTicketsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.MyTicketsQuery, Types.MyTicketsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.MyTicketsQuery, Types.MyTicketsQueryVariables>(
        MyTicketsDocument,
        options,
    )
}
export type MyTicketsQueryHookResult = ReturnType<typeof useMyTicketsQuery>
export type MyTicketsLazyQueryHookResult = ReturnType<typeof useMyTicketsLazyQuery>
export type MyTicketsQueryResult = Apollo.QueryResult<
    Types.MyTicketsQuery,
    Types.MyTicketsQueryVariables
>
export const DoesTicketIdentifierExistDocument = gql`
    query DoesTicketIdentifierExist($identifier: String!, $jobId: Int) {
        doesTicketIdentifierExist(identifier: $identifier, jobId: $jobId)
    }
`

/**
 * __useDoesTicketIdentifierExistQuery__
 *
 * To run a query within a React component, call `useDoesTicketIdentifierExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoesTicketIdentifierExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoesTicketIdentifierExistQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useDoesTicketIdentifierExistQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.DoesTicketIdentifierExistQuery,
        Types.DoesTicketIdentifierExistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        Types.DoesTicketIdentifierExistQuery,
        Types.DoesTicketIdentifierExistQueryVariables
    >(DoesTicketIdentifierExistDocument, options)
}
export function useDoesTicketIdentifierExistLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.DoesTicketIdentifierExistQuery,
        Types.DoesTicketIdentifierExistQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        Types.DoesTicketIdentifierExistQuery,
        Types.DoesTicketIdentifierExistQueryVariables
    >(DoesTicketIdentifierExistDocument, options)
}
export type DoesTicketIdentifierExistQueryHookResult = ReturnType<
    typeof useDoesTicketIdentifierExistQuery
>
export type DoesTicketIdentifierExistLazyQueryHookResult = ReturnType<
    typeof useDoesTicketIdentifierExistLazyQuery
>
export type DoesTicketIdentifierExistQueryResult = Apollo.QueryResult<
    Types.DoesTicketIdentifierExistQuery,
    Types.DoesTicketIdentifierExistQueryVariables
>
export const CompanyTicketsDocument = gql`
    query CompanyTickets($params: FilterTicketParams!, $take: Int, $skip: Int) {
        companyTickets(params: $params, take: $take, skip: $skip) {
            id
            identifier
            timestamp
            materialAmount
            material {
                id
                type
                materialName
                unitSingular
                unitPlural
                expectedAmountPerTicket
            }
            job {
                id
                name
                client {
                    id
                    name
                }
            }
            uploader {
                id
                user {
                    id
                    displayName
                }
            }
            issuer {
                id
                name
            }
            transporter {
                id
                name
            }
            customer {
                id
                name
            }
            invoice {
                id
                name
            }
            revenue
            employeeEarnings
        }
    }
`

/**
 * __useCompanyTicketsQuery__
 *
 * To run a query within a React component, call `useCompanyTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTicketsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useCompanyTicketsQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.CompanyTicketsQuery,
        Types.CompanyTicketsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.CompanyTicketsQuery, Types.CompanyTicketsQueryVariables>(
        CompanyTicketsDocument,
        options,
    )
}
export function useCompanyTicketsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.CompanyTicketsQuery,
        Types.CompanyTicketsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.CompanyTicketsQuery, Types.CompanyTicketsQueryVariables>(
        CompanyTicketsDocument,
        options,
    )
}
export type CompanyTicketsQueryHookResult = ReturnType<typeof useCompanyTicketsQuery>
export type CompanyTicketsLazyQueryHookResult = ReturnType<typeof useCompanyTicketsLazyQuery>
export type CompanyTicketsQueryResult = Apollo.QueryResult<
    Types.CompanyTicketsQuery,
    Types.CompanyTicketsQueryVariables
>
export const EmployeeEarningsItemsDocument = gql`
    query EmployeeEarningsItems {
        employeeEarningsItems {
            id
            jobId
            jobName
            jobMaterialId
            materialName
            unitSingular
            unitPlural
            ticketCount
            materialAmount
            amountEarned
            pricePerUnit
        }
    }
`

/**
 * __useEmployeeEarningsItemsQuery__
 *
 * To run a query within a React component, call `useEmployeeEarningsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeEarningsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeEarningsItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeEarningsItemsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.EmployeeEarningsItemsQuery,
        Types.EmployeeEarningsItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        Types.EmployeeEarningsItemsQuery,
        Types.EmployeeEarningsItemsQueryVariables
    >(EmployeeEarningsItemsDocument, options)
}
export function useEmployeeEarningsItemsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.EmployeeEarningsItemsQuery,
        Types.EmployeeEarningsItemsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        Types.EmployeeEarningsItemsQuery,
        Types.EmployeeEarningsItemsQueryVariables
    >(EmployeeEarningsItemsDocument, options)
}
export type EmployeeEarningsItemsQueryHookResult = ReturnType<typeof useEmployeeEarningsItemsQuery>
export type EmployeeEarningsItemsLazyQueryHookResult = ReturnType<
    typeof useEmployeeEarningsItemsLazyQuery
>
export type EmployeeEarningsItemsQueryResult = Apollo.QueryResult<
    Types.EmployeeEarningsItemsQuery,
    Types.EmployeeEarningsItemsQueryVariables
>
export const CompanyEmployeeDocument = gql`
    query CompanyEmployee($employeeId: Int!) {
        companyEmployee(employeeId: $employeeId) {
            id
            user {
                id
                displayName
            }
            activeShift {
                id
                timestampStart
                timestampEnd
                notes
            }
            pastShifts {
                id
                timestampStart
                timestampEnd
                notes
            }
            tickets {
                id
                timestamp
                identifier
                materialAmount
                job {
                    id
                    name
                }
                material {
                    id
                    type
                    materialName
                    unitSingular
                    unitPlural
                    expectedAmountPerTicket
                }
                employeeEarnings
            }
        }
    }
`

/**
 * __useCompanyEmployeeQuery__
 *
 * To run a query within a React component, call `useCompanyEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useCompanyEmployeeQuery(
    baseOptions: Apollo.QueryHookOptions<
        Types.CompanyEmployeeQuery,
        Types.CompanyEmployeeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.CompanyEmployeeQuery, Types.CompanyEmployeeQueryVariables>(
        CompanyEmployeeDocument,
        options,
    )
}
export function useCompanyEmployeeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.CompanyEmployeeQuery,
        Types.CompanyEmployeeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.CompanyEmployeeQuery, Types.CompanyEmployeeQueryVariables>(
        CompanyEmployeeDocument,
        options,
    )
}
export type CompanyEmployeeQueryHookResult = ReturnType<typeof useCompanyEmployeeQuery>
export type CompanyEmployeeLazyQueryHookResult = ReturnType<typeof useCompanyEmployeeLazyQuery>
export type CompanyEmployeeQueryResult = Apollo.QueryResult<
    Types.CompanyEmployeeQuery,
    Types.CompanyEmployeeQueryVariables
>
export const GetTicketDocument = gql`
    query GetTicket($ticketId: Int!) {
        ticket(ticketId: $ticketId) {
            id
            identifier
            timestamp
            materialAmount
            notes
            revenue
            file {
                id
                originalFileName
                mimeType
                size
            }
            ocrFile {
                id
                mimeType
            }
            job {
                id
                name
            }
            material {
                id
                type
                materialName
                unitSingular
                unitPlural
                expectedAmountPerTicket
            }
            uploader {
                id
                user {
                    id
                    displayName
                }
            }
            issuer {
                id
                name
            }
            transporter {
                id
                name
            }
            customer {
                id
                name
            }
        }
    }
`

/**
 * __useGetTicketQuery__
 *
 * To run a query within a React component, call `useGetTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketQuery(
    baseOptions: Apollo.QueryHookOptions<Types.GetTicketQuery, Types.GetTicketQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.GetTicketQuery, Types.GetTicketQueryVariables>(
        GetTicketDocument,
        options,
    )
}
export function useGetTicketLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTicketQuery, Types.GetTicketQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.GetTicketQuery, Types.GetTicketQueryVariables>(
        GetTicketDocument,
        options,
    )
}
export type GetTicketQueryHookResult = ReturnType<typeof useGetTicketQuery>
export type GetTicketLazyQueryHookResult = ReturnType<typeof useGetTicketLazyQuery>
export type GetTicketQueryResult = Apollo.QueryResult<
    Types.GetTicketQuery,
    Types.GetTicketQueryVariables
>
export const SupplierMaterialsDocument = gql`
    query SupplierMaterials {
        supplierMaterials {
            id
            materialName
            unitSingular
            unitPlural
            expectedAmountPerTicket
            pricePerUnit
        }
    }
`

/**
 * __useSupplierMaterialsQuery__
 *
 * To run a query within a React component, call `useSupplierMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierMaterialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplierMaterialsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.SupplierMaterialsQuery,
        Types.SupplierMaterialsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.SupplierMaterialsQuery, Types.SupplierMaterialsQueryVariables>(
        SupplierMaterialsDocument,
        options,
    )
}
export function useSupplierMaterialsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.SupplierMaterialsQuery,
        Types.SupplierMaterialsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.SupplierMaterialsQuery, Types.SupplierMaterialsQueryVariables>(
        SupplierMaterialsDocument,
        options,
    )
}
export type SupplierMaterialsQueryHookResult = ReturnType<typeof useSupplierMaterialsQuery>
export type SupplierMaterialsLazyQueryHookResult = ReturnType<typeof useSupplierMaterialsLazyQuery>
export type SupplierMaterialsQueryResult = Apollo.QueryResult<
    Types.SupplierMaterialsQuery,
    Types.SupplierMaterialsQueryVariables
>
export const InvoiceDocument = gql`
    query Invoice($id: Int!) {
        invoice(id: $id) {
            id
            createdAt
            name
            quickBooksInvoiceId
            customer {
                id
                name
                quickBooksCustomerId
            }
            tickets {
                id
                timestamp
                identifier
                materialAmount
                material {
                    materialName
                    unitSingular
                    unitPlural
                    pricePerUnit
                }
                revenue
            }
            lineItems {
                Description
                Amount
                SalesItemLineDetail {
                    Qty
                    UnitPrice
                }
            }
        }
    }
`

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(
    baseOptions: Apollo.QueryHookOptions<Types.InvoiceQuery, Types.InvoiceQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.InvoiceQuery, Types.InvoiceQueryVariables>(
        InvoiceDocument,
        options,
    )
}
export function useInvoiceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<Types.InvoiceQuery, Types.InvoiceQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.InvoiceQuery, Types.InvoiceQueryVariables>(
        InvoiceDocument,
        options,
    )
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>
export type InvoiceQueryResult = Apollo.QueryResult<Types.InvoiceQuery, Types.InvoiceQueryVariables>
export const ListInvoicesDocument = gql`
    query ListInvoices {
        listInvoices {
            id
            createdAt
            name
            quickBooksInvoiceId
            customer {
                id
                name
            }
        }
    }
`

/**
 * __useListInvoicesQuery__
 *
 * To run a query within a React component, call `useListInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListInvoicesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.ListInvoicesQuery,
        Types.ListInvoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.ListInvoicesQuery, Types.ListInvoicesQueryVariables>(
        ListInvoicesDocument,
        options,
    )
}
export function useListInvoicesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.ListInvoicesQuery,
        Types.ListInvoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.ListInvoicesQuery, Types.ListInvoicesQueryVariables>(
        ListInvoicesDocument,
        options,
    )
}
export type ListInvoicesQueryHookResult = ReturnType<typeof useListInvoicesQuery>
export type ListInvoicesLazyQueryHookResult = ReturnType<typeof useListInvoicesLazyQuery>
export type ListInvoicesQueryResult = Apollo.QueryResult<
    Types.ListInvoicesQuery,
    Types.ListInvoicesQueryVariables
>
export const EmployeeShiftsDocument = gql`
    query EmployeeShifts {
        listEmployees {
            id
            user {
                id
                displayName
            }
            activeShift {
                id
                timestampStart
                timestampEnd
                notes
            }
            pastShifts {
                id
                timestampStart
                timestampEnd
                notes
            }
        }
    }
`

/**
 * __useEmployeeShiftsQuery__
 *
 * To run a query within a React component, call `useEmployeeShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeShiftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeShiftsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        Types.EmployeeShiftsQuery,
        Types.EmployeeShiftsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<Types.EmployeeShiftsQuery, Types.EmployeeShiftsQueryVariables>(
        EmployeeShiftsDocument,
        options,
    )
}
export function useEmployeeShiftsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        Types.EmployeeShiftsQuery,
        Types.EmployeeShiftsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<Types.EmployeeShiftsQuery, Types.EmployeeShiftsQueryVariables>(
        EmployeeShiftsDocument,
        options,
    )
}
export type EmployeeShiftsQueryHookResult = ReturnType<typeof useEmployeeShiftsQuery>
export type EmployeeShiftsLazyQueryHookResult = ReturnType<typeof useEmployeeShiftsLazyQuery>
export type EmployeeShiftsQueryResult = Apollo.QueryResult<
    Types.EmployeeShiftsQuery,
    Types.EmployeeShiftsQueryVariables
>
