import React from 'react'
import { Button, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { QuickBooksConnectButton } from './QuickBooksConnectButton'
import { QuickBooksDisconnectButton } from './QuickBooksDisconnectButton'
import { useFetch } from '../../hooks/useFetch'

export const QuickBooksIntegrationView: React.FC = () => {
    const {
        data,
        fetch: refetchToken,
        loading: isLoading,
    } = useFetch<{ token: object | null; record: object | null }>('/api/intuit/token')
    const { fetch: syncCustomers, loading } = useFetch('/api/intuit/sync_customers', {
        method: 'POST',
    })
    const buttonToShow = data?.token ? (
        <QuickBooksDisconnectButton onDisconnect={refetchToken} />
    ) : (
        <QuickBooksConnectButton />
    )
    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="h4">QuickBooks</Typography>
            <Box marginY={2}>{isLoading ? <CircularProgress /> : buttonToShow}</Box>
            {Boolean(data?.token) && (
                <Box>
                    <Button onClick={syncCustomers} disabled={loading}>
                        Synchronize Contacts
                    </Button>
                </Box>
            )}
        </Box>
    )
}
