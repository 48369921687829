import { Alert, Box, Button, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useStartShiftMutation } from '../../graphql/gen/hooks'
import { useGeoLocation } from '../../hooks/useGeoLocation'
import { distanceInKmBetweenGeoPoints, LatLngObj } from '../../lib/geo'

type Props = {
    refetch: () => any
}

export const JOBSITE_GEO_POINT: LatLngObj = {
    lat: 28.239656135785676,
    lng: -81.24556109545067,
}

export const NoActiveShiftView: React.FC<Props> = ({ refetch }) => {
    const [startShift, { loading }] = useStartShiftMutation({
        onCompleted: () => {
            refetch()
        },
    })
    const { pos } = useGeoLocation()
    const distance = useMemo(() => {
        if (!pos) {
            return 99999
        }
        return distanceInKmBetweenGeoPoints(JOBSITE_GEO_POINT, pos)
    }, [pos])
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {!pos && (
                <Box marginBottom={2}>
                    <Alert severity="info">
                        <Typography>Waiting for GPS...</Typography>
                    </Alert>
                </Box>
            )}
            {pos && distance > 0.1 && (
                <Box marginBottom={2}>
                    <Alert severity="error">
                        <Typography>You are not at the job site...</Typography>
                    </Alert>
                </Box>
            )}
            <Typography fontWeight="bold">You are not currently working...</Typography>
            <Button
                variant="contained"
                sx={{
                    marginTop: 2,
                }}
                disabled={loading || !pos || distance > 0.1}
                onClick={() => startShift()}
            >
                Start Shift
            </Button>
        </Box>
    )
}
