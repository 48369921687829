import { Add, Circle } from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useCompanyJobsQuery } from '../../../../graphql/gen/hooks'
import { JobStatus } from '../../../../graphql/gen/schemas'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { EditJobModal } from './EditJobModal'
import { Line } from 'rc-progress'
import { isMobile } from '../../../../lib/navigator'
import { useMemo, useState } from 'react'
import { convertJobStatusIntoSortOrder } from '../../../../lib/sorting'
import { formatClientName } from '../../../../lib/formatting'
import moment from 'moment'
import { useSimpleTextSearch } from '../../../../hooks/useSimpleTextSearch'

export const DashboardJobsPage: React.FC = () => {
    const [showDoneJobs, setShowDoneJobs] = useState(false)
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const navigate = useNavigate()
    const { data, refetch, loading } = useCompanyJobsQuery({
        variables: {
            includeDone: showDoneJobs,
        },
    })
    const {
        results: searchHitJobs,
        searchText,
        setSearchText,
    } = useSimpleTextSearch(
        data?.companyJobs ?? [],
        job => job.id,
        job => [
            job.name,
            job.instructions,
            job.client?.name,
            job.origin.name,
            job.destination.name,
            ...job.materials.map(mat => mat.materialName),
        ],
    )
    const sortedJobs = useMemo(() => {
        if (searchText !== '') {
            return searchHitJobs
        }
        return [...searchHitJobs].sort((a, b) => {
            const aStatus = convertJobStatusIntoSortOrder(a.status)
            const bStatus = convertJobStatusIntoSortOrder(b.status)
            if (aStatus < bStatus) {
                return -1
            } else if (bStatus < aStatus) {
                return 1
            }
            const aProgress = a.progress.progressPercent
            const bProgress = b.progress.progressPercent
            if (aProgress > bProgress) {
                return -1
            } else if (bProgress > aProgress) {
                return 1
            }
            const aDt = moment(a.createdAt).format()
            const bDt = moment(b.createdAt).format()
            if (aDt > bDt) {
                return -1
            } else if (aDt < bDt) {
                return 1
            }
            return 0
        })
    }, [searchHitJobs, searchText])
    return (
        <>
            <EditJobModal open={isVisible} onClose={closeModal} refetch={refetch} />
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Typography variant="h5">Jobs</Typography>
                    <Button variant="contained" onClick={openModal} startIcon={<Add />}>
                        Create Job
                    </Button>
                </Box>
                <Grid container columnSpacing={2}>
                    <Grid item sm={12} md={4}>
                        <Box marginTop={2}>
                            <TextField
                                fullWidth
                                placeholder="Search Jobs..."
                                value={searchText}
                                onChange={e => setSearchText(e.currentTarget.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={3} display="flex" alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showDoneJobs}
                                    onChange={() => setShowDoneJobs(!showDoneJobs)}
                                />
                            }
                            label="Include Completed"
                        />
                    </Grid>
                </Grid>
                <Box flexGrow={1} marginTop={3}>
                    <DataGrid
                        rows={sortedJobs}
                        columnVisibilityModel={{
                            origin: !isMobile,
                            destination: !isMobile,
                        }}
                        autoHeight={isMobile}
                        loading={loading}
                        columns={[
                            {
                                field: 'status',
                                headerName: 'Status',
                                disableExport: true,
                                minWidth: isMobile ? 150 : 220,
                                valueGetter: params => {
                                    if (params.row.status === JobStatus.InProgress) {
                                        return params.row.progress.progressPercent
                                    }
                                    return convertJobStatusIntoSortOrder(params.row.status)
                                },
                                renderCell: params => {
                                    let color = 'orange'
                                    let text = 'Pending'
                                    const status = params.row.status
                                    if (status === JobStatus.InProgress) {
                                        const progress = params.row.progress.progressPercent * 100
                                        const color = progress > 100 ? 'darkGreen' : 'green'
                                        return (
                                            <Box width="100%" display="flex" flexDirection="column">
                                                <Typography marginBottom={1} color={color}>
                                                    In-Progress {progress.toFixed(0)}%
                                                </Typography>
                                                <Line
                                                    trailWidth={4}
                                                    strokeWidth={4}
                                                    percent={progress}
                                                    strokeColor={color}
                                                />
                                            </Box>
                                        )
                                    } else if (status === JobStatus.Done) {
                                        color = 'blue'
                                        text = 'Done'
                                    }
                                    return (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            sx={{
                                                color,
                                            }}
                                        >
                                            <Circle
                                                sx={{
                                                    marginRight: 1,
                                                }}
                                            />
                                            <Typography>{text}</Typography>
                                        </Box>
                                    )
                                },
                            },
                            {
                                field: 'startDate',
                                headerName: 'Start Date',
                                minWidth: 75,
                                flex: 1,
                                valueGetter: params => params.row.createdAt,
                                renderCell: params => {
                                    return moment(params.row.createdAt).format('MMM Do YYYY')
                                },
                            },
                            {
                                field: 'client',
                                headerName: 'Client',
                                minWidth: 150,
                                flex: 1,
                                valueGetter: params => formatClientName(params.row.client),
                            },
                            {
                                field: 'name',
                                headerName: 'Name',
                                minWidth: 250,
                                flex: 2,
                                valueGetter: params =>
                                    `${params.row.name} (${
                                        params.row.materials
                                            .map(mat => mat.materialName)
                                            .join(', ') ?? 'No Materials'
                                    })`,
                            },
                            // {
                            //     field: 'origin',
                            //     headerName: 'Origin',
                            //     minWidth: 150,
                            //     flex: 1,
                            //     valueGetter: params => params.row.origin.name,
                            // },
                            // {
                            //     field: 'destination',
                            //     headerName: 'Destination',
                            //     minWidth: 150,
                            //     flex: 1,
                            //     valueGetter: params => params.row.destination.name,
                            // },
                            {
                                field: 'viewButton',
                                headerName: '',
                                disableExport: true,
                                renderCell: params => (
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            navigate(`/dashboard/job/${params.row.id}`)
                                        }}
                                    >
                                        Open
                                    </Button>
                                ),
                            },
                        ]}
                    />
                </Box>
            </Card>
        </>
    )
}
