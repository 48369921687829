import { useCallback } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export const useEmployeeInviteCode = () => {
    const [employeeInviteCode, setEmployeeInviteCode] = useLocalStorage<string | null>(
        'employeeInviteCode',
        null,
    )
    const deleteEmployeeInviteCode = useCallback(() => {
        localStorage.removeItem('employeeInviteCode')
    }, [])
    return {
        employeeInviteCode,
        setEmployeeInviteCode,
        deleteEmployeeInviteCode,
    }
}
