import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmployeeType } from '../../../graphql/gen/schemas'
import { useWhoAmI } from '../../../hooks/useWhoAmI'

export const ConsoleIndexNavigatorPage: React.FC = () => {
    const { whoAmI } = useWhoAmI()
    const navigate = useNavigate()
    useEffect(() => {
        if (whoAmI?.employeeAccount) {
            if (whoAmI.employeeAccount.employeeType === EmployeeType.ShiftOnly) {
                navigate('/console/shifts', {
                    replace: true,
                })
            } else {
                navigate('/console/upload', {
                    replace: true,
                })
            }
        }
    }, [whoAmI, navigate])
    return null
}
