import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import { SimpleModal } from '../../../../components/SimpleModal'
import { useCallback, useState } from 'react'
import {
    deriveLocationContextFromGeocoderResult,
    LatLngObj,
    LocationAddressObject,
} from '../../../../lib/geo'
import { MapboxMap } from '../../../../components/map/MapboxMap'
import { MapGeocoder } from '../../../../components/map/MapGeocoder'
import { MapMarker } from '../../../../components/map/MapMarker'
import { NewLocationConfirmationView } from './NewLocationConfirmationView'
import {
    useCompanySavedLocationsQuery,
    useCreateSavedLocationMutation,
} from '../../../../graphql/gen/hooks'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'

type Props = {
    open: boolean
    onClose: () => void
}

export const NewLocationModal: React.FC<Props> = ({ open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [latLng, setLatLng] = useState<LatLngObj | null>(null)
    const [selectedLocation, setSelectedLocation] = useState<LocationAddressObject | null>(null)
    const [isConfirming, setIsConfirming] = useState(false)
    const disableConfirming = useCallback(() => setIsConfirming(false), [setIsConfirming])
    const [createSavedLocation] = useCreateSavedLocationMutation()
    const { refetch } = useCompanySavedLocationsQuery({
        skip: true,
    })
    const onSave = useCallback(
        async (loc: LocationAddressObject) => {
            await createSavedLocation({
                variables: {
                    line1: loc.address,
                    city: loc.city,
                    state: loc.state,
                    zip: loc.zip,
                    lat: loc.lat,
                    lng: loc.lng,
                    name: loc.name,
                },
            })
            await refetch()
            onClose()
            disableConfirming()
            enqueueSnackbar('Successfully saved new location.', {
                variant: 'success',
            })
        },
        [onClose, disableConfirming],
    )
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="lg">
                <Typography variant="h5">Find new location</Typography>
                {isConfirming && selectedLocation ? (
                    <NewLocationConfirmationView
                        location={selectedLocation}
                        goBack={disableConfirming}
                        onSave={onSave}
                    />
                ) : (
                    <Box>
                        <Typography marginTop={2}>
                            You can search for an address on this map, you can also click on the map
                            to correct the address' pin...
                        </Typography>
                        <Box
                            marginY={2}
                            display="flex"
                            flex="1 1"
                            minHeight={window.innerHeight * 0.7}
                        >
                            <MapboxMap
                                onClick={e => {
                                    setLatLng(e.lngLat)
                                }}
                            >
                                <MapGeocoder
                                    onResult={result => {
                                        setLatLng({
                                            lat: result.result.center[1],
                                            lng: result.result.center[0],
                                        })
                                        setSelectedLocation(
                                            deriveLocationContextFromGeocoderResult(result),
                                        )
                                    }}
                                />
                                <MapMarker loc={latLng} />
                            </MapboxMap>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    startIcon={<Delete />}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!latLng || !selectedLocation}
                                    onClick={() => setIsConfirming(true)}
                                >
                                    Next step
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </SimpleModal>
        </Modal>
    )
}
