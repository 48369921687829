import { Delete, Save } from '@mui/icons-material'
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { floatRegex } from '../../../../../lib/constants/regex'
import { useCreateJobMaterialMutation } from '../../../../../graphql/gen/hooks'
import { ALL_MATERIALS, ALL_MATERIAL_UNIT_PRESETS } from '../../../../../lib/constants/materials'
import { SimpleModal } from '../../../../../components/SimpleModal'

type Props = {
    open: boolean
    onClose: () => void
    refetch: () => void
    jobId: number
}

const formSchema = yup.object().shape({
    materialName: yup.string().required('You must select a material'),
    unitSingular: yup.string().required('You must select a unit of measurement'),
    pricePerUnit: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the price per unit of material'),
    pricePerUnitEmployee: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the employee earnings per unit of material'),
    expectedMaterialPerTicket: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the expected units of material per ticket'),
    expectedAmountForJob: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the expected units of material for the job'),
})

export const NewJobMaterialModal: React.FC<Props> = ({ open, onClose, refetch, jobId }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit, register, formState, setValue, watch } = useForm<{
        materialName: string | null
        unitSingular: string | null
        expectedMaterialPerTicket: string
        expectedAmountForJob: string
        pricePerUnit: string
        pricePerUnitEmployee: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            materialName: '',
            unitSingular: '',
            expectedMaterialPerTicket: '',
            expectedAmountForJob: '',
            pricePerUnit: '',
            pricePerUnitEmployee: '',
        },
    })
    const formErrors = formState.errors
    const [createJobMaterial] = useCreateJobMaterialMutation()
    const [selectedMaterialName, selectedUnitSingular] = watch(['materialName', 'unitSingular'])
    const selectedUnit = ALL_MATERIAL_UNIT_PRESETS.find(p => p.singular === selectedUnitSingular)
    const onSubmit = handleSubmit(async data => {
        await createJobMaterial({
            variables: {
                jobId,
                materialInput: {
                    materialName: data.materialName,
                    unitSingular: selectedUnit.singular,
                    unitPlural: selectedUnit.plural,
                    expectedAmountPerTicket: parseFloat(data.expectedMaterialPerTicket),
                    expectedAmountForJob: parseFloat(data.expectedAmountForJob),
                    pricePerUnit: parseFloat(data.pricePerUnit),
                    pricePerUnitEmployee: parseFloat(data.pricePerUnitEmployee),
                },
            },
        })
        await refetch()
        onClose()
        enqueueSnackbar('Successfully created new material', {
            variant: 'success',
        })
    })
    useEffect(() => {
        if (selectedUnit) {
            setValue('expectedMaterialPerTicket', selectedUnit.defaultExpectedAmount.toString(), {
                shouldValidate: true,
                shouldDirty: true,
            })
            setValue('expectedAmountForJob', selectedUnit.defaultExpectedAmount.toString(), {
                shouldValidate: true,
                shouldDirty: true,
            })
        }
    }, [selectedUnitSingular])
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Add a new material</Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <Box display="flex" flexDirection="column">
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-unit-type">
                                        Unit of measurement
                                    </InputLabel>
                                    <Select
                                        labelId="select-unit-type"
                                        label="Unit of measurement"
                                        defaultValue=""
                                        {...register('unitSingular')}
                                    >
                                        {ALL_MATERIAL_UNIT_PRESETS.map(preset => (
                                            <MenuItem key={preset.singular} value={preset.singular}>
                                                {preset.plural}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(formErrors.materialName)}>
                                        {formErrors.materialName?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth sx={{ marginRight: 2, marginBottom: 2 }}>
                                    <InputLabel id="select-material-type">Material type</InputLabel>
                                    <Select
                                        labelId="select-material-type"
                                        label="Material type"
                                        defaultValue=""
                                        {...register('materialName')}
                                    >
                                        {ALL_MATERIALS.map(mat => (
                                            <MenuItem key={mat} value={mat}>
                                                {mat}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(formErrors.materialName)}>
                                        {formErrors.materialName?.message}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    {selectedUnit && selectedMaterialName && (
                        <>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography marginBottom={2} variant="h5">
                                Expected work
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        label={`Expected ${selectedUnit.plural} of ${selectedMaterialName} per ticket`}
                                        error={Boolean(formErrors.expectedMaterialPerTicket)}
                                        helperText={
                                            formErrors.expectedMaterialPerTicket ? (
                                                <Typography>
                                                    {formErrors.expectedMaterialPerTicket.message}
                                                </Typography>
                                            ) : null
                                        }
                                        {...register('expectedMaterialPerTicket')}
                                        sx={{
                                            marginRight: 2,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        label={`Expected ${selectedUnit?.plural} of ${selectedMaterialName} for the entire job`}
                                        error={Boolean(formErrors.expectedAmountForJob)}
                                        helperText={
                                            formErrors.expectedAmountForJob ? (
                                                <Typography>
                                                    {formErrors.expectedAmountForJob.message}
                                                </Typography>
                                            ) : null
                                        }
                                        {...register('expectedAmountForJob')}
                                    />
                                </Grid>
                            </Grid>
                            <Divider sx={{ marginY: 2 }} />
                            <Typography variant="h5">Revenue</Typography>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        label={`$ Price per ${selectedUnit?.singular} of ${selectedMaterialName}`}
                                        error={Boolean(formErrors.pricePerUnit)}
                                        helperText={
                                            formErrors.pricePerUnit ? (
                                                <Typography>
                                                    {formErrors.pricePerUnit.message}
                                                </Typography>
                                            ) : null
                                        }
                                        {...register('pricePerUnit')}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        label={`$ Employee earnings per ${selectedUnit?.singular} of ${selectedMaterialName}`}
                                        error={Boolean(formErrors.pricePerUnitEmployee)}
                                        helperText={
                                            formErrors.pricePerUnitEmployee ? (
                                                <Typography>
                                                    {formErrors.pricePerUnitEmployee.message}
                                                </Typography>
                                            ) : null
                                        }
                                        {...register('pricePerUnitEmployee')}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Delete />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<Save />}
                                disabled={!formState.isValid || formState.isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
