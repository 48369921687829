import { Alert, Box, Button, Typography } from '@mui/material'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { useEndShiftMutation } from '../../graphql/gen/hooks'
import { CompanyEmployeeQuery } from '../../graphql/gen/operations'
import { useGeoLocation } from '../../hooks/useGeoLocation'
import { distanceInKmBetweenGeoPoints } from '../../lib/geo'
import { JOBSITE_GEO_POINT } from './NoActiveShiftView'

type Props = {
    shift: CompanyEmployeeQuery['companyEmployee']['activeShift']
    refetch: () => any
}

export const ActiveShiftView: React.FC<Props> = ({ shift, refetch }) => {
    const [tsNow, setTsNow] = useState(moment())
    useInterval(() => setTsNow(moment()), 10000)
    const [diffHrs, diffMins] = useMemo(() => {
        if (!shift) {
            return [0, 0]
        }
        const tsStart = moment(shift.timestampStart)
        const totalMinutes = tsNow.diff(tsStart, 'minutes')
        return [Math.floor(totalMinutes / 60), totalMinutes % 60]
    }, [tsNow, shift])
    const [endShift, { loading }] = useEndShiftMutation({
        onCompleted: () => {
            refetch()
        },
    })
    const { pos } = useGeoLocation()
    const distance = useMemo(() => {
        if (!pos) {
            return 99999
        }
        return distanceInKmBetweenGeoPoints(JOBSITE_GEO_POINT, pos)
    }, [pos])
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {!pos && (
                <Box marginBottom={2}>
                    <Alert severity="info">
                        <Typography>Waiting for GPS...</Typography>
                    </Alert>
                </Box>
            )}
            {pos && distance > 0.1 && (
                <Box marginBottom={2}>
                    <Alert severity="error">
                        <Typography>You are not at the job site...</Typography>
                    </Alert>
                </Box>
            )}
            <Typography variant="h5" marginRight={2} textAlign="center">
                You have worked{' '}
                <Typography fontWeight="bold" color="green">
                    {diffHrs} hrs and {diffMins} mins
                </Typography>
            </Typography>
            <Box marginTop={2}>
                <Button
                    variant="contained"
                    onClick={() => endShift()}
                    disabled={loading || !pos || distance > 0.1}
                >
                    End Shift
                </Button>
            </Box>
        </Box>
    )
}
