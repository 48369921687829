import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { useMemo } from 'react'
import { usdFormatter } from '../../lib/formatting'
import { groupBy } from '../../lib/groupBy'
import { TicketObject } from '../../lib/types/gqlTypeAbbreviations'

type Props = {
    tickets: TicketObject[]
}

export const TicketCollectionPayrollAccordion: React.FC<Props> = ({ tickets }) => {
    const employeePayrollMap = useMemo(() => {
        const employeeTickets = groupBy(tickets, ticket => ticket.uploader.user.displayName)
        const map: Record<string, number> = {}
        Object.keys(employeeTickets).map(employee => {
            map[employee] = employeeTickets[employee].reduce(
                (total, ticket) => total + ticket.employeeEarnings,
                0,
            )
        })
        return map
    }, [tickets])
    const employeePayrollTotal = useMemo(() => {
        return Object.values(employeePayrollMap).reduce((total, cur) => total + cur, 0)
    }, [employeePayrollMap])
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                Payroll Totals ({usdFormatter.format(employeePayrollTotal)})
            </AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Employee</TableCell>
                            <TableCell>Payroll</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(employeePayrollMap).map(employeeName => {
                            return (
                                <TableRow key={employeeName}>
                                    <TableCell>{employeeName}</TableCell>
                                    <TableCell>
                                        {usdFormatter.format(employeePayrollMap[employeeName])}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    )
}
