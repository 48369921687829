import { CopyAll, OpenInBrowser, Send } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { appConfig } from '../../../../appConfig'
import { useExportInvoiceToQuickBooksMutation } from '../../../../graphql/gen/hooks'
import { useInvoice } from '../../../../hooks/useInvoice'
import { usdFormatter } from '../../../../lib/formatting'

const formInvoiceLink = (id: string) => {
    const intuitPage = appConfig.isProduction
        ? 'https://app.qbo.intuit.com'
        : 'https://app.sandbox.qbo.intuit.com'
    return `${intuitPage}/app/invoice?txnId=${id}`
}

export const InvoiceOverviewPage: React.FC = () => {
    const { invoice, refetch } = useInvoice()
    const total = useMemo(
        () => invoice.lineItems.reduce((total, cur) => total + cur.Amount, 0),
        [invoice.lineItems],
    )
    const [_exportToQuickBooks, { loading }] = useExportInvoiceToQuickBooksMutation({
        variables: {
            invoiceId: invoice.id,
        },
    })
    const exportToQuickBooks = useCallback(async () => {
        const resp = await _exportToQuickBooks()
        await refetch()
        window.open(formInvoiceLink(resp.data.exportInvoiceToQuickBooks))
    }, [_exportToQuickBooks])
    const customerHasNoQuickBooksReference = !invoice.customer.quickBooksCustomerId
    return (
        <Box>
            {customerHasNoQuickBooksReference && (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                    Customer "{invoice.customer.name}" is not assigned a QuickBooks customer
                    reference, please go update their information in the contacts tab.
                </Alert>
            )}
            <Box display="flex">
                <Box marginRight={1}>
                    {invoice.quickBooksInvoiceId ? (
                        <Button
                            variant="contained"
                            onClick={exportToQuickBooks}
                            disabled={loading || customerHasNoQuickBooksReference}
                            startIcon={<CopyAll />}
                        >
                            Clone QuickBooks Invoice
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={exportToQuickBooks}
                            disabled={loading || customerHasNoQuickBooksReference}
                            startIcon={<Send />}
                        >
                            Export To QuickBooks
                        </Button>
                    )}
                </Box>
                {invoice.quickBooksInvoiceId && (
                    <Button
                        variant="contained"
                        onClick={() => window.open(formInvoiceLink(invoice.quickBooksInvoiceId))}
                        disabled={loading || customerHasNoQuickBooksReference}
                        startIcon={<OpenInBrowser />}
                    >
                        Open Invoice
                    </Button>
                )}
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoice.lineItems.map((item, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell>
                                    {item.Description.split('\n').map(section => (
                                        <Typography key={section}>{section}</Typography>
                                    ))}
                                </TableCell>
                                <TableCell>{item.SalesItemLineDetail.Qty}</TableCell>
                                <TableCell>
                                    {usdFormatter.format(item.SalesItemLineDetail.UnitPrice)}
                                </TableCell>
                                <TableCell>{usdFormatter.format(item.Amount)}</TableCell>
                            </TableRow>
                        )
                    })}
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                            <Typography fontWeight="bold">Total</Typography>
                        </TableCell>
                        <TableCell>{usdFormatter.format(total)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
}
