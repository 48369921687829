import styled from '@emotion/styled'
import { Box, Card, CardContent, colors, Typography } from '@mui/material'
import { Toolbar } from '../../components/controls/Toolbar'
import { BackgroundVideo } from '../../components/views/BackgroundVideo'

const EmailLink = styled.a`
    color: ${colors.blue.A700};
    cursor: pointer;
    text-decoration: underline;
`

export const HomePage: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column">
            <Toolbar />
            <Box
                display="flex"
                position="relative"
                flexDirection="column"
                width={window.innerWidth}
                height="var(--app-height)"
            >
                <BackgroundVideo />
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    zIndex={1}
                >
                    <Card sx={{ maxWidth: 750, backgroundColor: 'black', color: 'white' }}>
                        <CardContent>
                            <Typography fontSize={36} marginBottom={3}>
                                FleetMage
                            </Typography>
                            <Typography>
                                Efficiency on the road, Simplicity in the office - fleet management
                                made simple.
                            </Typography>
                            <Typography>
                                Email{' '}
                                <EmailLink href="mailto:contact@fleetmage.com">
                                    contact@fleetmage.com
                                </EmailLink>{' '}
                                to get in contact.
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Box>
    )
}
