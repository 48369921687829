import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export const LoadingView: React.FC = () => {
    return (
        <Box display="flex" flex="1 1" justifyContent="center" alignItems="center" padding={5}>
            <CircularProgress />
        </Box>
    )
}
