import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { useMemo } from 'react'
import { usdFormatter } from '../../lib/formatting'
import { TicketObject } from '../../lib/types/gqlTypeAbbreviations'

type Props = {
    tickets: TicketObject[]
}

export const TicketCollectionRevenueAccordion: React.FC<Props> = ({ tickets }) => {
    const totalRevenue = useMemo(
        () => tickets.reduce((total, ticket) => total + ticket.revenue, 0),
        [tickets],
    )
    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>Revenue Total</AccordionSummary>
            <AccordionDetails>
                <Typography>{usdFormatter.format(totalRevenue)}</Typography>
            </AccordionDetails>
        </Accordion>
    )
}
