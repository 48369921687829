export const hashString = (str: string) => {
    let hash = 0
    const length = str.length
    let index = 0
    if (length > 0) {
        while (index < length) {
            hash = ((hash << 5) - hash + str.charCodeAt(index++)) | 0
        }
    }
    return hash.toString()
}
