import { useMemo } from 'react'
import { useFetch } from './useFetch'

export type QuickBooksCustomer_BillAddrObject = {
    Id: string
    Line1: string
    City: string
    CountrySubDivisionCode: string
    PostalCode: string
    Lat: string
    Long: string
}

export type QuickBooksCustomer_CurrencyRefObject = {
    Id: string
    Line1: string
    City: string
    CountrySubDivisionCode: string
    PostalCode: string
    Lat: string
    Long: string
}

export type QuickBooksCustomer_ShipAddrObject = {
    value: string
    name: string
}

export type QuickBooksCustomerObject = {
    Taxable: boolean
    BillAddr: QuickBooksCustomer_BillAddrObject
    ShipAddr: QuickBooksCustomer_ShipAddrObject
    Job: boolean
    BillWithParent: boolean
    Balance: number
    BalanceWithJobs: number
    CurrencyRef: QuickBooksCustomer_ShipAddrObject
    PreferredDeliveryMethod: string
    IsProject: boolean
    ClientEntityId: string
    domain: string
    sparse: boolean
    Id: string
    SyncToken: string
    MetaData: {
        CreateTime: string
        LastUpdatedTime: string
    }
    GivenName: string
    FamilyName: string
    FullyQualifiedName: string
    CompanyName: string
    DisplayName: string
    PrintOnCheckName: string
    Active: true
    V4IDPseudonym: string
    PrimaryPhone?: { FreeFormNumber: string }
    PrimaryEmailAddr?: { Address: string }
    Notes?: string
}

export const useQuickBooksCustomers = () => {
    const { data } = useFetch<QuickBooksCustomerObject[]>('/api/intuit/customers')
    const customers = useMemo(() => data ?? [], [data])
    return customers
}
