import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { LocationAddressObject } from '../../../../lib/geo'
import { ArrowBack, Save } from '@mui/icons-material'

type Props = {
    location: LocationAddressObject
    goBack?: () => void
    onSave?: (location: LocationAddressObject) => Promise<void>
}

const formSchema = yup.object().shape({
    address: yup
        .string()
        .min(4, 'Address must have at least 4 characters')
        .required('You must provide an address'),
    city: yup
        .string()
        .min(2, 'City must have at least 2 characters')
        .required('You must provide a city'),
    state: yup
        .string()
        .min(2, 'State must have at least 2 characters')
        .required('You must provide a state'),
    zip: yup
        .string()
        .length(5, 'Zip codes have exactly 5 digits')
        .matches(/^\d+$/, 'Zip code must be a valid 5 digit code')
        .required('You must provide a zip code'),
    label: yup
        .string()
        .min(4, 'Label must have at least 4 characters')
        .optional(),
})

export const NewLocationConfirmationView: React.FC<Props> = ({
    location,
    goBack,
    onSave,
}) => {
    const { handleSubmit, register, formState } =
        useForm<LocationAddressObject>({
            defaultValues: location,
            resolver: yupResolver(formSchema),
            mode: 'onChange',
        })
    const formErrors = formState.errors
    const onSubmit = handleSubmit(async data => {
        await onSave(data)
    })
    return (
        <Box>
            <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                <Typography marginY={2}>
                    Is this information correct? You can tweak the address.
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Address"
                    error={Boolean(formErrors.address)}
                    helperText={
                        formErrors.address ? (
                            <Typography>
                                {formErrors.address.message}
                            </Typography>
                        ) : null
                    }
                    {...register('address')}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="city"
                    disabled
                    error={Boolean(formErrors.city)}
                    helperText={
                        formErrors.city ? (
                            <Typography>{formErrors.city.message}</Typography>
                        ) : null
                    }
                    {...register('city')}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="state"
                    disabled
                    error={Boolean(formErrors.state)}
                    helperText={
                        formErrors.state ? (
                            <Typography>{formErrors.state.message}</Typography>
                        ) : null
                    }
                    {...register('state')}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="zip"
                    disabled
                    error={Boolean(formErrors.zip)}
                    helperText={
                        formErrors.zip ? (
                            <Typography>{formErrors.zip.message}</Typography>
                        ) : null
                    }
                    {...register('zip')}
                />
                <Divider sx={{ marginY: 2 }} />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Location name"
                    error={Boolean(formErrors.name)}
                    helperText={
                        formErrors.name ? (
                            <Typography>{formErrors.name.message}</Typography>
                        ) : (
                            'Quick reference name for this location, this can be anything to remind you which location this is. For example "Bob\'s ranch".'
                        )
                    }
                    {...register('name')}
                />
                <Box display="flex" marginTop={2}>
                    <Button
                        sx={{
                            marginRight: 5,
                        }}
                        fullWidth
                        variant="contained"
                        startIcon={<ArrowBack />}
                        color="warning"
                        onClick={goBack}
                    >
                        Go Back
                    </Button>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        startIcon={<Save />}
                        disabled={!formState.isValid || formState.isSubmitting}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
