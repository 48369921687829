import React from 'react'
import { useCompanyEmployeeQuery } from '../../graphql/gen/hooks'
import { CompanyEmployeeQuery } from '../../graphql/gen/operations'
import { ChildrenProp } from '../../lib/componentProps'
import { useParams } from 'react-router-dom'
import { NotFound404View } from '../NotFound404'
import { LoadingView } from './LoadingView'

type EmployeeObject = Omit<CompanyEmployeeQuery['companyEmployee'], '__typename'>

type EmployeeContextObject = {
    employee: EmployeeObject | null
    refetch: ReturnType<typeof useCompanyEmployeeQuery>['refetch']
}

export const EmployeeContext = React.createContext<EmployeeContextObject>(null)

export const EmployeeProvider: React.FC<ChildrenProp> = ({ children }) => {
    const { employeeId } = useParams<'employeeId'>()
    const { data, loading, refetch } = useCompanyEmployeeQuery({
        variables: {
            employeeId: parseInt(employeeId),
        },
    })
    if (!loading && !data) {
        return <NotFound404View />
    }
    // TODO: Handle company errors
    if (loading && !data) {
        return <LoadingView />
    }
    return (
        <EmployeeContext.Provider
            value={{
                employee: data.companyEmployee,
                refetch,
            }}
        >
            {children}
        </EmployeeContext.Provider>
    )
}
