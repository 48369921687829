export const formatMaterialAmount = (amount: number, decimals = 2) => {
    const multiplier = 10 ** decimals
    return Math.round(amount * multiplier) / multiplier
}

export const formatMaterialIdentifier = (material: { materialName: string; unitPlural: string }) =>
    `${material.unitPlural} of ${material.materialName}`

export const formatMaterialAmountPhrase = (
    materialName: string,
    unitSingular: string,
    unitPlural: string,
    materialAmount: number,
    decimals = 2,
) => {
    return `${formatMaterialAmount(materialAmount, decimals)} ${
        materialAmount === 1 ? unitSingular : unitPlural
    } of ${materialName}`
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

export const MOMENT_FORMATS = {
    HUMAN_MONTH_AND_DAY: 'MMM Do',
}

export const formatClientName = (
    client?: {
        name?: string
        companyName?: string
    },
    noneText = '',
) => {
    if (client?.companyName) {
        return client.companyName
    } else if (client?.name) {
        return client.name
    } else {
        return noneText
    }
}
