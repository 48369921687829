import { TextField } from '@mui/material'
import { useCallback, useState } from 'react'
import { useUpdateVehicleMutation } from '../../graphql/gen/hooks'
import { GetCompanyQuery } from '../../graphql/gen/operations'
import { useCompany } from '../../hooks/useCompany'

type Props = {
    vehicle: GetCompanyQuery['getCompany']['vehicles'][number]
}

export const VehicleDescriptionField: React.FC<Props> = ({ vehicle }) => {
    const [description, setDescription] = useState(vehicle.description)
    const { refetch } = useCompany()
    const [updateVehicle] = useUpdateVehicleMutation()
    const callback = useCallback(async () => {
        if (description !== vehicle.description) {
            await updateVehicle({
                variables: {
                    vehicleId: vehicle.vehicleId,
                    description,
                },
            })
            await refetch()
        }
    }, [description, vehicle, updateVehicle, refetch])
    return (
        <TextField
            fullWidth
            value={description}
            multiline
            placeholder="Vehicle description..."
            onKeyDownCapture={e => e.stopPropagation()}
            onKeyUpCapture={e => e.stopPropagation()}
            onChange={e => setDescription(e.target.value)}
            onBlur={callback}
        />
    )
}
