import { Box, Card, Tab, Tabs } from '@mui/material'
import { useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ChildrenProp } from '../../lib/componentProps'

export const DashboardEmployeesPageLayout: React.FC<ChildrenProp> = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const tab = useMemo(() => {
        const pathSections = location.pathname.split('/')
        return pathSections[pathSections.length - 1]
    }, [location])
    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Tabs
                    value={tab}
                    onChange={(_, tab) => {
                        navigate(`/dashboard/employees/${tab}`)
                    }}
                    variant="scrollable"
                >
                    <Tab label="Employees" value="list" />
                    <Tab label="Shifts" value="shifts" />
                </Tabs>
                <Box flexGrow={1} marginTop={3}>
                    <Outlet />
                    {children}
                </Box>
            </Card>
        </>
    )
}
