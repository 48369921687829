import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWhoAmI } from '../../../hooks/useWhoAmI'
import { useEmployeeInviteCode } from '../../../hooks/useEmployeeInviteCode'
import { useClaimEmployeeInviteMutation } from '../../../graphql/gen/hooks'
import { useAuth } from '../../../hooks/useAuth'
import { LoadingView } from '../../../components/layouts/LoadingView'

export const SignInSuccessPage: React.FC = () => {
    const navigate = useNavigate()
    const { firebaseUser, loading: loadingAuth, error: firebaseError } = useAuth()
    const { employeeInviteCode, setEmployeeInviteCode } = useEmployeeInviteCode()
    const [claimEmployeeInvite] = useClaimEmployeeInviteMutation()
    const { loading: loadingWhoAmI, whoAmI } = useWhoAmI()
    useEffect(() => {
        const f = async () => {
            if (firebaseError) {
                return navigate('/signout')
            }
            if (!whoAmI && !firebaseUser && !loadingAuth && !loadingWhoAmI) {
                // No auth or process to fetch auth
                return navigate('/signin')
            }
            if (firebaseUser) {
                // If whoAmI is available or if the firebase user is loaded but is currently fetching whoAmI
                if (!firebaseUser.displayName || !firebaseUser.phoneNumber) {
                    // Signed in but account is missing information
                    return navigate('/signin/add_info')
                }
            }
            if (whoAmI) {
                const employeeAccount = whoAmI.employeeAccount
                if (employeeAccount) {
                    // Signed in and already part of a company
                    if (employeeAccount.isOwner) {
                        return navigate('/dashboard')
                    } else {
                        return navigate('/console')
                    }
                } else if (employeeInviteCode) {
                    // Signed in and has a cached employee invite code to claim
                    await claimEmployeeInvite({
                        variables: {
                            inviteCode: employeeInviteCode,
                        },
                    })
                    setEmployeeInviteCode(null)
                    return navigate('/console')
                } else {
                    // Signed in but not part of a company
                    return navigate('/create')
                }
            }
        }
        f()
    }, [
        firebaseUser,
        whoAmI,
        navigate,
        loadingAuth,
        loadingWhoAmI,
        employeeInviteCode,
        claimEmployeeInvite,
    ])
    return <LoadingView />
}
