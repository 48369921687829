import { AppBar, Avatar, Box, Button, Toolbar as MuiToolbar, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ProductLogo from '../ProductLogo'
import { useAuth } from '../../hooks/useAuth'
import { ClickableBox } from './ClickableBox'
import { ChildrenProp } from '../../lib/componentProps'
import { Login } from '@mui/icons-material'

export const Toolbar: React.FC<ChildrenProp> = ({ children }) => {
    const navigate = useNavigate()
    const { firebaseUser } = useAuth()
    const textColor = 'white'
    return (
        <AppBar>
            <MuiToolbar
                sx={{
                    justifyContent: 'space-between',
                }}
            >
                <Box display="flex" alignItems="center">
                    <ClickableBox display="flex" alignItems="center" onClick={() => navigate('/')}>
                        <ProductLogo size={50} />
                        <Typography marginLeft={1} variant="h4" color={textColor}>
                            FleetMage
                        </Typography>
                    </ClickableBox>
                    <Box marginLeft={4}>{children}</Box>
                </Box>
                <Box display="flex" alignItems="center">
                    {firebaseUser ? (
                        <>
                            <Button
                                sx={{ marginRight: 1 }}
                                onClick={() => {
                                    navigate('/signout')
                                }}
                            >
                                <Typography color={textColor}>Sign Out</Typography>
                            </Button>
                            <Avatar src={firebaseUser.photoURL} alt={firebaseUser.displayName} />
                        </>
                    ) : (
                        <Button
                            startIcon={<Login sx={{ color: textColor }} />}
                            onClick={() => {
                                navigate('/signin')
                            }}
                        >
                            <Typography color={textColor}>Sign In</Typography>
                        </Button>
                    )}
                </Box>
            </MuiToolbar>
        </AppBar>
    )
}
