import { Button, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { GridContainer } from '../../../../components/datagrid/GridContainer'
import { ConfirmActionModal } from '../../../../components/ConfirmActionModal'
import { useInvoice } from '../../../../hooks/useInvoice'
import { usdFormatter } from '../../../../lib/formatting'
import { isMobile } from '../../../../lib/navigator'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { useBulkUpdateTicketsMutation } from '../../../../graphql/gen/hooks'
import { Remove } from '@mui/icons-material'
import { TicketModal } from '../../../../components/ticket/TicketModal'

export const InvoiceTicketsPage: React.FC = () => {
    const { isVisible, hide, show } = useVisibilityState()
    const { invoice, refetch } = useInvoice()
    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [bulkUpdateTickets] = useBulkUpdateTicketsMutation()
    const orderedTickets = useMemo(() => {
        return [...invoice.tickets].sort((a, b) => {
            return a.timestamp > b.timestamp ? -1 : 1
        })
    }, [invoice.tickets])
    const [viewingTicketId, setViewingTicketId] = useState<number | null>(null)
    const onConfirm = useCallback(async () => {
        await bulkUpdateTickets({
            variables: {
                input: {
                    ticketIds: selectedIds,
                    removeInvoice: true,
                },
            },
        })
        await refetch()
    }, [invoice.tickets, bulkUpdateTickets, selectedIds])
    return (
        <>
            <TicketModal
                onClose={() => setViewingTicketId(null)}
                open={Boolean(viewingTicketId)}
                ticketId={viewingTicketId}
                refetch={refetch}
            />
            <ConfirmActionModal
                isOpen={isVisible}
                close={hide}
                onConfirm={onConfirm}
                btnColor="error"
                btnIcon={<Remove />}
                btnText={`Remove (${selectedIds.length})`}
                title={`Remove ${selectedIds.length} tickets from invoice`}
                message="Are you sure you wish to delete this ticket? This action is unreversible."
            />
            <Button disabled={selectedIds.length === 0} onClick={show}>
                Remove From Invoice ({selectedIds.length})
            </Button>
            <GridContainer flexGrow={1} marginTop={2} minHeight={750}>
                <DataGrid
                    rows={orderedTickets}
                    autoHeight={isMobile}
                    checkboxSelection
                    disableSelectionOnClick
                    getRowId={row => row.id}
                    onSelectionModelChange={(ids: number[]) => setSelectedIds(ids)}
                    columns={[
                        {
                            field: 'dt',
                            headerName: 'Date',
                            minWidth: 150,
                            valueGetter: p => moment(p.row.timestamp).format('MMM D'),
                        },
                        {
                            field: 'identifier',
                            headerName: 'Identifier',
                            minWidth: 150,
                            renderCell: p => (
                                <Typography fontWeight="bold">{p.row.identifier}</Typography>
                            ),
                        },
                        {
                            field: 'amount',
                            headerName: 'Amount',
                            minWidth: 150,
                            valueGetter: p => p.row.materialAmount,
                        },
                        {
                            field: 'material',
                            headerName: 'Material',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: p =>
                                `${p.row.material.unitPlural} of ${p.row.material.materialName}`,
                        },
                        {
                            field: 'revenue',
                            headerName: 'Revenue',
                            minWidth: 150,
                            valueGetter: p => usdFormatter.format(p.row.revenue),
                        },
                        {
                            field: 'viewBtn',
                            headerName: '',
                            disableExport: true,
                            disableColumnMenu: true,
                            disableReorder: true,
                            sortable: false,
                            minWidth: 100,
                            renderCell: params => {
                                return (
                                    <Button
                                        variant="contained"
                                        onClick={() => setViewingTicketId(params.row.id)}
                                    >
                                        View
                                    </Button>
                                )
                            },
                        },
                    ]}
                />
            </GridContainer>
        </>
    )
}
