import { useCallback, useMemo, useState } from 'react'

type GetIdFunc<E, Y> = (item: E) => Y

export const useSet = <E, Y = string | number>(items: E[], getIdFunc: GetIdFunc<E, Y>) => {
    const [selectedItemIds, setSelectedItemIds] = useState<Set<Y>>(new Set())
    const has = useCallback(
        (item: E) => {
            const id = getIdFunc(item)
            return selectedItemIds.has(id)
        },
        [selectedItemIds, getIdFunc],
    )
    const add = useCallback(
        (item: E) => {
            const id = getIdFunc(item)
            setSelectedItemIds(new Set(selectedItemIds.add(id)))
        },
        [setSelectedItemIds, getIdFunc],
    )
    const addMany = useCallback((items: E[]) => items.forEach(item => add(item)), [add])
    const remove = useCallback(
        (item: E) => {
            const id = getIdFunc(item)
            selectedItemIds.delete(id)
            setSelectedItemIds(new Set(selectedItemIds))
        },
        [setSelectedItemIds, getIdFunc],
    )
    const toggle = useCallback(
        (item: E) => {
            if (has(item)) {
                remove(item)
            } else add(item)
        },
        [has, add, remove],
    )
    const clear = useCallback(() => setSelectedItemIds(new Set()), [setSelectedItemIds])
    const selectedItems = useMemo(() => items.filter(item => has(item)), [has, items])
    return {
        has,
        add,
        addMany,
        remove,
        toggle,
        clear,
        selectedItems,
    }
}
