import { Add, Edit } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useCallback, useMemo, useState } from 'react'
import { DatagridToolbarExportAndFilter } from '../../../../../components/datagrid/DatagridToolbarExportAndFilter'
import { useJob } from '../../../../../hooks/useJob'
import { useVisibilityState } from '../../../../../hooks/useVisibilityState'
import { formatMaterialAmount, usdFormatter } from '../../../../../lib/formatting'
import { isMobile } from '../../../../../lib/navigator'
import { NewJobMaterialModal } from './NewJobMaterialModal'
import { UpdateJobMaterialModal } from './UpdateJobMaterialModal'

export const JobMaterialsPage: React.FC = () => {
    const { job, refetch } = useJob()
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const [editingMaterialId, setEditingMaterialId] = useState<number | null>(null)
    const handleCloseEditingMaterial = useCallback(() => {
        setEditingMaterialId(null)
    }, [setEditingMaterialId])
    const editingMaterial = useMemo(() => {
        return job.materials.find(material => material.id === editingMaterialId)
    }, [job, editingMaterialId])
    return (
        <>
            <NewJobMaterialModal
                open={isVisible}
                onClose={closeModal}
                jobId={job.id}
                refetch={refetch}
            />
            {editingMaterial && (
                <UpdateJobMaterialModal
                    open={Boolean(editingMaterial)}
                    onClose={handleCloseEditingMaterial}
                    refetch={refetch}
                    material={editingMaterial}
                />
            )}
            <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" onClick={openModal} startIcon={<Add />}>
                    Add more materials
                </Button>
            </Box>
            <Box flexGrow={1} marginTop={3}>
                <DataGrid
                    autoHeight={isMobile}
                    rows={job.materials}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Name',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => params.row.materialName,
                        },
                        {
                            field: 'unit',
                            headerName: 'Unit',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => params.row.unitPlural,
                        },
                        {
                            field: 'materialPerTicket',
                            headerName: 'Material Per Ticket',
                            type: 'numeric',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params =>
                                `${params.row.expectedAmountPerTicket} ${
                                    params.row.expectedAmountPerTicket === 1
                                        ? params.row.unitSingular
                                        : params.row.unitPlural
                                }`,
                        },
                        {
                            field: 'materialTotal',
                            headerName: 'Expected Total',
                            type: 'numeric',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params =>
                                `${params.row.expectedAmountTotal} ${
                                    params.row.expectedAmountTotal === 1
                                        ? params.row.unitSingular
                                        : params.row.unitPlural
                                }`,
                        },
                        {
                            field: 'progress',
                            headerName: 'Progress',
                            type: 'numeric',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => {
                                const progress = params.row.progress
                                return `${formatMaterialAmount(progress.materialProgress)} ${
                                    progress.materialProgress === 1
                                        ? params.row.unitSingular
                                        : params.row.unitPlural
                                } (${(progress.progressPercent * 100).toFixed(0)}%)`
                            },
                        },
                        {
                            field: 'price',
                            headerName: 'Price per unit',
                            type: 'numeric',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params =>
                                usdFormatter.format(params.row.effectivePricePerUnit),
                        },
                        {
                            field: 'editBtn',
                            headerName: '',
                            disableExport: true,
                            minWidth: 100,
                            flex: 1,
                            renderCell: params => {
                                return (
                                    <Button
                                        startIcon={<Edit />}
                                        onClick={() => setEditingMaterialId(params.row.id)}
                                    >
                                        Edit
                                    </Button>
                                )
                            },
                        },
                    ]}
                    components={{
                        Toolbar: DatagridToolbarExportAndFilter,
                    }}
                />
            </Box>
        </>
    )
}
