import { useCallback, useState } from 'react'

export const useVisibilityState = (defaultState = false) => {
    const [isVisible, setIsVisible] = useState(defaultState)
    const show = useCallback(() => setIsVisible(true), [setIsVisible])
    const hide = useCallback(() => setIsVisible(false), [setIsVisible])
    const toggle = useCallback(() => setIsVisible(!open), [open, setIsVisible])
    return {
        isVisible,
        show,
        hide,
        toggle,
    }
}
