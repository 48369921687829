import { useMemo } from 'react'

export const useMapFromArray = <E, K = string | number>(
    items: E[],
    getId: (item: E) => K,
) => {
    return useMemo(() => {
        const map = new Map<K, E>()
        for (const item of items) {
            map.set(getId(item), item)
        }
        return map
    }, [items, getId])
}
