import { useCallback, useMemo } from 'react'
import { useAssignVehicleToEmployeeMutation } from '../../graphql/gen/hooks'
import { GetCompanyQuery } from '../../graphql/gen/operations'
import { useCompany } from '../../hooks/useCompany'
import { useSnackbar } from 'notistack'
import { SPECIAL_OPTIONS, UnmanagedSelect } from './Select/UnmanagedSelect'

type Props = {
    employee: GetCompanyQuery['getCompany']['employees'][number]
}

export const EmployeeAssignedVehicleDropdown: React.FC<Props> = ({ employee }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { company, refetch } = useCompany()
    const [assignVehicleToEmployee] = useAssignVehicleToEmployeeMutation()
    const callback = useCallback(
        async (vehicleId?: string | number) => {
            if (vehicleId !== employee.assignedVehicle?.vehicleId) {
                await assignVehicleToEmployee({
                    variables: {
                        employeeId: employee.id,
                        vehicleId: vehicleId === 'none' ? undefined : vehicleId.toString(),
                    },
                })
                await refetch()
                enqueueSnackbar(
                    `Successfully assigned vehicle ${vehicleId} to ${employee.user.displayName}.`,
                    {
                        variant: 'success',
                    },
                )
            }
        },
        [assignVehicleToEmployee, employee, refetch],
    )
    const freeVehicles = useMemo(
        () =>
            company.vehicles.filter(
                v => v.assignedEmployee?.id === employee?.id || !v.assignedEmployee,
            ),
        [company.vehicles],
    )
    return (
        <UnmanagedSelect
            value={employee.assignedVehicle?.vehicleId ?? 'none'}
            setValue={v => callback(v)}
            specialOptions={[SPECIAL_OPTIONS.NONE]}
            items={freeVehicles}
            getValueFn={v => v.vehicleId}
            getLabelFn={v => v.vehicleId}
        />
    )
}
