import { Box, Button, Card, Tab, Tabs, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Link } from '../Link'
import { useJob } from '../../hooks/useJob'
import { ChildrenProp } from '../../lib/componentProps'
import { isMobile } from '../../lib/navigator'
import { useVisibilityState } from '../../hooks/useVisibilityState'
import { EditJobModal } from '../../routes/pages/dashboard/jobs/EditJobModal'

export const JobPageLayout: React.FC<ChildrenProp> = ({ children }) => {
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const navigate = useNavigate()
    const location = useLocation()
    const tab = useMemo(() => {
        const pathSections = location.pathname.split('/')
        if (pathSections.length < 5) {
            return ''
        }
        return pathSections[4]
    }, [location])
    const { job, refetch } = useJob()
    return (
        <>
            <EditJobModal
                open={isVisible}
                onClose={closeModal}
                refetch={refetch}
                existingJob={job}
            />
            <Box>
                <Link to="/dashboard/jobs">{'<'} Back to job list</Link>
            </Box>
            <Card
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    flex: '1 1',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" marginRight={4}>
                            <strong>Job</strong> - {job.name}
                        </Typography>
                        <Button variant="contained" onClick={openModal}>
                            Edit
                        </Button>
                    </Box>
                    <Tabs
                        value={tab}
                        onChange={(_, tab) => {
                            navigate(`/dashboard/job/${job.id}/${tab}`)
                        }}
                        variant="scrollable"
                    >
                        <Tab label="Overview" value="overview" />
                        <Tab label="Materials" value="materials" />
                        <Tab label="Employees" value="employees" />
                        <Tab label="Tickets" value="tickets" />
                        <Tab label="Invoice" value="invoice" />
                    </Tabs>
                </Box>
                <Box
                    flexGrow={1}
                    marginTop={3}
                    display="flex"
                    flexDirection="column"
                    overflow="scroll"
                >
                    <Outlet />
                    {children}
                </Box>
            </Card>
        </>
    )
}
