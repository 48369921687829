import { Cancel, Save } from '@mui/icons-material'
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import {
    useCreateSupplierMaterialMutation,
    useUpdateSupplierMaterialMutation,
} from '../../../../graphql/gen/hooks'
import { ALL_MATERIALS, ALL_MATERIAL_UNIT_PRESETS } from '../../../../lib/constants/materials'
import { SimpleModal } from '../../../../components/SimpleModal'
import { SupplierMaterialsQuery } from '../../../../graphql/gen/operations'
import { UnmanagedSelect } from '../../../../components/inputs/Select/UnmanagedSelect'

type Props = {
    editMaterial?: SupplierMaterialsQuery['supplierMaterials'][0]
    open: boolean
    onClose: () => void
    refetch: () => void
}

export const EditMaterialModal: React.FC<Props> = ({ open, onClose, refetch, editMaterial }) => {
    const isEditing = Boolean(editMaterial)
    const { enqueueSnackbar } = useSnackbar()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [materialName, setMaterialName] = useState<string>()
    const [unitName, setUnitName] = useState<string>()
    const [expectedMaterialPerTicket, setExpectedMaterialPerTicket] = useState<number>()
    const [pricePerUnit, setPricePerUnit] = useState<number>()
    const [createSupplierMaterial] = useCreateSupplierMaterialMutation()
    const [updateSupplierMaterial] = useUpdateSupplierMaterialMutation()
    const resetForm = useCallback(() => {
        setMaterialName(editMaterial?.materialName ?? '')
        setUnitName(editMaterial?.unitPlural ?? '')
        setExpectedMaterialPerTicket(editMaterial?.expectedAmountPerTicket ?? NaN)
        setPricePerUnit(editMaterial?.pricePerUnit ?? NaN)
    }, [editMaterial, setMaterialName, setUnitName, setExpectedMaterialPerTicket, setPricePerUnit])
    useEffect(() => {
        resetForm()
    }, [resetForm, editMaterial])
    const selectedUnit = useMemo(
        () => ALL_MATERIAL_UNIT_PRESETS.find(p => p.plural === unitName || p.singular === unitName),
        [unitName],
    )
    const onSubmit = useCallback(async () => {
        setIsSubmitting(true)
        let snackbarMessage = 'Successfully created new material'
        if (isEditing) {
            await updateSupplierMaterial({
                variables: {
                    materialId: editMaterial.id,
                    materialInput: {
                        expectedAmountPerTicket: expectedMaterialPerTicket,
                        pricePerUnit,
                    },
                },
            })
            snackbarMessage = 'Successfully updated material'
        } else {
            await createSupplierMaterial({
                variables: {
                    materialInput: {
                        materialName: materialName,
                        unitSingular: selectedUnit.plural,
                        unitPlural: selectedUnit.plural,
                        expectedAmountPerTicket: expectedMaterialPerTicket,
                        pricePerUnit,
                    },
                },
            })
        }
        await refetch()
        onClose()
        resetForm()
        enqueueSnackbar(snackbarMessage, {
            variant: 'success',
        })
        setIsSubmitting(false)
    }, [
        enqueueSnackbar,
        onClose,
        refetch,
        createSupplierMaterial,
        materialName,
        selectedUnit,
        expectedMaterialPerTicket,
        pricePerUnit,
        updateSupplierMaterial,
        editMaterial,
    ])
    useEffect(() => {
        if (selectedUnit) {
            setExpectedMaterialPerTicket(selectedUnit.defaultExpectedAmount)
        }
    }, [selectedUnit, setExpectedMaterialPerTicket])
    const isValid = useMemo(() => {
        return (
            Boolean(unitName) &&
            Boolean(materialName) &&
            !isNaN(expectedMaterialPerTicket) &&
            !isNaN(pricePerUnit)
        )
    }, [unitName, materialName, expectedMaterialPerTicket, pricePerUnit])
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Add a new material</Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <Box display="flex" flexDirection="column">
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <UnmanagedSelect
                                        placeholder="Unit of measurement"
                                        items={ALL_MATERIAL_UNIT_PRESETS}
                                        value={unitName}
                                        setValue={v => setUnitName(v.toString())}
                                        getValueFn={m => m.plural}
                                        getLabelFn={m => m.plural}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth sx={{ marginRight: 2, marginBottom: 2 }}>
                                    <UnmanagedSelect
                                        placeholder="Material Type"
                                        items={ALL_MATERIALS}
                                        value={materialName}
                                        setValue={v => setMaterialName(v.toString())}
                                        getValueFn={m => m.toString()}
                                        getLabelFn={m => m.toString()}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    {selectedUnit && materialName && (
                        <>
                            <Divider sx={{ marginY: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12}>
                                    <Typography marginBottom={2} variant="h5">
                                        Expected work
                                    </Typography>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        label={`Expected ${selectedUnit.plural} of ${materialName} per ticket`}
                                        value={
                                            isNaN(expectedMaterialPerTicket)
                                                ? ''
                                                : expectedMaterialPerTicket
                                        }
                                        onChange={e =>
                                            setExpectedMaterialPerTicket(
                                                parseFloat(e.currentTarget.value),
                                            )
                                        }
                                        sx={{
                                            marginRight: 2,
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Typography marginBottom={2} variant="h5">
                                        Pricing
                                    </Typography>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="number"
                                        inputProps={{
                                            inputMode: 'decimal',
                                        }}
                                        label={`$ Price for ${selectedUnit?.plural} of ${materialName}`}
                                        value={isNaN(pricePerUnit) ? '' : pricePerUnit}
                                        onChange={e =>
                                            setPricePerUnit(parseFloat(e.currentTarget.value))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                startIcon={<Save />}
                                disabled={!isValid || isSubmitting}
                                onClick={onSubmit}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
