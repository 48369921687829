import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Link,
    Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { DatagridToolbarExportAndFilter } from '../../../../components/datagrid/DatagridToolbarExportAndFilter'
import { CompanyEmployeeQuery } from '../../../../graphql/gen/operations'
import { useEmployee } from '../../../../hooks/useEmployee'
import {
    formatMaterialAmountPhrase,
    MOMENT_FORMATS,
    usdFormatter,
} from '../../../../lib/formatting'
import { groupBy } from '../../../../lib/groupBy'
import { TicketModal } from '../../../../components/ticket/TicketModal'

const now = moment()

export const EmployeeTicketsPage: React.FC = () => {
    const [weekDt, setWeekDt] = useState(now.clone())
    const startDt = useMemo(() => weekDt.clone().startOf('week'), [weekDt])
    const endDt = useMemo(() => weekDt.clone().endOf('week'), [weekDt])
    const changeWeek = useCallback(
        (offsetWeeks: number) => {
            setWeekDt(weekDt.clone().add(offsetWeeks, 'weeks'))
        },
        [setWeekDt, weekDt],
    )
    const { employee, refetch } = useEmployee()
    const tickets = useMemo(
        () =>
            employee.tickets
                .filter(ticket => {
                    const timestamp = moment(ticket.timestamp)
                    return timestamp.isAfter(startDt) && timestamp.isBefore(endDt)
                })
                .sort((a, b) => {
                    if (a.timestamp > b.timestamp) {
                        return -1
                    } else if (a.timestamp < b.timestamp) {
                        return 1
                    }
                    return 0
                }),
        [employee, startDt, endDt],
    )
    const ticketsByDate = useMemo(
        () => groupBy(tickets, ticket => moment(ticket.timestamp).format('YYYY-MM-DD')),
        [tickets, startDt, endDt],
    )
    const orderedDtKeys = useMemo(
        () => Object.keys(ticketsByDate).sort().reverse(),
        [ticketsByDate],
    )
    const calculateEarnings = useCallback(
        (tickets: CompanyEmployeeQuery['companyEmployee']['tickets']) => {
            return tickets.map(ticket => ticket.employeeEarnings).reduce((sum, cur) => sum + cur, 0)
        },
        [],
    )
    const totalEarnings = useMemo(() => calculateEarnings(tickets), [tickets])
    const [viewingTicketId, setViewingTicketId] = useState<number | null>(null)
    const closeModal = useCallback(() => {
        setViewingTicketId(null)
    }, [setViewingTicketId])
    const viewingTicket = useMemo(() => {
        if (viewingTicketId != null) {
            return employee.tickets.find(ticket => ticket.id === viewingTicketId)
        }
    }, [viewingTicketId])
    return (
        <>
            {viewingTicket && (
                <TicketModal
                    onClose={closeModal}
                    open={typeof viewingTicketId === 'number'}
                    ticketId={viewingTicket.id}
                    refetch={refetch}
                />
            )}
            <Box display="flex" flexDirection="column" flexGrow={1} marginTop={3}>
                <Box display="flex" flexDirection="column" alignItems="center" marginBottom={3}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" marginRight={2}>
                            Week of {startDt.format(MOMENT_FORMATS.HUMAN_MONTH_AND_DAY)} -{' '}
                            {endDt.format(MOMENT_FORMATS.HUMAN_MONTH_AND_DAY)}
                        </Typography>
                        (<Link onClick={() => changeWeek(-1)}>Prev</Link>
                        {weekDt.dayOfYear() < now.dayOfYear() && (
                            <>
                                <Typography variant="h6" marginX={1}>
                                    |
                                </Typography>
                                <Link onClick={() => changeWeek(1)}>Next</Link>
                            </>
                        )}
                        )
                    </Box>
                    <Typography>Week Earnings - {usdFormatter.format(totalEarnings)}</Typography>
                </Box>
                <Accordion disableGutters elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6">All tickets in the week</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography>
                                    {tickets.length} ticket{tickets.length > 1 ? 's' : ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography>
                                    {usdFormatter.format(totalEarnings)} in earnings
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexGrow={1}>
                            <DataGrid
                                rows={tickets}
                                autoHeight
                                disableSelectionOnClick
                                components={{
                                    Toolbar: DatagridToolbarExportAndFilter,
                                }}
                                columns={[
                                    {
                                        field: 'identifier',
                                        headerName: 'Code',
                                        minWidth: 150,
                                        valueGetter: params => params.row.identifier,
                                    },
                                    {
                                        field: 'job',
                                        headerName: 'Job',
                                        minWidth: 200,
                                        valueGetter: params => params.row.job.name,
                                    },
                                    {
                                        field: 'timestamp',
                                        headerName: 'Timestamp',
                                        minWidth: 200,
                                        valueGetter: params =>
                                            moment(params.row.timestamp).format('YYYY-MM-DD-HH-mm'),
                                        renderCell: params => (
                                            <Typography>
                                                {moment(params.row.timestamp).format(
                                                    'MMM Do h:mm A',
                                                )}
                                            </Typography>
                                        ),
                                    },
                                    {
                                        field: 'material',
                                        headerName: 'Material',
                                        minWidth: 200,
                                        flex: 1,
                                        valueGetter: params => {
                                            const m = params.row.material
                                            return formatMaterialAmountPhrase(
                                                m.materialName,
                                                m.unitSingular,
                                                m.unitPlural,
                                                params.row.materialAmount,
                                            )
                                        },
                                    },
                                    {
                                        field: 'earnings',
                                        headerName: 'Employee Earnings',
                                        minWidth: 150,
                                        valueGetter: params => params.row.employeeEarnings,
                                        valueFormatter: params => usdFormatter.format(params.value),
                                    },
                                    {
                                        field: 'viewBtn',
                                        headerName: '',
                                        disableExport: true,
                                        minWidth: 150,
                                        renderCell: params => {
                                            return (
                                                <Button
                                                    variant="contained"
                                                    onClick={() =>
                                                        setViewingTicketId(params.row.id)
                                                    }
                                                >
                                                    View
                                                </Button>
                                            )
                                        },
                                    },
                                ]}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {orderedDtKeys.map(dtStr => {
                    const dt = moment(dtStr)
                    const tickets = [...ticketsByDate[dtStr]].sort((a, b) => {
                        if (a.job.name < b.job.name) {
                            return -1
                        } else if (a.job.name > b.job.name) {
                            return 1
                        }
                        return 0
                    })
                    const dayEarnings = calculateEarnings(tickets)
                    return (
                        <Accordion disableGutters elevation={0}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">
                                            {dt.format('dddd MMM Do')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography>
                                            {tickets.length} ticket{tickets.length > 1 ? 's' : ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography>
                                            {usdFormatter.format(dayEarnings)} in earnings
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box display="flex" flexGrow={1}>
                                    <DataGrid
                                        rows={tickets}
                                        autoHeight
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: DatagridToolbarExportAndFilter,
                                        }}
                                        columns={[
                                            {
                                                field: 'identifier',
                                                headerName: 'Code',
                                                minWidth: 150,
                                                valueGetter: params => params.row.identifier,
                                            },
                                            {
                                                field: 'job',
                                                headerName: 'Job',
                                                minWidth: 200,
                                                valueGetter: params => params.row.job.name,
                                            },
                                            {
                                                field: 'timestamp',
                                                headerName: 'Timestamp',
                                                minWidth: 200,
                                                valueGetter: params =>
                                                    moment(params.row.timestamp).format(
                                                        'YYYY-MM-DD-HH-mm',
                                                    ),
                                                renderCell: params => (
                                                    <Typography>
                                                        {moment(params.row.timestamp).format(
                                                            'MMM Do h:mm A',
                                                        )}
                                                    </Typography>
                                                ),
                                            },
                                            {
                                                field: 'material',
                                                headerName: 'Material',
                                                minWidth: 200,
                                                flex: 1,
                                                valueGetter: params => {
                                                    const m = params.row.material
                                                    return formatMaterialAmountPhrase(
                                                        m.materialName,
                                                        m.unitSingular,
                                                        m.unitPlural,
                                                        params.row.materialAmount,
                                                    )
                                                },
                                            },
                                            {
                                                field: 'earnings',
                                                headerName: 'Employee Earnings',
                                                minWidth: 150,
                                                valueGetter: params => params.row.employeeEarnings,
                                                valueFormatter: params =>
                                                    usdFormatter.format(params.value),
                                            },
                                            {
                                                field: 'viewBtn',
                                                headerName: '',
                                                disableExport: true,
                                                minWidth: 150,
                                                renderCell: params => {
                                                    return (
                                                        <Button
                                                            variant="contained"
                                                            onClick={() =>
                                                                setViewingTicketId(params.row.id)
                                                            }
                                                        >
                                                            View
                                                        </Button>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Box>
        </>
    )
}
