import { Box, Button, Modal, Typography } from '@mui/material'
import { SimpleModal } from '../../../../components/SimpleModal'
import { MapboxMap } from '../../../../components/map/MapboxMap'
import { MapMarker } from '../../../../components/map/MapMarker'
import { useCompanySavedLocationsQuery } from '../../../../graphql/gen/hooks'
import { useMemo } from 'react'
import { getCenterOfLocations } from '../../../../lib/geo'
import { ExitToApp } from '@mui/icons-material'

type Props = {
    open: boolean
    onClose: () => void
}

export const ViewLocationsModal: React.FC<Props> = ({ open, onClose }) => {
    const { data } = useCompanySavedLocationsQuery()
    const centerPoint = useMemo(() => {
        if (data) {
            return getCenterOfLocations(
                data.companySavedLocations.map(loc => ({
                    lat: loc.latitude,
                    lng: loc.longitude,
                })),
            )
        }
    }, [data])
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="lg">
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5">All saved locations</Typography>
                    <Button variant="contained" startIcon={<ExitToApp />} onClick={onClose}>
                        Exit
                    </Button>
                </Box>
                <Box marginY={2} display="flex" flex="1 1" minHeight={window.innerHeight * 0.75}>
                    {data && (
                        <MapboxMap defaultLocation={centerPoint} defaultZoom={9}>
                            {data?.companySavedLocations.map(loc => {
                                return (
                                    <MapMarker
                                        key={loc.id}
                                        loc={{
                                            lat: loc.latitude,
                                            lng: loc.longitude,
                                        }}
                                        text={loc.line1}
                                    />
                                )
                            })}
                        </MapboxMap>
                    )}
                </Box>
            </SimpleModal>
        </Modal>
    )
}
