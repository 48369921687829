import { firebaseAuth } from '../connections/firebase'

export const AUTH_OVERRIDE_UID_LOCAL_STORAGE_KEY = 'AUTH_OVERRIDE_UID'
export const AUTH_OVERRIDE_UID_HEADER_KEY = 'admin-uid-override'
export const ADMIN_SECRET_LOCAL_STORAGE_KEY = 'ADMIN_SECRET'
export const ADMIN_SECRET_HEADER_KEY = 'admin_secret'

export const getAuthOverrideConfig = () => {
    const uid = localStorage.getItem(AUTH_OVERRIDE_UID_LOCAL_STORAGE_KEY)
    const adminSecret = localStorage.getItem(ADMIN_SECRET_LOCAL_STORAGE_KEY)
    const headers = {
        [AUTH_OVERRIDE_UID_HEADER_KEY]: uid,
        [ADMIN_SECRET_HEADER_KEY]: adminSecret,
    }
    return {
        uid,
        adminSecret,
        headers,
    }
}

export const getAuthHeaders = async (prevHeaders: Record<string, string> = {}) => {
    let jwt = ''
    const { headers: authOverrideHeaders } = getAuthOverrideConfig()
    let user = firebaseAuth.currentUser
    if (!user) {
        // Give time for firebase to load the user
        await new Promise(resolve => setTimeout(resolve, 100))
        user = firebaseAuth.currentUser
    }
    if (user) {
        jwt = await user.getIdToken()
    }
    return {
        ...prevHeaders,
        ...authOverrideHeaders,
        authorization: jwt ?? '',
    }
}
