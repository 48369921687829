import React, { useState } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'

type MenuItemObject = {
    text: string
    callback: () => void | Promise<void>
    disabled?: boolean
}

type Props = {
    idName: string
    buttonText: string
    items?: MenuItemObject[]
}

export const MenuButton: React.FC<Props> = ({ idName, buttonText, items }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                id={`${idName}-button`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {buttonText}
            </Button>
            <Menu
                id={`${idName}-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `${idName}-button`,
                }}
            >
                {items.map(item => {
                    return (
                        <MenuItem
                            onClick={() => {
                                item.callback()
                                handleClose()
                            }}
                            disabled={item.disabled}
                        >
                            {item.text}
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
}
