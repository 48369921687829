import { Modal } from '@mui/material'
import { SimpleModal } from '../SimpleModal'
import { useCallback, useMemo } from 'react'
import { useGetTicketQuery } from '../../graphql/gen/hooks'
import { LoadingView } from '../layouts/LoadingView'
import { TicketDataView } from './TicketDataView'

type Props = {
    ticketId: number
    open: boolean
    onClose: () => void
    refetch: () => Promise<any>
}

export const TicketModal: React.FC<Props> = ({ open, onClose, ticketId, refetch }) => {
    const { data: ticketData } = useGetTicketQuery({
        variables: {
            ticketId,
        },
    })
    const refetchWrapper = useCallback(async () => {
        await refetch()
        onClose()
    }, [refetch, onClose])
    const contentDom = useMemo(() => {
        if (ticketData?.ticket) {
            return (
                <TicketDataView
                    ticket={ticketData.ticket}
                    refetch={refetchWrapper}
                    exitView={onClose}
                />
            )
        }
        return <LoadingView />
    }, [ticketData, refetchWrapper, onClose])
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="lg">
                {contentDom}
            </SimpleModal>
        </Modal>
    )
}
