import { Typography } from '@mui/material'
import React from 'react'
import { ErrorView } from './ErrorView'

type Props = {
    message?: string
}

export const NotFound404View: React.FC<Props> = ({ message }) => {
    return (
        <ErrorView title="Error 404 - Not Found">
            {message && <Typography variant="subtitle1">{message}</Typography>}
        </ErrorView>
    )
}
