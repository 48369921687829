import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { apollo } from './connections/graphql'
import { CssBaseline } from '@mui/material'
import { AppRouter } from './routes/AppRouter'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ThemeProvider } from './components/providers/ThemeProvider'

export const AppRoot: React.FC = () => {
    return (
        <React.StrictMode>
            <ApolloProvider client={apollo}>
                <ThemeProvider>
                    <CssBaseline>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <SnackbarProvider
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <BrowserRouter>
                                    <AppRouter />
                                </BrowserRouter>
                            </SnackbarProvider>
                        </LocalizationProvider>
                    </CssBaseline>
                </ThemeProvider>
            </ApolloProvider>
        </React.StrictMode>
    )
}
