import React from 'react'
import { useJobQuery } from '../../graphql/gen/hooks'
import { JobQuery } from '../../graphql/gen/operations'
import { ChildrenProp } from '../../lib/componentProps'
import { useParams } from 'react-router-dom'
import { NotFound404View } from '../NotFound404'
import { LoadingView } from './LoadingView'

type JobObject = Omit<JobQuery['job'], '__typename'>

type JobContextObject = {
    job: JobObject | null
    refetch: ReturnType<typeof useJobQuery>['refetch']
}

export const JobContext = React.createContext<JobContextObject>(null)

export const JobProvider: React.FC<ChildrenProp> = ({ children }) => {
    const { jobId } = useParams<'jobId'>()
    const { data, loading, refetch } = useJobQuery({
        variables: {
            id: parseInt(jobId),
        },
    })
    if (!loading && !data) {
        return <NotFound404View />
    }
    // TODO: Handle company errors
    if (loading && !data) {
        return <LoadingView />
    }
    return (
        <JobContext.Provider
            value={{
                job: data.job,
                refetch,
            }}
        >
            {children}
        </JobContext.Provider>
    )
}
