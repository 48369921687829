import { useCloudFile } from './useCloudFile'

type OcrItem = {
    text: string
    confidence: number
    boundingPoly: {
        vertices: OcrPolygon[]
    }
}

type OcrPolygon = {
    x: number
    y: number
}

type OcrObject = {
    description: string
    boundingPoly: {
        vertices: OcrPolygon[]
    }
}

type OcrGenericBlockDefinition = {
    confidence: number
    boundingBox: {
        vertices: { x: number; y: number }[]
    }
}

type OcrBlock = OcrGenericBlockDefinition & {
    blockType: string
}

type OcrTextBlockSymbol = OcrGenericBlockDefinition & {
    text: string
}

type OcrTextBlockWord = OcrGenericBlockDefinition & {
    symbols: OcrTextBlockSymbol[]
}

type OcrTextBlockParagraph = OcrGenericBlockDefinition & {
    words: OcrTextBlockWord[]
}

type OcrTextBlock = OcrBlock & {
    blockType: 'TEXT'
    paragraphs: OcrTextBlockParagraph[]
}

type OcrPage = {
    blocks: OcrBlock[]
}

type OcrResponse = {
    textAnnotations?: OcrObject[]
    fullTextAnnotation?: {
        pages: OcrPage[]
    }
}

export const useOcrData = (fileId?: number) => {
    const file = useCloudFile<OcrResponse>(fileId)
    const allWords: OcrItem[] = []
    if (file?.fullTextAnnotation && file.fullTextAnnotation.pages.length > 0) {
        const page = file.fullTextAnnotation.pages[0]
        page.blocks.forEach((block: OcrTextBlock) => {
            if (block.blockType === 'TEXT') {
                block.paragraphs.forEach(paragraph => {
                    paragraph.words.forEach(word => {
                        allWords.push({
                            text: word.symbols.map(symbol => symbol.text).join(''),
                            boundingPoly: word.boundingBox,
                            confidence: word.confidence,
                        })
                    })
                })
            }
        })
    }
    return allWords.filter((item, index) => index !== 0 && item.text.length !== 1)
}
