import { Business } from '@mui/icons-material'
import { Alert, Avatar, Box, Button, Container, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateCompanyMutation } from '../../graphql/gen/hooks'
import { useWhoAmI } from '../../hooks/useWhoAmI'
import { useEffect } from 'react'

const formSchema = yup.object().shape({
    name: yup
        .string()
        .min(4, "Your company's name must have at least 4 letters")
        .required('Company name is required'),
    inviteCode: yup.string().required('Invite code is required'),
})

export const CreateCompanyPage: React.FC = () => {
    const navigate = useNavigate()
    const { whoAmI, loading } = useWhoAmI()
    useEffect(() => {
        if (!whoAmI && !loading) {
            navigate('/signin')
        }
    }, [whoAmI, loading, navigate])
    const { handleSubmit, register, formState } = useForm<{
        name: string
        inviteCode: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const [createCompany, { loading: isCreatingCompany }] = useCreateCompanyMutation()
    const onSubmit = handleSubmit(async data => {
        await createCompany({
            variables: {
                name: data.name,
                inviteCode: data.inviteCode,
            },
        })
        navigate('/dashboard')
    })
    const formErrors = formState.errors
    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <Business />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Set up your company
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <Alert severity="warning">
                        This page is for registering your own company. If you were invited to join a
                        company please refer to the link sent to you instead.
                    </Alert>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Company Name"
                        error={Boolean(formErrors.name)}
                        disabled={isCreatingCompany}
                        helperText={
                            formErrors.name ? (
                                <Typography>{formErrors.name.message}</Typography>
                            ) : null
                        }
                        {...register('name')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Invite Code"
                        error={Boolean(formErrors.inviteCode)}
                        disabled={isCreatingCompany}
                        helperText={
                            formErrors.inviteCode ? (
                                <Typography>{formErrors.inviteCode.message}</Typography>
                            ) : null
                        }
                        {...register('inviteCode')}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!formState.isValid || isCreatingCompany}
                    >
                        Go to dashboard
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}
