const isProduction = process.env['NODE_ENV'] === 'production'

export const getLocalDevelopmentOrigin = () => {
    const origin = window.location.origin
    const lastIndex = origin.lastIndexOf(':') ?? origin.length
    return origin.substring(0, lastIndex)
}

export const appConfig = {
    isProduction,
    logRocketKey: 'fleetmage/fleetmage',
    firebase: {
        apiKey: 'AIzaSyCw-_ilhkhzMuOk5QSzfaCjD8Ll4-r_rgQ',
        authDomain: 'fleetmage.firebaseapp.com',
        emulatorUrl: !isProduction ? `${getLocalDevelopmentOrigin()}:9099` : null,
        projectId: 'fleetmage',
        appId: '1:410453239192:web:2c56932d80dd1731dd37ed',
        measurementId: 'G-P59VTRQLFK',
    },
    mapboxToken: isProduction
        ? 'pk.eyJ1IjoianVhbmphbHZhcmV6IiwiYSI6ImNsNjMwMWQyczAyOW4za21rcmt5aHJnOW8ifQ.8kQspBMoP71IyDb6JhyejQ'
        : 'pk.eyJ1IjoianVhbmphbHZhcmV6IiwiYSI6ImNsNHEybmNmdzA2Z24zanM2dTM2bm5teWsifQ.FDCPs-FzGeq5tsPW4qVMxA',
    sentryDsn: 'https://6e2b593e6de44fdd900aed10e8048391@o1339644.ingest.sentry.io/6611781',
}
