import React from 'react'

type ProductLogoProps = {
    size?: number
}

const ProductLogo: React.FC<ProductLogoProps> = ({ size = 244 }) => {
    return (
        <svg
            height="100%"
            strokeMiterlimit="10"
            style={{
                fillRule: 'nonzero',
                clipRule: 'evenodd',
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                width: size,
                height: size,
                borderRadius: 10,
            }}
            version="1.1"
            viewBox="0 0 1024 1024"
            width="100%"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <linearGradient
                    gradientTransform="matrix(1 0 0 1 0 -0.000227542)"
                    gradientUnits="userSpaceOnUse"
                    id="LinearGradient"
                    x1="479.977"
                    x2="505.502"
                    y1="315.63"
                    y2="1209.72"
                >
                    <stop offset="0" stopColor="#1998fd" />
                    <stop offset="0.992049" stopColor="#0011ff" />
                </linearGradient>
            </defs>
            <g id="Layer-2">
                <path
                    d="M1.59872e-14 1.72085e-15L1024 1.72085e-15L1024 1024L1.59872e-14 1024L1.59872e-14 1.72085e-15Z"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="1"
                    stroke="none"
                />
                <g opacity="1">
                    <path
                        d="M541.031 210.969C504.626 210.328 465.753 214.595 425.562 225.906C353.107 246.298 300.041 463.174 274.156 595.25C158.487 616.551 82.2188 652.903 82.2188 694.219C82.2187 759.866 274.631 813.094 512 813.094C749.369 813.094 941.781 759.866 941.781 694.219C941.781 649.694 853.147 610.957 722.125 590.594C681.34 495.136 624.384 361.648 611 328.156C607.429 319.221 817.521 331.601 771.688 290C737.312 258.799 650.248 212.889 541.031 210.969Z"
                        fill="url(#LinearGradient)"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="none"
                    />
                    <path
                        d="M436.058 303.499C436.058 303.499 513.226 321.976 530.47 323.662C646.435 335 635.866 331.328 635.531 331.328C635.531 331.328 554.925 328.358 530.058 323.152C505.19 317.945 435.549 303.499 436.058 303.499Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="1"
                        stroke="#000000"
                        strokeLinecap="butt"
                        strokeLinejoin="round"
                        strokeWidth="10"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ProductLogo
