import { LockOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Container, TextField, Typography } from '@mui/material'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../../connections/firebase'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Link } from '../../../components/Link'

const formSchema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Your email is required'),
    confirmEmail: yup
        .string()
        .oneOf([yup.ref('email'), null], 'This does not match your email address above')
        .required('You must confirm your email address'),
    password: yup
        .string()
        .min(8, 'Your password should have at least 8 characters')
        .required('You must set a password'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Your passwords do not match')
        .required('You must confirm your password'),
})

export const SignUpWithEmailAndPasswordPage: React.FC = () => {
    const navigate = useNavigate()
    const { handleSubmit, register, control, formState } = useForm<{
        email: string
        confirmEmail: string
        password: string
        confirmPassword: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const formPassword = useWatch({
        control,
        name: 'password',
    })
    const onSubmit = handleSubmit(async data => {
        await createUserWithEmailAndPassword(firebaseAuth, data.email, data.password)
        navigate('/signin/success')
    })
    const formErrors = formState.errors
    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up with email & password
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        type="email"
                        autoComplete="email"
                        error={Boolean(formState.errors.email)}
                        helperText={
                            formErrors.email ? (
                                <Typography>{formErrors.email.message}</Typography>
                            ) : null
                        }
                        {...register('email')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm Email Address"
                        type="email"
                        autoComplete="email"
                        error={Boolean(formState.errors.confirmEmail)}
                        helperText={
                            formErrors.confirmEmail ? (
                                <Typography>{formErrors.confirmEmail.message}</Typography>
                            ) : null
                        }
                        {...register('confirmEmail')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        autoComplete="password"
                        error={Boolean(formState.errors.password)}
                        helperText={
                            formErrors.password ? (
                                <Typography>{formErrors.password.message}</Typography>
                            ) : null
                        }
                        {...register('password')}
                    />
                    <PasswordStrengthBar password={formPassword} />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        error={Boolean(formState.errors.confirmPassword)}
                        helperText={
                            formErrors.confirmPassword ? (
                                <Typography>{formErrors.confirmPassword.message}</Typography>
                            ) : null
                        }
                        {...register('confirmPassword')}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!formState.isValid}
                    >
                        Sign Up
                    </Button>
                    <Link to="/signin">Already have an account? Sign In</Link>
                </Box>
            </Box>
        </Container>
    )
}
