import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { ListItemButton, ListItemIcon, ListItemText, styled, useTheme } from '@mui/material'
import { isMobile } from '../../lib/navigator'
import { NavigationUiItem } from '../../lib/navigationUiHelpers'
import { NavbarControls } from '../controls/NavbarControls'
import { useWhoAmI } from '../../hooks/useWhoAmI'

const drawerWidth = 280

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: 0,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}))

type Props = {
    layoutTitle: string
    navItems: NavigationUiItem[]
    secondaryNavItems?: NavigationUiItem[]
}

export const AppLayout: React.FC<Props> = ({ layoutTitle, navItems, secondaryNavItems }) => {
    const [open, setOpen] = useState(!isMobile)
    const toggleDrawer = () => {
        setOpen(!open)
    }
    const navigate = useNavigate()
    const location = useLocation()
    const { whoAmI } = useWhoAmI()
    const theme = useTheme()
    return (
        <Box display="flex">
            <AppBar position="absolute" open={open}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {layoutTitle}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={
                    isMobile
                        ? {
                              position: 'fixed',
                              top: 0,
                              left: 0,
                              zIndex: 10,
                              height: 'var(--app-height)',
                          }
                        : undefined
                }
                open={open}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    flex="1 1"
                >
                    <Box display="flex" flexDirection="column">
                        <List component="nav">
                            {navItems.map(item => {
                                return (
                                    <ListItemButton
                                        key={`primaryNav-${item.title}-${item.rootPath}`}
                                        selected={
                                            item.exactMatch
                                                ? location.pathname === item.pathPrefix
                                                : location.pathname.startsWith(item.pathPrefix)
                                        }
                                        onClick={() => {
                                            if (isMobile) {
                                                toggleDrawer()
                                            }
                                            navigate(item.rootPath)
                                        }}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                        {secondaryNavItems && (
                            <>
                                <Divider />
                                <List component="nav">
                                    {secondaryNavItems.map(item => {
                                        return (
                                            <ListItemButton
                                                key={`secondaryNav-${item.title}-${item.rootPath}`}
                                                selected={
                                                    item.exactMatch
                                                        ? location.pathname === item.pathPrefix
                                                        : location.pathname.startsWith(
                                                              item.pathPrefix,
                                                          )
                                                }
                                                onClick={() => {
                                                    if (isMobile) {
                                                        toggleDrawer()
                                                    }
                                                    navigate(item.rootPath)
                                                }}
                                            >
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                        )
                                    })}
                                </List>
                            </>
                        )}
                    </Box>
                    <NavbarControls
                        showConsoleButton={location.pathname.startsWith('/dashboard')}
                        showDashboardButton={
                            location.pathname.startsWith('/console') &&
                            whoAmI.employeeAccount.isOwner
                        }
                    />
                </Box>
            </Drawer>
            <Box
                display="flex"
                flexDirection="column"
                component="main"
                sx={{
                    backgroundColor:
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[1000],
                    flexGrow: 1,
                    width: window.innerWidth,
                    overflowX: 'scroll',
                    minHeight: '100vh',
                    height: isMobile ? 'initial' : '100vh',
                    overflowY: isMobile ? 'initial' : 'scroll',
                }}
            >
                <Toolbar />
                <Box
                    sx={{
                        margin: isMobile ? 2 : 4,
                        flex: '1 1',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </Box>
    )
}
