import { useContext } from 'react'
import { EmployeeContext } from '../components/layouts/EmployeeProvider'

export const useEmployee = () => {
    const { employee, refetch } = useContext(EmployeeContext)
    if (!employee) {
        throw new Error('You are calling useEmployee outside of <EmployeeProvider />.')
    }
    return { employee, refetch }
}
