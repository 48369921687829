import React from 'react'
import { Box, Button, Card, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import { useMyJobAssignmentsQuery } from '../../../../graphql/gen/hooks'
import { isMobile } from '../../../../lib/navigator'
import { formatClientName } from '../../../../lib/formatting'

export const ConsoleJobsPage: React.FC = () => {
    const navigate = useNavigate()
    const { data } = useMyJobAssignmentsQuery()
    const jobs = useMemo(() => {
        if (!data) {
            return []
        }
        return data.myJobAssignments.map(ea => ea.job)
    }, [data])
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                <Typography variant="h5">My Jobs</Typography>
            </Box>
            <Box flexGrow={1} marginTop={3}>
                <DataGrid
                    rows={jobs}
                    columnVisibilityModel={{
                        origin: !isMobile,
                        destination: !isMobile,
                    }}
                    autoHeight={isMobile}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Name',
                            minWidth: 150,
                            flex: 2,
                        },
                        {
                            field: 'client',
                            headerName: 'Client',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => formatClientName(params.row.client),
                        },
                        {
                            field: 'origin',
                            headerName: 'Origin',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => params.row.origin.name,
                        },
                        {
                            field: 'destination',
                            headerName: 'Destination',
                            minWidth: 150,
                            flex: 1,
                            valueGetter: params => params.row.destination.name,
                        },
                        {
                            field: 'viewButton',
                            headerName: '',
                            disableExport: true,
                            renderCell: params => (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        navigate(`/console/job/${params.row.id}`)
                                    }}
                                >
                                    Open
                                </Button>
                            ),
                        },
                    ]}
                />
            </Box>
        </Card>
    )
}
