import { Add, Map } from '@mui/icons-material'
import { Box, Button, Card, TextField, Typography } from '@mui/material'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { NewLocationModal } from './NewLocationModal'
import React from 'react'
import { useCompanySavedLocationsQuery } from '../../../../graphql/gen/hooks'
import { DataGrid } from '@mui/x-data-grid'
import { DatagridToolbarExportAndFilter } from '../../../../components/datagrid/DatagridToolbarExportAndFilter'
import { ViewLocationsModal } from './ViewLocationsModal'
import { isMobile } from '../../../../lib/navigator'
import { useSimpleTextSearch } from '../../../../hooks/useSimpleTextSearch'

export const DashboardLocationsPage: React.FC = () => {
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const {
        isVisible: isMapModalVisible,
        show: openMapModal,
        hide: closeMapModal,
    } = useVisibilityState()
    const { data } = useCompanySavedLocationsQuery()
    const {
        results: savedLocationHits,
        searchText,
        setSearchText,
    } = useSimpleTextSearch(
        data?.companySavedLocations ?? [],
        loc => loc.id,
        loc => [loc.city, loc.line1, loc.name, loc.state, loc.zip],
    )
    return (
        <>
            <NewLocationModal open={isVisible} onClose={closeModal} />
            <ViewLocationsModal open={isMapModalVisible} onClose={closeMapModal} />
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Typography variant="h5">Saved Locations</Typography>
                    <Box
                        marginTop={isMobile ? 2 : 0}
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                    >
                        <Button
                            fullWidth={isMobile}
                            variant="contained"
                            startIcon={<Map />}
                            onClick={openMapModal}
                            sx={{
                                marginRight: isMobile ? 0 : 2,
                                marginBottom: isMobile ? 2 : 0,
                            }}
                        >
                            View Map
                        </Button>
                        <Button
                            fullWidth={isMobile}
                            variant="contained"
                            startIcon={<Add />}
                            onClick={openModal}
                        >
                            New Location
                        </Button>
                    </Box>
                </Box>
                <Box marginTop={2}>
                    <TextField
                        fullWidth
                        placeholder="Search Locations..."
                        value={searchText}
                        onChange={e => setSearchText(e.currentTarget.value)}
                    />
                </Box>
                <Box flexGrow={1} marginTop={3}>
                    <DataGrid
                        autoHeight={isMobile}
                        rows={savedLocationHits}
                        columns={[
                            {
                                field: 'name',
                                headerName: 'Label',
                                minWidth: 150,
                                flex: 1,
                            },
                            {
                                field: 'line1',
                                headerName: 'Address',
                                minWidth: 150,
                                flex: 1,
                            },
                            {
                                field: 'cityState',
                                headerName: 'City, state & zip',
                                minWidth: 150,
                                flex: 1,
                                valueGetter: params =>
                                    `${params.row.city}, ${params.row.state} ${params.row.zip}`,
                            },
                        ]}
                        components={{
                            Toolbar: DatagridToolbarExportAndFilter,
                        }}
                    />
                </Box>
            </Card>
        </>
    )
}
