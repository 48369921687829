import styled from '@emotion/styled'
import React from 'react'

type Props = {
    uri: string
}

const IFrame = styled.iframe`
    background-color: white;
`

export const LegalPolicyFrameView: React.FC<Props> = ({ uri }) => {
    return <IFrame width={window.innerWidth} height={window.innerHeight} src={uri} />
}
