import React, { useMemo } from 'react'
import { MapPath } from './MapPath'
import { useDirectionsData } from '../../hooks/useDirectionsData'

type Props = {
    originId: number
    destinationId: number
    color?: string
}

export const MapDirections: React.FC<Props> = ({ originId, destinationId, color }) => {
    const name = useMemo(
        () => `directions-path-${JSON.stringify([originId, destinationId])}`,
        [originId, destinationId],
    )
    const data = useDirectionsData(originId, destinationId)
    if (!data) {
        return null
    }
    return <MapPath name={name} coordinates={data.geometry.coordinates} color={color} />
}
