import { Box } from '@mui/material'
import React from 'react'

export const EmployeeOverviewPage: React.FC = () => {
    return (
        <Box>
            <Box>Work in progress...</Box>
        </Box>
    )
}
