import { Add } from '@mui/icons-material'
import { Box, Button, Card, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import { DatagridToolbarExportAndFilter } from '../../../../components/datagrid/DatagridToolbarExportAndFilter'
import { useCompanySavedContactsQuery } from '../../../../graphql/gen/hooks'
import { CompanySavedContactsQuery } from '../../../../graphql/gen/operations'
import { useSimpleTextSearch } from '../../../../hooks/useSimpleTextSearch'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { isMobile } from '../../../../lib/navigator'
import { EditSavedContactModal } from './EditSavedContactModal'

export const DashboardContactsPage: React.FC = () => {
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const { data } = useCompanySavedContactsQuery()
    const [editingContact, setEditingContact] = useState<
        CompanySavedContactsQuery['companySavedContacts'][0] | null
    >(null)
    const { results, searchText, setSearchText } = useSimpleTextSearch(
        data?.companySavedContacts ?? [],
        c => c.id,
        c => [c.name, c.notes, c.phoneNumber, c.emailAddress],
    )
    return (
        <>
            <EditSavedContactModal
                existingContact={editingContact}
                open={isVisible || Boolean(editingContact)}
                onClose={() => {
                    closeModal()
                    setEditingContact(null)
                }}
            />
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Typography variant="h5" marginBottom={isMobile ? 2 : 0}>
                        Saved Contacts
                    </Typography>
                    <Button variant="contained" onClick={openModal} startIcon={<Add />}>
                        New Contact
                    </Button>
                </Box>
                <Box marginTop={2} display="flex">
                    <TextField
                        fullWidth
                        placeholder="Search for contact..."
                        value={searchText}
                        onChange={e => setSearchText(e.currentTarget.value)}
                    />
                </Box>
                <Box flexGrow={1} marginTop={3}>
                    <DataGrid
                        autoHeight={isMobile}
                        rows={results}
                        columns={[
                            {
                                field: 'name',
                                headerName: 'Name',
                                minWidth: 150,
                            },
                            {
                                field: 'phoneNumber',
                                headerName: 'Phone',
                                minWidth: 150,
                            },
                            {
                                field: 'emailAddress',
                                headerName: 'Email',
                                minWidth: 150,
                            },
                            {
                                field: 'notes',
                                headerName: 'Notes',
                                minWidth: 150,
                                flex: 1,
                                renderCell: params => {
                                    return <Typography>{params.row.notes}</Typography>
                                },
                            },
                            {
                                field: 'btn',
                                headerName: '',
                                minWidth: 150,
                                renderCell: params => {
                                    return (
                                        <Button
                                            variant="contained"
                                            onClick={() => setEditingContact(params.row)}
                                        >
                                            Edit
                                        </Button>
                                    )
                                },
                            },
                        ]}
                        components={{
                            Toolbar: DatagridToolbarExportAndFilter,
                        }}
                    />
                </Box>
            </Card>
        </>
    )
}
