import {
    Box,
    Card,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useListOfJobsQuery } from '../../../graphql/gen/hooks'
import { useCompany } from '../../../hooks/useCompany'
import { useSelectController } from '../../../hooks/useSelectController'
import {
    SPECIAL_OPTIONS_NUM,
    UnmanagedSelect,
} from '../../../components/inputs/Select/UnmanagedSelect'
import { useFilteredTickets } from '../../../hooks/useFilteredTickets'
import { TicketCollectionMaterialsAccordion } from '../../../components/views/TicketCollectionMaterialsAccordion'
import { TicketCollectionPayrollAccordion } from '../../../components/views/TicketCollectionPayrollAccordion'
import { TicketCollectionRevenueAccordion } from '../../../components/views/TicketCollectionRevenueAccordion'
import { TicketCollectionTable } from '../../../components/views/TicketCollectionTable'
import { useContacts } from '../../../hooks/useContacts'

const DT_FMT = 'YYYY-MM-DD HH:mm:ss Z'

const DT_TODAY = moment()
const DT_WEEK_START = DT_TODAY.clone().startOf('week')
const DT_WEEK_END = DT_TODAY.clone().endOf('week')

export const DashboardTicketsPage: React.FC = () => {
    const [hideInvoicedTickets, setHideInvoicedTickets] = useState(false)
    const [showMaterials, setShowMaterials] = useState(false)
    const [showRevenue, setShowRevenue] = useState(false)
    const [showPayroll, setShowPayroll] = useState(false)
    const { company, isSupplierCompany, isTruckingCompany } = useCompany()
    const { contacts } = useContacts()
    const { data: listOfJobsData } = useListOfJobsQuery()
    const jobs = useMemo(() => {
        if (!listOfJobsData) {
            return []
        }
        return listOfJobsData.companyJobs
    }, [listOfJobsData])
    const [startDt, setStartDt] = useState<string>(DT_WEEK_START.format('YYYY-MM-DD'))
    const [endDt, setEndDt] = useState<string>(DT_WEEK_END.format('YYYY-MM-DD'))
    const { selectedId: selectedJobId, setId: setSelectedJobId } = useSelectController(
        jobs,
        job => job.id,
    )
    const { selectedId: selectedIssuerId, setId: setSelectedIssuerId } = useSelectController(
        contacts,
        customer => customer.id,
    )
    const { selectedId: selectedCustomerId, setId: setSelectedCustomerId } = useSelectController(
        contacts,
        customer => customer.id,
    )
    const { selectedId: selectedTransporterId, setId: setSelectedTransporterId } =
        useSelectController(contacts, customer => customer.id)
    const { selectedId: selectedEmployeeId, setId: setSelectedEmployeeId } = useSelectController(
        company.employees,
        employee => employee.id,
    )
    const {
        tickets: allTickets,
        refetch,
        loading,
    } = useFilteredTickets({
        jobId: selectedJobId,
        employeeId: selectedEmployeeId,
        issuerId: selectedIssuerId,
        transporterId: selectedTransporterId,
        customerId: selectedCustomerId,
        startDt: startDt ? moment(startDt).startOf('day').format(DT_FMT) : null,
        endDt: endDt ? moment(endDt).endOf('day').format(DT_FMT) : null,
        hideInvoicedTickets,
    })
    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography variant="h5">Tickets</Typography>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <FormControlLabel
                            label="Show Materials"
                            control={
                                <Checkbox
                                    checked={showMaterials}
                                    onClick={() => setShowMaterials(!showMaterials)}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Show Revenue"
                            control={
                                <Checkbox
                                    checked={showRevenue}
                                    onClick={() => setShowRevenue(!showRevenue)}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Hide Invoiced Tickets"
                            control={
                                <Checkbox
                                    checked={hideInvoicedTickets}
                                    onClick={() => setHideInvoicedTickets(!hideInvoicedTickets)}
                                />
                            }
                        />
                        {isTruckingCompany && (
                            <FormControlLabel
                                label="Payroll"
                                control={
                                    <Checkbox
                                        checked={showPayroll}
                                        onClick={() => setShowPayroll(!showPayroll)}
                                    />
                                }
                            />
                        )}
                    </Box>
                </Box>
                <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Start Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startDt}
                            onChange={e => setStartDt(e.currentTarget.value)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="End Date"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={endDt}
                            onChange={e => setEndDt(e.currentTarget.value)}
                        />
                    </Grid>
                </Grid>
                {isTruckingCompany && (
                    <Grid container spacing={2} marginTop={1}>
                        <Grid item xs={12} lg={6}>
                            <UnmanagedSelect
                                placeholder="Filter Job"
                                items={jobs}
                                value={selectedJobId}
                                setValue={v => setSelectedJobId(v)}
                                specialOptions={[SPECIAL_OPTIONS_NUM.NONE]}
                                getValueFn={v => v.id}
                                getLabelFn={v => v.name}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <UnmanagedSelect
                                placeholder="Filter Employee"
                                items={company.employees}
                                value={selectedEmployeeId}
                                setValue={v => setSelectedEmployeeId(v)}
                                specialOptions={[SPECIAL_OPTIONS_NUM.NONE]}
                                getValueFn={v => v.id}
                                getLabelFn={v => v.user.displayName}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={2} marginTop={1}>
                    <Grid item xs={12} lg={6}>
                        {isSupplierCompany ? (
                            <UnmanagedSelect
                                placeholder="Filter transporter"
                                items={contacts}
                                value={selectedTransporterId}
                                setValue={v => setSelectedTransporterId(v)}
                                specialOptions={[SPECIAL_OPTIONS_NUM.NONE]}
                                getValueFn={v => v.id}
                                getLabelFn={v => v.name}
                            />
                        ) : (
                            <UnmanagedSelect
                                placeholder="Filter issuer"
                                items={contacts}
                                value={selectedIssuerId}
                                setValue={v => setSelectedIssuerId(v)}
                                specialOptions={[SPECIAL_OPTIONS_NUM.NONE]}
                                getValueFn={v => v.id}
                                getLabelFn={v => v.name}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <UnmanagedSelect
                            placeholder="Filter customer"
                            items={contacts}
                            value={selectedCustomerId}
                            setValue={v => setSelectedCustomerId(v)}
                            specialOptions={[SPECIAL_OPTIONS_NUM.NONE]}
                            getValueFn={v => v.id}
                            getLabelFn={v => v.name}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 1, opacity: 0 }} />
                {showRevenue && <TicketCollectionRevenueAccordion tickets={allTickets} />}
                {showPayroll && <TicketCollectionPayrollAccordion tickets={allTickets} />}
                {showMaterials && <TicketCollectionMaterialsAccordion tickets={allTickets} />}
                <TicketCollectionTable
                    tickets={allTickets}
                    refetch={refetch}
                    loading={loading}
                    showMaterials={showMaterials}
                    showPayroll={showPayroll}
                    showRevenue={showRevenue}
                />
            </Card>
        </>
    )
}
