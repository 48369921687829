import { LockOutlined } from '@mui/icons-material'
import { Alert, Avatar, Box, Button, Container, TextField, Typography } from '@mui/material'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../../connections/firebase'
import { useQueryString } from '../../../hooks/queryStringHooks'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from '../../../components/Link'

const formSchema = yup.object().shape({
    phone: yup
        .string()
        .length(10, 'Your phone number must have exactly 10 digits')
        .required('You must provide your phone number'),
})

export const SignInWithPhonePage: React.FC = () => {
    const navigate = useNavigate()
    const phone = useQueryString('phone')
    const { handleSubmit, register, formState, watch } = useForm<{
        phone?: string
    }>({
        defaultValues: {
            phone,
        },
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const phoneValue = watch('phone')
    const is787 = formState.isValid && phoneValue.startsWith('787')
    const onSubmit = handleSubmit(async data => {
        const result = await signInWithPhoneNumber(
            firebaseAuth,
            `+1${data.phone}`,
            new RecaptchaVerifier(
                'recaptcha-container',
                {
                    size: 'invisible',
                    callback: () => {},
                },
                firebaseAuth,
            ),
        )
        // TODO: Handle send verification code errors
        navigate(`/signin/phone/confirm?verificationId=${result.verificationId}`)
    })
    return (
        <Container component="main" maxWidth="sm">
            <div id="recaptcha-container" />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in with phone number
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Phone Number"
                        autoComplete="phone-number"
                        error={Boolean(formState.errors.phone)}
                        helperText={
                            formState.errors.phone ? (
                                <Typography>{formState.errors.phone.message}</Typography>
                            ) : null
                        }
                        {...register('phone')}
                    />
                    <Alert severity="info">
                        In the next screen you will need to confirm the verification code sent to
                        you via text.
                    </Alert>
                    {is787 && (
                        <Alert severity="error" sx={{ marginTop: 2 }}>
                            Phone numbers from Puerto Rico are not supported for sign in, please use
                            another sign in method unless you have already signed up and have been
                            provided an overriden confirmation code.
                        </Alert>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!formState.isValid}
                    >
                        Send Verification Code
                    </Button>
                    <Link to="/signin">Sign in with another method</Link>
                </Box>
            </Box>
        </Container>
    )
}
