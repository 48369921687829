import { Alert, AlertTitle, Box, Button, Grid, Typography } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MapboxMap } from '../../../../components/map/MapboxMap'
import { MapMarker } from '../../../../components/map/MapMarker'
import { JobStatus } from '../../../../graphql/gen/schemas'
import { useJob } from '../../../../hooks/useJob'
import { convertLocationToLatLng, getCenterOfLocations } from '../../../../lib/geo'
import { formatMaterialAmountPhrase } from '../../../../lib/formatting'
import {
    useDispatchJobMutation,
    useFinishJobMutation,
    useReOpenJobMutation,
} from '../../../../graphql/gen/hooks'
import { CopyText } from '../../../../components/CopyText'
import { Done, RestartAlt, Send, Share } from '@mui/icons-material'
import { calcLogic } from '../../../../lib/calcLogic'
import { MapDirections } from '../../../../components/map/MapDirections'
import { useDirectionsData } from '../../../../hooks/useDirectionsData'
import { isMobile } from '../../../../lib/navigator'
import { MapVehicleMarkers } from '../../../../components/map/MapVehicleMarkers'
import { ProgressBar } from '../../../../components/dataviz/ProgressBar'

export const JobOverviewPage: React.FC = () => {
    const { job, refetch } = useJob()
    const navigate = useNavigate()
    const centerPoint = useMemo(() => {
        return getCenterOfLocations(
            [job.origin, job.destination].map(loc => ({
                lat: loc.latitude,
                lng: loc.longitude,
            })),
        )
    }, [job])
    const [_dispatchJob, { loading: isDispatching }] = useDispatchJobMutation({
        variables: {
            jobId: job.id,
        },
    })
    const dispatchJob = useCallback(async () => {
        await _dispatchJob()
        await refetch()
    }, [refetch, _dispatchJob])
    const [_finishJob, { loading: isFinishing }] = useFinishJobMutation({
        variables: {
            jobId: job.id,
        },
    })
    const finishJob = useCallback(async () => {
        await _finishJob()
        await refetch()
    }, [refetch, _dispatchJob])
    const [_reOpenJob, { loading: isReOpening }] = useReOpenJobMutation({
        variables: {
            jobId: job.id,
        },
    })
    const reopenJob = useCallback(async () => {
        await _reOpenJob()
        await refetch()
    }, [refetch, _dispatchJob])
    const monitorUrl = useMemo(() => `${window.location.origin}/monitor/${job.monitorId}`, [job])
    const directionsData = useDirectionsData(job.origin.id, job.destination.id)
    const distanceStr = useMemo(() => {
        if (!directionsData) {
            return `unknown miles`
        }
        return `${(directionsData.distance / 1609.344).toFixed(2)} miles`
    }, [directionsData])
    const durationStr = useMemo(() => {
        if (!directionsData) {
            return `unknown duration`
        }
        const minutesTotal = Math.floor(directionsData.duration / 60)
        const hours = Math.floor(minutesTotal / 60)
        const extraMinutes = Math.floor(minutesTotal % 60)
        const minutesStr = `${extraMinutes} min`
        if (hours > 0) {
            return `${hours} hr ${minutesStr}`
        }
        return minutesStr
    }, [directionsData])
    return (
        <>
            {job.jobAssignments.length === 0 && (
                <Alert
                    severity="warning"
                    action={
                        <Button
                            color="inherit"
                            onClick={() => {
                                navigate(`/dashboard/job/${job.id}/employees`)
                            }}
                        >
                            Solve
                        </Button>
                    }
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    <AlertTitle>No employees assigned</AlertTitle>
                    Please visit the employees tab of this job in order to assign workers.
                </Alert>
            )}
            {job.status === JobStatus.Pending && (
                <Alert
                    severity="warning"
                    action={
                        <Button color="inherit" onClick={dispatchJob}>
                            Dispatch Job
                        </Button>
                    }
                    sx={{
                        marginBottom: 2,
                        alignItems: 'center',
                    }}
                >
                    <AlertTitle>Job Pending</AlertTitle>
                    This job has not yet been dispatched.
                </Alert>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography fontWeight="bold">Share public job monitor view</Typography>
                    <Box display="flex">
                        <CopyText text={monitorUrl} button />
                        {window.navigator.share && (
                            <Button
                                startIcon={<Share />}
                                onClick={async () => {
                                    await window.navigator.share({
                                        title: 'Job Monitor URL',
                                        text: `Monitor For Job - ${job.name}`,
                                        url: monitorUrl,
                                    })
                                }}
                            >
                                Share
                            </Button>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    {calcLogic(() => {
                        if (job.status === JobStatus.Pending) {
                            return (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    startIcon={<Send />}
                                    onClick={dispatchJob}
                                    disabled={isDispatching}
                                >
                                    Dispatch Job
                                </Button>
                            )
                        } else if (job.status === JobStatus.InProgress) {
                            return (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    startIcon={<Done />}
                                    onClick={finishJob}
                                    disabled={isFinishing}
                                >
                                    Mark job as done
                                </Button>
                            )
                        } else if (job.status === JobStatus.Done) {
                            return (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={reopenJob}
                                    startIcon={<RestartAlt />}
                                    disabled={isReOpening}
                                >
                                    Re-open job
                                </Button>
                            )
                        }
                    })}
                </Grid>
                <Grid
                    marginTop={isMobile ? 3 : 0}
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    justifyContent="center"
                >
                    <Typography fontWeight="bold" textAlign="center">
                        Route
                    </Typography>
                    <Typography marginLeft={4} textAlign="center">
                        {distanceStr}
                    </Typography>
                    <Typography marginLeft={4} textAlign="center">
                        {durationStr}
                    </Typography>
                </Grid>
            </Grid>
            <Box display="flex" flexDirection="column" alignItems="center" marginTop={3}>
                <Typography variant="h6" textAlign="center">
                    {job.progress.ticketCount} tickets, {job.progress.ticketTarget} expected
                </Typography>
                {job.materials.map(material => {
                    return (
                        <Typography key={material.id} marginBottom={1}>
                            {`${formatMaterialAmountPhrase(
                                material.materialName,
                                material.unitSingular,
                                material.unitPlural,
                                material.progress.materialProgress,
                            )} out of ${material.progress.materialTarget} (${(
                                material.progress.progressPercent * 100
                            ).toFixed(0)}%)`}
                        </Typography>
                    )
                })}
                <ProgressBar progress={Math.round(job.progress.progressPercent * 100)} />
            </Box>
            <Box marginTop={2} display="flex" flex="1 1" minHeight={300}>
                <MapboxMap defaultLocation={centerPoint}>
                    <MapMarker loc={convertLocationToLatLng(job.origin)} color="green" />
                    <MapMarker loc={convertLocationToLatLng(job.destination)} color="red" />
                    <MapVehicleMarkers
                        vehicleIdentifiers={job.jobAssignments.map(
                            assignment => assignment.vehicle.vehicleId,
                        )}
                    />
                    <MapDirections originId={job.origin.id} destinationId={job.destination.id} />
                </MapboxMap>
            </Box>
        </>
    )
}
