import { ExitToApp, QuestionAnswer, SwitchAccount } from '@mui/icons-material'
import { List, ListItemButton, ListItemIcon, ListItemText, ListItem } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DarkModeControls } from './DarkModeControls'

type Props = {
    showConsoleButton?: boolean
    showDashboardButton?: boolean
}

export const NavbarControls: React.FC<Props> = ({ showConsoleButton, showDashboardButton }) => {
    const navigate = useNavigate()
    return (
        <List sx={{ paddingBottom: 3 }}>
            <ListItem>
                <DarkModeControls />
            </ListItem>
            <ListItemButton onClick={() => alert('Text/call 407-883-8983')}>
                <ListItemIcon>
                    <QuestionAnswer />
                </ListItemIcon>
                <ListItemText primary="Get Help" />
            </ListItemButton>
            {showConsoleButton && (
                <ListItemButton onClick={() => navigate('/console')}>
                    <ListItemIcon>
                        <SwitchAccount />
                    </ListItemIcon>
                    <ListItemText primary="Visit Console" />
                </ListItemButton>
            )}
            {showDashboardButton && (
                <ListItemButton onClick={() => navigate('/dashboard')}>
                    <ListItemIcon>
                        <SwitchAccount />
                    </ListItemIcon>
                    <ListItemText primary="Switch To Dashboard" />
                </ListItemButton>
            )}
            <ListItemButton onClick={() => navigate('/signout')}>
                <ListItemIcon>
                    <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
            </ListItemButton>
        </List>
    )
}
