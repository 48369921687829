import { Box, Card, CircularProgress, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useFetch } from 'usehooks-ts'
import { DarkModeControls } from '../../../components/controls/DarkModeControls'
import { ProgressBar } from '../../../components/dataviz/ProgressBar'
import { LoadingView } from '../../../components/layouts/LoadingView'
import { MapboxMap } from '../../../components/map/MapboxMap'
import { MapDirections } from '../../../components/map/MapDirections'
import { MapMarker } from '../../../components/map/MapMarker'
import { formatMaterialAmountPhrase } from '../../../lib/formatting'
import { DEFAULT_MAP_LOCATION, getCenterOfLocations } from '../../../lib/geo'
import { MonitorDataObject } from '../../../lib/monitorLogic'

export const MonitorPage: React.FC = () => {
    const { monitorId } = useParams<'monitorId'>()
    const { data } = useFetch<MonitorDataObject>(`/api/monitor/${monitorId}`)
    const centerPoint = useMemo(() => {
        if (!data) {
            return DEFAULT_MAP_LOCATION
        }
        return getCenterOfLocations([data.origin, data.destination])
    }, [data])
    if (!data) {
        return <LoadingView />
    }
    if (!data) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
        )
    }
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
                margin: 3,
            }}
        >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                    <Typography variant="h5">Job - {data.jobName}</Typography>
                    <Typography variant="subtitle1">Company - {data.companyName}</Typography>
                </Box>
                <DarkModeControls />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" paddingX={4}>
                <Typography variant="h6">
                    Job Progress - {data.progress.ticketCount} tickets, {data.progress.ticketTarget}{' '}
                    expected
                </Typography>
                {data.materials.map(material => {
                    return (
                        <Typography key={material.id} marginBottom={1}>
                            {`${formatMaterialAmountPhrase(
                                material.materialName,
                                material.unitSingular,
                                material.unitPlural,
                                material.progress.materialProgress,
                            )} out of ${material.progress.materialTarget} (${(
                                material.progress.progressPercent * 100
                            ).toFixed(0)}%)`}
                        </Typography>
                    )
                })}
                <ProgressBar progress={data.progress.progressPercent * 100} />
            </Box>
            <Box marginTop={2} display="flex" flex="1 1" minHeight={500}>
                <MapboxMap defaultLocation={centerPoint}>
                    <MapMarker loc={data.origin} color="green" />
                    <MapMarker loc={data.destination} color="red" />
                    <MapDirections originId={data.origin.id} destinationId={data.destination.id} />
                </MapboxMap>
            </Box>
        </Card>
    )
}
