import {
    Box,
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { CopyText } from '../../../../components/CopyText'
import { Link } from '../../../../components/Link'
import { MapboxMap } from '../../../../components/map/MapboxMap'
import { MapDirections } from '../../../../components/map/MapDirections'
import { MapMarker } from '../../../../components/map/MapMarker'
import { useJob } from '../../../../hooks/useJob'
import { usdFormatter } from '../../../../lib/formatting'
import {
    generateDirectionsLink,
    getCenterOfLocations,
    convertLocationToLatLng,
} from '../../../../lib/geo'
import { isMobile } from '../../../../lib/navigator'

export const ConsoleJobPage: React.FC = () => {
    const navigate = useNavigate()
    const { job } = useJob()
    const originAddress = useMemo(
        () => `${job.origin.line1}, ${job.origin.city} ${job.origin.state}
    ${job.origin.zip}`,
        [job],
    )
    const destinationAddress = useMemo(
        () => `${job.destination.line1}, ${job.destination.city} ${job.destination.state}
    ${job.destination.zip}`,
        [job],
    )
    const googleMapsLink = useMemo(() => {
        return generateDirectionsLink(
            `${job.origin.latitude},${job.origin.longitude}`,
            `${job.destination.latitude},${job.destination.longitude}`,
        )
    }, [job])
    const centerPoint = useMemo(() => {
        return getCenterOfLocations(
            [job.origin, job.destination].map(loc => ({
                lat: loc.latitude,
                lng: loc.longitude,
            })),
        )
    }, [job])
    return (
        <>
            <Box>
                <Link to="/console/jobs">{'<'} Back to job list</Link>
            </Box>
            <Card
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    flex: '1 1',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Typography variant="h5">
                        <strong>Job</strong> - {job.name}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate(`/console/upload?jobId=${job.id}`)
                        }}
                    >
                        Upload ticket
                    </Button>
                </Box>
                <Box
                    flexGrow={1}
                    marginTop={3}
                    display="flex"
                    flexDirection="column"
                    overflow="scroll"
                >
                    <Box>
                        <Typography variant="h6">Instructions</Typography>
                        <Typography>{job.instructions}</Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Typography variant="h6">Origin location</Typography>
                        <Typography>{job.origin.name}</Typography>
                        <CopyText text={originAddress} />
                    </Box>
                    <Box marginTop={2}>
                        <Typography variant="h6">Destination location</Typography>
                        <Typography>{job.destination.name}</Typography>
                        <CopyText text={destinationAddress} />
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            onClick={() => {
                                window.open(googleMapsLink)
                            }}
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            Open directions in Google Maps
                        </Button>
                    </Box>
                    <Table sx={{ marginY: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Material</TableCell>
                                <TableCell>Earnings</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {job.materials.map(material => (
                                <TableRow key={material.id}>
                                    <TableCell>
                                        {material.unitSingular} of {material.materialName}
                                    </TableCell>
                                    <TableCell>
                                        {usdFormatter.format(material.pricePerUnitEmployee)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box marginTop={2} display="flex" flex="1 1" minHeight={300}>
                        <MapboxMap defaultLocation={centerPoint}>
                            <MapMarker loc={convertLocationToLatLng(job.origin)} color="green" />
                            <MapMarker loc={convertLocationToLatLng(job.destination)} color="red" />
                            <MapDirections
                                originId={job.origin.id}
                                destinationId={job.destination.id}
                            />
                        </MapboxMap>
                    </Box>
                </Box>
            </Card>
        </>
    )
}
