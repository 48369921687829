import { LockOutlined } from '@mui/icons-material'
import { Alert, Avatar, Box, Button, Container, TextField, Typography } from '@mui/material'
import { RecaptchaVerifier, updateProfile, reauthenticateWithPhoneNumber } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { firebaseAuth } from '../../../connections/firebase'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo } from 'react'

const displayNameSchema = yup.string().required('Your name is required')

const phoneNumberSchema = yup
    .string()
    .length(10, 'Your phone number must have exactly 10 digits')
    .required('You must provide your phone number')

const getFormSchema = (hasName: boolean, hasPhone: boolean) => {
    if (!hasName && !hasPhone) {
        return yup.object().shape({
            displayName: displayNameSchema,
            phone: phoneNumberSchema,
        })
    } else if (!hasName) {
        return yup.object().shape({
            displayName: displayNameSchema,
        })
    } else if (!hasPhone) {
        return yup.object().shape({
            phone: phoneNumberSchema,
        })
    } else {
        throw new Error(
            'Impossible scenario - User has name and phone, however ended up on more info page.',
        )
    }
}

export const SignInAddInfoPage: React.FC = () => {
    const navigate = useNavigate()
    const hasName = useMemo(
        () => Boolean(firebaseAuth.currentUser?.displayName),
        [firebaseAuth.currentUser?.displayName],
    )
    const hasPhone = useMemo(
        () => Boolean(firebaseAuth.currentUser?.phoneNumber),
        [firebaseAuth.currentUser?.phoneNumber],
    )
    const formSchema = useMemo(() => getFormSchema(hasName, hasPhone), [hasName, hasPhone])
    const { handleSubmit, register, watch, formState } = useForm<{
        displayName: string
        phone: string
    }>({
        defaultValues: {
            displayName: firebaseAuth.currentUser?.displayName ?? '',
            phone: firebaseAuth.currentUser?.phoneNumber ?? '',
        },
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const phoneValue = watch('phone')
    const is787 = phoneValue.startsWith('787')
    const onSubmit = handleSubmit(async data => {
        if (!firebaseAuth.currentUser?.displayName) {
            await updateProfile(firebaseAuth.currentUser, {
                displayName: data?.displayName,
            })
        }
        if (!firebaseAuth.currentUser?.phoneNumber) {
            const result = await reauthenticateWithPhoneNumber(
                firebaseAuth.currentUser,
                `+1${data.phone}`,
                // '+1 787-528-2445',
                new RecaptchaVerifier(
                    'recaptcha-container',
                    {
                        size: 'invisible',
                    },
                    firebaseAuth,
                ),
            )
            navigate(`/signin/phone/confirm?verificationId=${result.verificationId}`)
            // TODO: Handle send verification code errors
        } else {
            navigate('/signin/success')
        }
    })
    const formErrors = formState.errors
    return (
        <Container component="main" maxWidth="xs">
            <div id="recaptcha-container" />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Complete your profile
                </Typography>
                <Typography component="p" marginTop={2}>
                    Looks like your profile is missing some information.
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    {!firebaseAuth.currentUser.displayName && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Your Name"
                            autoComplete="name"
                            error={Boolean(formErrors.displayName)}
                            helperText={
                                formErrors.displayName ? (
                                    <Typography>{formErrors.displayName.message}</Typography>
                                ) : null
                            }
                            {...register('displayName')}
                        />
                    )}
                    {!firebaseAuth.currentUser.phoneNumber && (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Phone Number"
                                autoComplete="phone-number"
                                error={Boolean(formState.errors.phone)}
                                helperText={
                                    formState.errors.phone ? (
                                        <Typography>{formState.errors.phone.message}</Typography>
                                    ) : null
                                }
                                {...register('phone')}
                            />
                            <Alert severity="info">
                                In the next screen you will need to confirm the verification code
                                sent to you via text.
                            </Alert>
                            {is787 && (
                                <Alert severity="error" sx={{ marginTop: 2 }}>
                                    Phone numbers from Puerto Rico are not supported by the
                                    authentication system and require a manual override code. Please
                                    send a text to 407-883-8983 to get an override code.
                                </Alert>
                            )}
                        </>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!formState.isValid}
                    >
                        Complete Profile
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}
