import { Add, ArrowRight } from '@mui/icons-material'
import { Box, Button, Card, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useListInvoicesQuery } from '../../../../graphql/gen/hooks'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { formatClientName } from '../../../../lib/formatting'
import { isMobile } from '../../../../lib/navigator'
import { CreateInvoiceModal } from './CreateInvoiceModal'

export const DashboardInvoicesPage: React.FC = () => {
    const navigate = useNavigate()
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const { data, refetch } = useListInvoicesQuery()
    const invoices = useMemo(() => {
        const arr = data?.listInvoices ?? []
        if (arr.length < 2) {
            return arr
        }
        return [...arr].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    }, [data?.listInvoices])
    return (
        <>
            <CreateInvoiceModal
                open={isVisible}
                onClose={closeModal}
                refetch={refetch}
                existingCount={invoices.length}
            />
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Typography variant="h5" marginBottom={isMobile ? 2 : 0}>
                        Invoices
                    </Typography>
                    <Button variant="contained" onClick={openModal} startIcon={<Add />}>
                        Create Invoice
                    </Button>
                </Box>
                <Box flexGrow={1} marginTop={3}>
                    <DataGrid
                        autoHeight={isMobile}
                        rows={invoices}
                        columns={[
                            {
                                field: 'createdAt',
                                headerName: 'Created',
                                minWidth: 150,
                                valueGetter: params =>
                                    moment(params.row.createdAt).format('MMM Do YYYY'),
                            },
                            {
                                field: 'name',
                                headerName: 'Name',
                                minWidth: 150,
                                flex: 1,
                                valueGetter: params => params.row.name,
                            },
                            {
                                field: 'customer',
                                headerName: 'Customer',
                                minWidth: 150,
                                flex: 1,
                                valueGetter: params => formatClientName(params.row.customer),
                            },
                            {
                                field: 'viewBtn',
                                headerName: '',
                                disableExport: true,
                                sortable: false,
                                renderCell: params => {
                                    return (
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                navigate(`/dashboard/invoice/${params.row.id}`)
                                            }
                                            startIcon={<ArrowRight />}
                                        >
                                            View
                                        </Button>
                                    )
                                },
                            },
                        ]}
                    />
                </Box>
            </Card>
        </>
    )
}
