import { yupResolver } from '@hookform/resolvers/yup'
import { Upload } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    Card,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { PhotoPicker } from '../../../components/inputs/PhotoPicker'
import {
    useCreateTicketMutation,
    useDoesTicketIdentifierExistQuery,
    useMyJobAssignmentsQuery,
} from '../../../graphql/gen/hooks'
import { CloudFile } from '../../../graphql/gen/schemas'
import { useQueryNumber } from '../../../hooks/queryStringHooks'
import { useAuth } from '../../../hooks/useAuth'
import { useSnackbar } from 'notistack'
import { getAuthOverrideConfig } from '../../../lib/authLogic'
import moment from 'moment'
import { useCompany } from '../../../hooks/useCompany'
import { useNavigate } from 'react-router-dom'

const formSchema = yup.object().shape({
    jobId: yup.number().integer().required('You must select a job'),
    identifier: yup
        .string()
        .max(20, 'The ticket identifier must be less than 20 characters.')
        .required('You must provide the ticket id'),
    materialAmount: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, 'Material amount must be a valid number')
        .required('You must specify the amount of material in this ticket'),
    photo: yup.mixed().required('You must upload a photo of the ticket'),
    date: yup.string().required('You must provide the date of ticket collection'),
    time: yup.string().required('You must provide the time of ticket collection'),
    notes: yup.string().max(1000, '1000 character limit reached'),
})

export const UploadTicketPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { firebaseUser } = useAuth()
    const { isSupplierCompany } = useCompany()
    const navigate = useNavigate()
    useEffect(() => {
        if (isSupplierCompany) {
            navigate('/console/supplier_upload', {
                replace: true,
            })
        }
    }, [isSupplierCompany])
    const { data } = useMyJobAssignmentsQuery()
    const preSelectedJobId = useQueryNumber('jobId')
    const jobs = useMemo(() => {
        if (!data) {
            return []
        }
        return data.myJobAssignments.map(ea => ea.job)
    }, [data])
    const { handleSubmit, register, formState, watch, setValue, reset } = useForm<{
        jobId: number
        materialId: number
        identifier: string
        materialAmount: number
        photo: File
        date: string
        time: string
        notes: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
        defaultValues: {
            jobId: preSelectedJobId,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
            notes: '',
        },
    })
    const formErrors = formState.errors
    const jobId = watch('jobId')
    const materialId = watch('materialId')
    const photo = watch('photo')
    const identifier = watch('identifier')
    const selectedJob = useMemo(() => {
        return jobs.find(job => job.id === jobId)
    }, [jobs, jobId])
    const selectedMaterial = useMemo(() => {
        if (selectedJob) {
            return selectedJob.materials.find(mat => mat.id === materialId)
        }
    }, [selectedJob, materialId])
    const { data: ticketExistsData } = useDoesTicketIdentifierExistQuery({
        variables: {
            jobId: selectedJob?.id,
            identifier: identifier,
        },
        skip: !selectedJob || !identifier,
    })
    const isIdentifierUnique = useMemo(
        () => !ticketExistsData?.doesTicketIdentifierExist,
        [ticketExistsData],
    )
    useEffect(() => {
        if (selectedJob && !materialId) {
            if (selectedJob.materials.length > 0) {
                setValue('materialId', selectedJob.materials[0].id, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                })
            }
        }
    }, [selectedJob, materialId, setValue])
    useEffect(() => {
        if (selectedMaterial) {
            setValue('materialAmount', selectedMaterial.expectedAmountPerTicket, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            })
        }
    }, [selectedMaterial, setValue])
    const [createTicket] = useCreateTicketMutation()
    const handleFormSubmit = handleSubmit(async data => {
        const timestamp = moment(`${data.date}T${data.time}`).format()
        const formData = new FormData()
        formData.set('file', data.photo)
        const { headers: authOverrideHeaders } = getAuthOverrideConfig()
        const resp = await fetch('/api/file/upload', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': await firebaseUser.getIdToken(),
                ...authOverrideHeaders,
            },
        })
        if (resp.status !== 200) {
            throw new Error(`Ran into error uploading photo`)
        }
        const respData: CloudFile = await resp.json()
        await createTicket({
            variables: {
                input: {
                    jobMaterialId: data.materialId,
                    fileId: respData.id,
                    identifier: data.identifier,
                    materialAmount: parseFloat(data.materialAmount.toString()),
                    timestamp,
                    notes: data.notes,
                },
            },
        })
        reset({
            jobId: selectedJob.id,
            photo: undefined,
            identifier: '',
            materialId: data.materialId,
            materialAmount: selectedMaterial.expectedAmountPerTicket,
        })
        enqueueSnackbar('Successfully uploaded ticket.', {
            variant: 'success',
        })
    })
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            {jobs.length > 0 ? (
                <Box component="form" onSubmit={handleFormSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography variant="h5" marginBottom={2}>
                        Upload Ticket
                    </Typography>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel id="select-job">Job</InputLabel>
                        <Select
                            labelId="select-job"
                            label="Job"
                            defaultValue={preSelectedJobId}
                            {...register('jobId')}
                        >
                            {jobs.map(job => (
                                <MenuItem key={job.id} value={job.id}>
                                    {job.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error={Boolean(formErrors.jobId)}>
                            {formErrors.jobId?.message}
                        </FormHelperText>
                    </FormControl>
                    {selectedJob && (
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel id="select-material">Material</InputLabel>
                            <Select
                                labelId="select-material"
                                label="Material"
                                defaultValue={
                                    selectedJob.materials.length > 0
                                        ? selectedJob.materials[0].id
                                        : undefined
                                }
                                {...register('materialId')}
                            >
                                {selectedJob?.materials.map(material => (
                                    <MenuItem key={material.id} value={material.id}>
                                        {material.unitPlural} of {material.materialName}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={Boolean(formErrors.materialId)}>
                                {formErrors.materialId?.message}
                            </FormHelperText>
                        </FormControl>
                    )}
                    <FormControl fullWidth>
                        <PhotoPicker
                            value={photo}
                            onChange={file =>
                                setValue('photo', file, {
                                    shouldValidate: true,
                                })
                            }
                        />
                        <FormHelperText error={Boolean(formErrors.photo)}>
                            {formErrors.photo?.message}
                        </FormHelperText>
                    </FormControl>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Ticket ID"
                        error={Boolean(formErrors.identifier)}
                        helperText={
                            formErrors.identifier ? (
                                <Typography>{formErrors.identifier.message}</Typography>
                            ) : undefined
                        }
                        {...register('identifier')}
                    />
                    {selectedMaterial && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label={`${selectedMaterial.unitPlural ?? 'Amount'} of ${
                                selectedMaterial.materialName ?? 'material'
                            } in this ticket`}
                            error={Boolean(formErrors.materialAmount)}
                            helperText={
                                formErrors.materialAmount ? (
                                    <Typography>{formErrors.materialAmount?.message}</Typography>
                                ) : null
                            }
                            {...register('materialAmount')}
                        />
                    )}
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Date"
                                type="date"
                                error={Boolean(formErrors.date)}
                                helperText={
                                    formErrors.identifier ? (
                                        <Typography>{formErrors.date?.message}</Typography>
                                    ) : undefined
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register('date')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Time"
                                type="time"
                                error={Boolean(formErrors.time)}
                                helperText={
                                    formErrors.identifier ? (
                                        <Typography>{formErrors.time?.message}</Typography>
                                    ) : undefined
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register('time')}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Notes (optional)"
                        type="notes"
                        placeholder="Additional information about this ticket..."
                        multiline
                        rows={3}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...register('notes')}
                    />
                    {!isIdentifierUnique && (
                        <Alert severity="error">
                            A ticket with the code "{identifier}" already exists...
                        </Alert>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        startIcon={<Upload />}
                        disabled={
                            !formState.isValid || formState.isSubmitting || !isIdentifierUnique
                        }
                    >
                        Upload
                    </Button>
                </Box>
            ) : (
                <Typography>
                    You have not yet been assigned to any jobs... Please contact the owner of your
                    company to either add to you a job or dispatch pending ones.
                </Typography>
            )}
        </Card>
    )
}
