import { Box, Card, Divider, Typography } from '@mui/material'
import { LoadingView } from '../../../components/layouts/LoadingView'
import { ActiveShiftView } from '../../../components/shifts/ActiveShiftView'
import { EmployeeShiftsList } from '../../../components/shifts/EmployeeShiftsList'
import { NoActiveShiftView } from '../../../components/shifts/NoActiveShiftView'
import { useCompanyEmployee } from '../../../hooks/useCompanyEmployee'
import { useWhoAmI } from '../../../hooks/useWhoAmI'

export const ConsoleShiftsPage: React.FC = () => {
    const { whoAmI } = useWhoAmI()
    const { employee, refetch } = useCompanyEmployee(whoAmI?.employeeAccount.id)
    if (!employee) {
        return <LoadingView />
    }
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            {employee.activeShift ? (
                <ActiveShiftView shift={employee.activeShift} refetch={refetch} />
            ) : (
                <NoActiveShiftView refetch={refetch} />
            )}
            {employee.pastShifts && employee.pastShifts.length > 0 && (
                <Box>
                    <Divider sx={{ marginY: 4 }} />
                    <Typography variant="h4" marginBottom={3}>
                        Past Shifts
                    </Typography>
                    <EmployeeShiftsList shifts={employee.pastShifts} />
                </Box>
            )}
        </Card>
    )
}
