import { useMemo, useState } from 'react'

const calculateSearchScore = (texts: string[], searchTerms: string[]) => {
    return texts.reduce((textsTotal, text) => {
        return (
            textsTotal +
            searchTerms.reduce((termsTotal, term) => {
                if (text.indexOf(term) !== -1) {
                    return termsTotal + 1
                }
                return termsTotal
            }, 0)
        )
    }, 0)
}

export const useSimpleTextSearch = <E>(
    items: E[],
    getIdFn: (item: E) => string | number,
    getTextsFn: (item: E) => string[],
    defaultSearchText = '',
) => {
    const [searchText, setSearchText] = useState(defaultSearchText)
    const idToItemMap = useMemo(() => {
        const map: Record<string, E> = {}
        items.forEach(item => {
            map[getIdFn(item)] = item
        })
        return map
    }, [items, getIdFn])
    const itemTextsMap = useMemo(() => {
        const map: Record<string, string[]> = {}
        items.forEach(item => {
            const texts = getTextsFn(item).filter(t => Boolean(t))
            map[getIdFn(item)] = texts.map(txt => txt.toLowerCase())
        })
        return map
    }, [items, getIdFn, getTextsFn])
    const sortedSearchResults = useMemo(() => {
        const searchTerms = searchText.toLowerCase().split(',')
        const sortedItemScores = items
            .map<[E, number]>(item => [
                item,
                calculateSearchScore(itemTextsMap[getIdFn(item)], searchTerms),
            ])
            .filter(([_, score]) => {
                return score > 0
            })
            .sort(([_, scoreA], [__, scoreB]) => {
                if (scoreA > scoreB) {
                    return -1
                }
                return 1
            })
        return sortedItemScores.map(([item]) => item)
    }, [items, itemTextsMap, idToItemMap, searchText])
    return {
        results: sortedSearchResults,
        searchText,
        setSearchText,
    }
}
