import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { useMemo } from 'react'
import { formatMaterialAmount, formatMaterialIdentifier } from '../../lib/formatting'
import { groupBy } from '../../lib/groupBy'
import { TicketObject } from '../../lib/types/gqlTypeAbbreviations'

type Props = {
    tickets: TicketObject[]
}

export const TicketCollectionMaterialsAccordion: React.FC<Props> = ({ tickets }) => {
    const materialAmountsMap = useMemo(() => {
        const materialGroups = groupBy(tickets, ticket => formatMaterialIdentifier(ticket.material))
        const map: Record<string, number> = {}
        Object.keys(materialGroups).map(material => {
            map[material] = materialGroups[material].reduce(
                (total, ticket) => total + ticket.materialAmount,
                0,
            )
        })
        return map
    }, [tickets])
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>Material Totals</AccordionSummary>
            <AccordionDetails>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Material</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(materialAmountsMap).map(material => {
                            return (
                                <TableRow key={material}>
                                    <TableCell>{material}</TableCell>
                                    <TableCell>
                                        {formatMaterialAmount(materialAmountsMap[material])}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    )
}
