import { Alert, Box, Button, Divider, Grid, Link, Typography } from '@mui/material'
import { useJob } from '../../../../../hooks/useJob'
import styled from '@emotion/styled'
import { useCallback, useMemo, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { formatClientName, formatMaterialAmount, usdFormatter } from '../../../../../lib/formatting'
import moment from 'moment'
import ProductLogo from '../../../../../components/ProductLogo'
import { useCompany } from '../../../../../hooks/useCompany'
import { useJobTickets } from '../../../../../hooks/useJobTickets'

const PrintContainer = styled(Box)`
    @page {
        size: A4;
        margin: 0.5in;
    }
`

const Table = styled.table`
    border-collapse: collapse;
    page-break-inside: avoid;

    & tr td,
    & tr th {
        page-break-inside: avoid;
    }
`

const Thead = styled.thead`
    & tr td {
        font-weight: bold;
    }
`

const Tbody = styled.tbody``

const Tr = styled.tr`
    &:nth-child(number) {
        background-color: gray;
    }
`

const Td = styled.td`
    padding: 5px 0px;
`

export const JobInvoicePage: React.FC = () => {
    const { company } = useCompany()
    const { job } = useJob()
    const { tickets } = useJobTickets()
    const ref = useRef<HTMLDivElement>()
    const content = useCallback(() => ref.current, [ref.current])
    const handlePrint = useReactToPrint({
        content,
        removeAfterPrint: true,
        documentTitle: `invoice-0000-${moment().format('YYYY-MM-DD')}`,
    })
    const sortedTickets = useMemo(
        () =>
            [...tickets].sort((a, b) => {
                if (a.timestamp > b.timestamp) {
                    return -1
                } else if (a.timestamp < b.timestamp) {
                    return 1
                }
                return 0
            }),
        [tickets],
    )
    return (
        <Box>
            <Alert severity="warning" sx={{ marginBottom: 3 }}>
                This page is only meant to serve as a realistic example of what an invoice would
                look like. Get in touch to learn more.
            </Alert>
            <Button variant="contained" onClick={handlePrint}>
                Print
            </Button>
            <Box marginTop={3} maxHeight={window.innerHeight * 0.9} overflow="scroll" width="210mm">
                <PrintContainer ref={ref} id="print-area" display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h5">Invoice # 000000</Typography>
                            <Typography variant="h6">{company.name}</Typography>
                            <Typography>Address</Typography>
                            <Typography>City, State, Zip</Typography>
                            <Typography>+1 OwnerPhone</Typography>
                        </Box>
                        {job.client && (
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h5" textAlign="end">
                                    Bill To
                                </Typography>
                                <Typography variant="h6" textAlign="end">
                                    {formatClientName(job.client, 'Nameless Client')}
                                </Typography>
                                <Typography textAlign="end">Address</Typography>
                                <Typography textAlign="end">City, State, Zip</Typography>
                                <Typography textAlign="end">+1 ClientPhone</Typography>
                            </Box>
                        )}
                    </Box>
                    <Divider sx={{ marginY: 3 }} />
                    <Grid container>
                        <Grid item xs={4}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h5">Job</Typography>
                                <Typography>{job.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h5" textAlign="end">
                                    Invoice Total
                                </Typography>
                                <Typography textAlign="end">
                                    {usdFormatter.format(
                                        sortedTickets.reduce(
                                            (total, ticket) => total + ticket.revenue,
                                            0,
                                        ),
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 3 }} />
                    <Typography variant="h5" marginBottom={2}>
                        Materials ({job.materials.length})
                    </Typography>
                    <Table>
                        <Thead>
                            <Tr>
                                <Td>Material</Td>
                                <Td>Amount</Td>
                                <Td>Per Unit</Td>
                                <Td>
                                    <Typography textAlign="end" fontWeight="bold">
                                        Price
                                    </Typography>
                                </Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {job.materials.map(material => {
                                const relevantTickets = sortedTickets.filter(
                                    ticket => ticket.material.id === material.id,
                                )
                                return (
                                    <Tr key={material.id}>
                                        <Td>
                                            {material.unitPlural} of {material.materialName}
                                        </Td>
                                        <Td>
                                            {formatMaterialAmount(
                                                relevantTickets.reduce(
                                                    (sum, ticket) => sum + ticket.materialAmount,
                                                    0,
                                                ),
                                            )}
                                        </Td>
                                        <Td>
                                            {usdFormatter.format(material.effectivePricePerUnit)}
                                        </Td>
                                        <Td>
                                            <Typography textAlign="end">
                                                {usdFormatter.format(
                                                    relevantTickets.reduce(
                                                        (total, ticket) => total + ticket.revenue,
                                                        0,
                                                    ),
                                                )}
                                            </Typography>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                    <Box marginTop={10} display="flex" justifyContent="center" alignItems="center">
                        <Box>
                            <ProductLogo size={30} />
                        </Box>
                        <Typography display="flex" marginX={2}>
                            This invoice was generated with FleetMage.
                        </Typography>
                        <Link href="https://fleetmage.com">https://fleetmage.com</Link>
                    </Box>
                </PrintContainer>
            </Box>
        </Box>
    )
}
