import { useFetch } from './useFetch'

export type GpsInsightVehicleObject = {
    id: string
    vin: string
    label: string
    latitude: number
    longitude: number
}

export const useVehicleGps = () => {
    const { data } = useFetch<GpsInsightVehicleObject[]>('/api/vehicle_gps')
    return data ?? []
}
