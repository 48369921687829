import { Marker, Popup } from 'mapbox-gl'
import { MarkerOptions } from 'mapbox-gl'
import React, { useEffect, useRef, useState } from 'react'
import { useMapboxMap } from '../../hooks/useMap'
import { LatLngObj } from '../../lib/geo'

type Props = {
    loc?: LatLngObj
    text?: string
    hover?: boolean
    color?: MarkerOptions['color']
}

export const MapMarker: React.FC<Props> = ({ loc, text, color = 'red', hover = true }) => {
    const map = useMapboxMap()
    const marker = useRef<Marker | null>(null)
    const popup = useRef<Popup | null>(null)
    const [isAdded, setIsAdded] = useState(false)
    useEffect(() => {
        if (isAdded) {
            marker.current.remove()
            if (text) {
                popup.current.remove()
            }
            setIsAdded(false)
        }
        if (loc) {
            marker.current = new Marker({
                color,
            })
                .setLngLat(loc)
                .addTo(map)
            if (text) {
                popup.current = new Popup({ closeButton: false }).setText(text).addTo(map)
                popup.current.addClassName('marker-popover')
                marker.current.setPopup(popup.current)
            }
            setIsAdded(true)
        }
        return () => {
            if (marker.current) {
                marker.current.remove()
            }
        }
    }, [loc])
    return null
}
