import { Add } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { EmployeeAssignedVehicleDropdown } from '../../../../components/inputs/EmployeeAssignedVehicleDropdown'
import { useCompany } from '../../../../hooks/useCompany'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { isMobile } from '../../../../lib/navigator'
import { InviteEmployeeModal } from './InviteEmployeeModal'

export const DashboardEmployeesListPage: React.FC = () => {
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const { company, isTruckingCompany } = useCompany()
    const navigate = useNavigate()
    return (
        <>
            <InviteEmployeeModal open={isVisible} onClose={closeModal} />
            <Box
                marginBottom={2}
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent="flex-end"
            >
                <Button
                    sx={{ marginLeft: 2 }}
                    variant="contained"
                    onClick={openModal}
                    startIcon={<Add />}
                >
                    Invite Employee
                </Button>
            </Box>
            <DataGrid
                autoHeight={isMobile}
                rows={company.employees}
                columnVisibilityModel={{
                    assignedVehicle: isTruckingCompany,
                }}
                columns={[
                    {
                        field: 'name',
                        headerName: 'Name',
                        minWidth: 150,
                        flex: 1,
                        valueGetter: params => params.row.user.displayName,
                    },
                    {
                        field: 'phone',
                        headerName: 'Phone Number',
                        minWidth: 150,
                        valueGetter: params => params.row.user.phoneNumber,
                    },
                    {
                        field: 'assignedVehicle',
                        headerName: 'Assigned Vehicle',
                        minWidth: 200,
                        valueGetter: params => params.row.assignedVehicle?.vehicleId,
                        renderCell: params => {
                            return (
                                <EmployeeAssignedVehicleDropdown
                                    key={params.row.id}
                                    employee={params.row}
                                />
                            )
                        },
                    },
                    // {
                    //     field: 'viewBtn',
                    //     headerName: '',
                    //     disableExport: true,
                    //     sortable: false,
                    //     renderCell: params => {
                    //         return (
                    //             <Button
                    //                 variant="contained"
                    //                 onClick={() => navigate(`/dashboard/employee/${params.row.id}`)}
                    //             >
                    //                 View
                    //             </Button>
                    //         )
                    //     },
                    // },
                ]}
            />
        </>
    )
}
