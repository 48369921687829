import { Box, Card, Tab, Tabs, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Link } from '../Link'
import { ChildrenProp } from '../../lib/componentProps'
import { isMobile } from '../../lib/navigator'
import { useInvoice } from '../../hooks/useInvoice'

export const InvoicePageLayout: React.FC<ChildrenProp> = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const tab = useMemo(() => {
        const pathSections = location.pathname.split('/')
        if (pathSections.length < 5) {
            return ''
        }
        return pathSections[4]
    }, [location])
    const { invoice } = useInvoice()
    return (
        <>
            <Box>
                <Link to="/dashboard/invoices">{'<'} Back to invoice list</Link>
            </Box>
            <Card
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    flex: '1 1',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" marginRight={4}>
                            <strong>Invoice</strong> - {invoice.name}
                        </Typography>
                    </Box>
                    <Tabs
                        value={tab}
                        onChange={(_, tab) => {
                            navigate(`/dashboard/invoice/${invoice.id}/${tab}`)
                        }}
                        variant="scrollable"
                    >
                        <Tab label="Overview" value="overview" />
                        <Tab label="Tickets" value="tickets" />
                    </Tabs>
                </Box>
                <Box
                    flexGrow={1}
                    marginTop={3}
                    display="flex"
                    flexDirection="column"
                    overflow="scroll"
                >
                    <Outlet />
                    {children}
                </Box>
            </Card>
        </>
    )
}
