import { Delete } from '@mui/icons-material'
import { useCallback } from 'react'
import { useDeleteTicketMutation } from '../../graphql/gen/hooks'
import { useSnackbar } from 'notistack'
import { useWhoAmI } from '../../hooks/useWhoAmI'
import { ConfirmActionModal } from '../ConfirmActionModal'

type Props = {
    ticketId: number
    identifier: string
    open: boolean
    onClose: () => void
    onDelete: () => Promise<any>
}

export const ConfirmDeleteTicketModal: React.FC<Props> = ({
    open,
    onClose,
    ticketId,
    identifier,
    onDelete,
}) => {
    const { whoAmI } = useWhoAmI()
    const { enqueueSnackbar } = useSnackbar()
    const [deleteTicketMutation] = useDeleteTicketMutation({
        variables: {
            id: ticketId,
        },
    })
    const handleDeleteTicket = useCallback(async () => {
        await deleteTicketMutation()
        await onDelete()
        enqueueSnackbar(`Successfully deleted ticket ${identifier}.`)
    }, [deleteTicketMutation, onDelete])
    return (
        <ConfirmActionModal
            isOpen={open}
            close={onClose}
            onConfirm={handleDeleteTicket}
            btnColor="error"
            btnIcon={<Delete />}
            btnText="Delete"
            title={`Delete Ticket - ${identifier}`}
            message="Are you sure you wish to delete this ticket? This action is unreversible."
        />
    )
}
