import { createTheme, PaletteMode, ThemeProvider as MuiThemeProvider } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ChildrenProp } from '../../lib/componentProps'

export const getIsDarkFromLocalStorage = () =>
    localStorage.getItem(LOCAL_STORAGE_DARK_MODE_KEY) === '1'

type ColorModeContextVariables = {
    toggleMode: () => void
    setIsDarkMode: (isDark: boolean) => void
    isDarkMode: boolean
    mode: PaletteMode
}

const ColorModeContext = React.createContext<ColorModeContextVariables>(null)

export const useThemeMode = () => {
    return { ...useContext(ColorModeContext) }
}

type Props = ChildrenProp & {
    defaultMode?: PaletteMode
}

const LOCAL_STORAGE_DARK_MODE_KEY = 'dark_mode'

export const ThemeProvider: React.FC<Props> = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(getIsDarkFromLocalStorage())
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_DARK_MODE_KEY, isDarkMode ? '1' : '0')
    }, [isDarkMode])
    const theme = useMemo(() => {
        return createTheme({
            palette: {
                mode: isDarkMode ? 'dark' : 'light',
            },
        })
    }, [isDarkMode])
    const toggleMode = useCallback(() => {
        setIsDarkMode(!isDarkMode)
    }, [isDarkMode, setIsDarkMode])
    return (
        <MuiThemeProvider theme={theme}>
            <ColorModeContext.Provider
                value={{
                    isDarkMode,
                    mode: theme.palette.mode,
                    setIsDarkMode,
                    toggleMode,
                }}
            >
                {children}
            </ColorModeContext.Provider>
        </MuiThemeProvider>
    )
}
