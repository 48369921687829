import { Cancel, Save } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { SimpleModal } from '../../../../components/SimpleModal'
import {
    useAssignEmployeeToJobMutation,
    useCompanySavedContactsQuery,
    useCompanySavedLocationsQuery,
    useCreateJobMaterialMutation,
    useCreateJobMutation,
    useUpdateJobMutation,
} from '../../../../graphql/gen/hooks'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ALL_MATERIALS, ALL_MATERIAL_UNIT_PRESETS } from '../../../../lib/constants/materials'
import { useEffect, useMemo } from 'react'
import { floatRegex } from '../../../../lib/constants/regex'
import { useNavigate } from 'react-router-dom'
import { isMobile } from '../../../../lib/navigator'
import { useSnackbar } from 'notistack'
import { Link } from '../../../../components/Link'
import { useWhoAmI } from '../../../../hooks/useWhoAmI'
import { FormSelect } from '../../../../components/inputs/Select/FormSelect'
import { JobQuery } from '../../../../graphql/gen/operations'

type Props = {
    open: boolean
    onClose: () => void
    refetch: () => void
    existingJob?: JobQuery['job']
}

const editJobFormSchema = yup.object().shape({
    name: yup.string().required('You must provide the name of the contact'),
    instructions: yup.string(),
    originId: yup.number().integer().required('You must select an origin location'),
    destinationId: yup.number().integer().required('You must select an origin location'),
})

const newJobFormSchema = editJobFormSchema.shape({
    materialName: yup.string().required('You must select a material'),
    unitSingular: yup.string().required('You must select a unit of measurement'),
    pricePerUnit: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the price per unit of material'),
    pricePerUnitEmployee: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the employee earnings per unit of material'),
    expectedMaterialPerTicket: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the expected units of material per ticket'),
    expectedAmountForJob: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the expected units of material for the job'),
})

export const EditJobModal: React.FC<Props> = ({ open, onClose, refetch, existingJob }) => {
    const isEditing = Boolean(existingJob)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { whoAmI } = useWhoAmI()
    const { data: savedContactsData } = useCompanySavedContactsQuery()
    const { data: savedLocationsData } = useCompanySavedLocationsQuery()
    const { handleSubmit, register, formState, setValue, watch, control } = useForm<{
        name: string
        instructions: string
        originId: number | null
        destinationId: number | null
        materialName: string | null
        unitSingular: string | null
        expectedMaterialPerTicket: string
        expectedAmountForJob: string
        pricePerUnit: string
        pricePerUnitEmployee: string
        clientId: number | null
        assignSelf: boolean
    }>({
        resolver: yupResolver(isEditing ? editJobFormSchema : newJobFormSchema),
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            name: existingJob?.name ?? '',
            instructions: existingJob?.instructions ?? '',
            materialName: '',
            unitSingular: '',
            expectedMaterialPerTicket: '',
            expectedAmountForJob: '',
            pricePerUnit: '',
            pricePerUnitEmployee: '',
            assignSelf: false,
            clientId: existingJob?.client?.id ?? null,
            originId: existingJob?.origin.id ?? null,
            destinationId: existingJob?.destination.id ?? null,
        },
    })
    const ownerHasVehicle = useMemo(
        () => Boolean(whoAmI?.employeeAccount?.assignedVehicle) && !isEditing,
        [whoAmI, isEditing],
    )
    useEffect(() => {
        if (ownerHasVehicle) {
            setValue('assignSelf', true)
        }
    }, [ownerHasVehicle, whoAmI])
    const formErrors = formState.errors
    const [createJob] = useCreateJobMutation()
    const [updateJob] = useUpdateJobMutation()
    const [createJobMaterial] = useCreateJobMaterialMutation()
    const [assignEmployeeToJob] = useAssignEmployeeToJobMutation()
    const [selectedMaterialName, selectedUnitSingular] = watch(['materialName', 'unitSingular'])
    const selectedUnit = ALL_MATERIAL_UNIT_PRESETS.find(p => p.singular === selectedUnitSingular)
    const onSubmit = handleSubmit(async data => {
        let clientId: number | undefined = undefined
        if (typeof data.clientId === 'string') {
            clientId = parseInt(data.clientId)
        } else if (typeof data.clientId === 'number') {
            clientId = data.clientId
        }
        if (isEditing) {
            await updateJob({
                variables: {
                    id: existingJob.id,
                    name: data.name,
                    instructions: data.instructions,
                    originId: data.originId,
                    destinationId: data.destinationId,
                    clientId,
                },
            })
            await refetch()
            onClose()
            enqueueSnackbar('Successfully updated this job.', {
                variant: 'success',
            })
        } else {
            const resp = await createJob({
                variables: {
                    name: data.name,
                    instructions: data.instructions,
                    originId: data.originId,
                    destinationId: data.destinationId,
                    clientId,
                },
            })
            await createJobMaterial({
                variables: {
                    jobId: resp.data.createJob.id,
                    materialInput: {
                        materialName: data.materialName,
                        unitSingular: selectedUnit.singular,
                        unitPlural: selectedUnit.plural,
                        expectedAmountPerTicket: parseFloat(data.expectedMaterialPerTicket),
                        expectedAmountForJob: parseFloat(data.expectedAmountForJob),
                        pricePerUnit: parseFloat(data.pricePerUnit),
                        pricePerUnitEmployee: parseFloat(data.pricePerUnitEmployee),
                    },
                },
            })
            if (data.assignSelf && whoAmI?.employeeAccount?.assignedVehicle && resp.data) {
                await assignEmployeeToJob({
                    variables: {
                        jobId: resp.data.createJob.id,
                        employeeId: whoAmI.employeeAccount.id,
                        vehicleId: whoAmI.employeeAccount.assignedVehicle.id,
                    },
                })
            }
            await refetch()
            onClose()
            navigate(`/dashboard/job/${resp.data.createJob.id}`)
            enqueueSnackbar('Successfully created new job.', {
                variant: 'success',
            })
        }
    })
    useEffect(() => {
        if (selectedUnit) {
            setValue('expectedMaterialPerTicket', selectedUnit.defaultExpectedAmount.toString(), {
                shouldValidate: true,
                shouldDirty: true,
            })
            setValue('expectedAmountForJob', selectedUnit.defaultExpectedAmount.toString(), {
                shouldValidate: true,
                shouldDirty: true,
            })
        }
    }, [selectedUnitSingular])
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Create a new job</Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Job name"
                        error={Boolean(formErrors.name)}
                        helperText={
                            formErrors.name ? (
                                <Typography>{formErrors.name.message}</Typography>
                            ) : (
                                'This name will be used for quick reference.'
                            )
                        }
                        {...register('name')}
                    />
                    <FormControl fullWidth sx={{ marginRight: 2, marginBottom: 2 }}>
                        <FormSelect
                            name="clientId"
                            placeholder="Client (optional)"
                            control={control}
                            items={savedContactsData?.companySavedContacts ?? []}
                            getValueFn={contact => contact.id}
                            getLabelFn={contact => contact.name}
                        />
                        <FormHelperText error={Boolean(formErrors.clientId)}>
                            {formErrors.clientId?.message}
                            {savedContactsData?.companySavedContacts.length === 0 && (
                                <Box display="flex" alignItems="center">
                                    <Typography marginRight={1}>
                                        You have no saved contacts yet...
                                    </Typography>
                                    <Link to="/dashboard/contacts">Click here to create one</Link>
                                </Box>
                            )}
                        </FormHelperText>
                    </FormControl>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Instructions (optional)"
                        placeholder="Instructions for the workers assigned to this job."
                        multiline
                        rows={6}
                        error={Boolean(formErrors.instructions)}
                        helperText={
                            formErrors.instructions ? (
                                <Typography>{formErrors.instructions.message}</Typography>
                            ) : null
                        }
                        {...register('instructions')}
                    />
                    <Divider sx={{ marginY: 2 }} />
                    <Box display="flex" flexDirection="column">
                        <Typography marginBottom={2} variant="h5">
                            Locations
                        </Typography>
                        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                            <FormControl fullWidth sx={{ marginRight: 2, marginBottom: 2 }}>
                                <FormSelect
                                    name="originId"
                                    placeholder="Origin"
                                    control={control}
                                    items={savedLocationsData?.companySavedLocations ?? []}
                                    getValueFn={loc => loc.id}
                                    getLabelFn={loc => `${loc.name} (${loc.line1})`}
                                />
                                <FormHelperText error={Boolean(formErrors.originId)}>
                                    {formErrors.originId?.message ??
                                        'Starting location for the job.'}
                                </FormHelperText>
                            </FormControl>
                            <FormControl fullWidth>
                                <FormSelect
                                    name="destinationId"
                                    placeholder="Destination"
                                    control={control}
                                    items={savedLocationsData?.companySavedLocations ?? []}
                                    getValueFn={loc => loc.id}
                                    getLabelFn={loc => `${loc.name} (${loc.line1})`}
                                />
                                <FormHelperText error={Boolean(formErrors.destinationId)}>
                                    {formErrors.destinationId?.message ??
                                        'Ending location for the job.'}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                    {!isEditing && (
                        <>
                            <Divider sx={{ marginY: 2 }} />
                            <Box display="flex" flexDirection="column">
                                <Typography marginBottom={2} variant="h5">
                                    Material
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth>
                                            <FormSelect
                                                name="unitSingular"
                                                placeholder="Unit of measurement"
                                                disabled={isEditing}
                                                control={control}
                                                items={ALL_MATERIAL_UNIT_PRESETS}
                                                getValueFn={u => u.singular}
                                                getLabelFn={u => u.plural}
                                            />
                                            <FormHelperText
                                                error={Boolean(formErrors.unitSingular)}
                                            >
                                                {formErrors.unitSingular?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl
                                            fullWidth
                                            sx={{ marginRight: 2, marginBottom: 2 }}
                                        >
                                            <FormSelect
                                                name="materialName"
                                                placeholder="Material type"
                                                disabled={isEditing}
                                                control={control}
                                                items={ALL_MATERIALS}
                                                getValueFn={mat => mat}
                                                getLabelFn={mat => mat}
                                            />
                                            <FormHelperText
                                                error={Boolean(formErrors.materialName)}
                                            >
                                                {formErrors.materialName?.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            {selectedUnit && selectedMaterialName && (
                                <>
                                    <Divider sx={{ marginY: 2 }} />
                                    <Typography marginBottom={2} variant="h5">
                                        Expected work
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                disabled={isEditing}
                                                type="number"
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                label={`Expected ${selectedUnit.plural} of ${selectedMaterialName} per ticket`}
                                                error={Boolean(
                                                    formErrors.expectedMaterialPerTicket,
                                                )}
                                                helperText={
                                                    formErrors.expectedMaterialPerTicket ? (
                                                        <Typography>
                                                            {
                                                                formErrors.expectedMaterialPerTicket
                                                                    .message
                                                            }
                                                        </Typography>
                                                    ) : null
                                                }
                                                {...register('expectedMaterialPerTicket')}
                                                sx={{
                                                    marginRight: 2,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                disabled={isEditing}
                                                type="number"
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                label={`Expected ${selectedUnit?.plural} of ${selectedMaterialName} for the entire job`}
                                                error={Boolean(formErrors.expectedAmountForJob)}
                                                helperText={
                                                    formErrors.expectedAmountForJob ? (
                                                        <Typography>
                                                            {
                                                                formErrors.expectedAmountForJob
                                                                    .message
                                                            }
                                                        </Typography>
                                                    ) : null
                                                }
                                                {...register('expectedAmountForJob')}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ marginY: 2 }} />
                                    <Typography variant="h5">Revenue</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                disabled={isEditing}
                                                type="number"
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                label={`$ Price per ${selectedUnit?.singular} of ${selectedMaterialName}`}
                                                error={Boolean(formErrors.pricePerUnit)}
                                                helperText={
                                                    formErrors.pricePerUnit ? (
                                                        <Typography>
                                                            {formErrors.pricePerUnit.message}
                                                        </Typography>
                                                    ) : null
                                                }
                                                {...register('pricePerUnit')}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                disabled={isEditing}
                                                type="number"
                                                inputProps={{
                                                    inputMode: 'decimal',
                                                }}
                                                label={`$ Employee earnings per ${selectedUnit?.singular} of ${selectedMaterialName}`}
                                                error={Boolean(formErrors.pricePerUnitEmployee)}
                                                helperText={
                                                    formErrors.pricePerUnitEmployee ? (
                                                        <Typography>
                                                            {
                                                                formErrors.pricePerUnitEmployee
                                                                    .message
                                                            }
                                                        </Typography>
                                                    ) : null
                                                }
                                                {...register('pricePerUnitEmployee')}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Divider sx={{ marginY: 2 }} />
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    defaultChecked={ownerHasVehicle}
                                    disabled={!ownerHasVehicle || isEditing}
                                    {...register('assignSelf')}
                                />
                                <Typography>Assign myself to this job</Typography>
                            </Box>
                            {!ownerHasVehicle && (
                                <Alert severity="warning">
                                    This option is disabled due to your own account having no
                                    vehicle assigned, you can fix this from the vehicles page or the
                                    employees page.
                                </Alert>
                            )}
                        </>
                    )}
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<Save />}
                                disabled={!formState.isValid || formState.isSubmitting}
                            >
                                {isEditing ? 'Update' : 'Create'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
