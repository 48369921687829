import axios from 'axios'
import { useEffect, useState } from 'react'

export const useCloudFile = <E>(fileId?: number) => {
    const [data, setData] = useState<null | E>(null)
    useEffect(() => {
        if (fileId) {
            axios.get<E>(`/api/file/${fileId}`).then(resp => {
                setData(resp.data)
            })
        }
    }, [fileId])
    return data
}
