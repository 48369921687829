import styled from '@emotion/styled'
import { AppBar, Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Link as UnstyledLink } from '../../components/Link'
import { ChildrenProp } from '../../lib/componentProps'
import { Toolbar } from '../controls/Toolbar'

const Link = styled(UnstyledLink)`
    color: white;
    cursor: pointer;
    margin-left: 15px;
`

export const LegalLayout: React.FC<ChildrenProp> = ({ children }) => {
    return (
        <Box>
            <AppBar position="fixed">
                <Toolbar>
                    <Link to="/legal/terms">Terms & Conditions</Link>
                    <Link to="/legal/privacy">Privacy Policy</Link>
                    <Link to="/legal/eula">EULA</Link>
                    <Link to="/legal/cookies">Cookie Policy</Link>
                </Toolbar>
            </AppBar>
            <Box>
                <Outlet />
                {children}
            </Box>
        </Box>
    )
}
