import { Add, Delete, MoreHoriz } from '@mui/icons-material'
import {
    Box,
    Button,
    Card,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useRef } from 'react'
import { DatagridToolbarExportAndFilter } from '../../../../components/datagrid/DatagridToolbarExportAndFilter'
import { VehicleAssignedEmployeeDropdown } from '../../../../components/inputs/VehicleAssignedEmployeeDropdown'
import { VehicleDescriptionField } from '../../../../components/inputs/VehicleDescriptionField'
import { useCompany } from '../../../../hooks/useCompany'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { isMobile } from '../../../../lib/navigator'
import { AddVehicleModal } from './AddVehicleModal'

const VehicleGridActionMenu: React.FC<{
    vehicleId: number
}> = () => {
    const ref = useRef()
    const { isVisible, show, hide } = useVisibilityState()
    return (
        <Box onClick={e => e.stopPropagation()}>
            <IconButton ref={ref} onClick={show}>
                <MoreHoriz />
            </IconButton>
            <Menu anchorEl={ref.current} open={isVisible} onClose={hide}>
                <MenuItem disabled>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export const DashboardVehiclesPage: React.FC = () => {
    const { isVisible, show: openModal, hide: closeModal } = useVisibilityState()
    const { company } = useCompany()
    return (
        <>
            <AddVehicleModal open={isVisible} onClose={closeModal} />
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Typography variant="h5" marginBottom={isMobile ? 2 : 0}>
                        Company vehicles
                    </Typography>
                    <Button variant="contained" onClick={openModal} startIcon={<Add />}>
                        Add Vehicle
                    </Button>
                </Box>
                <Box flexGrow={1} marginTop={3}>
                    <DataGrid
                        rows={company.vehicles}
                        autoHeight={isMobile}
                        columnVisibilityModel={{
                            description: !isMobile,
                            actions: false,
                        }}
                        columns={[
                            {
                                field: 'vehicleId',
                                headerName: 'Vehicle ID',
                                minWidth: 150,
                            },
                            {
                                field: 'description',
                                headerName: 'Description',
                                minWidth: 150,
                                flex: 1,
                                renderCell: params => (
                                    <VehicleDescriptionField vehicle={params.row} />
                                ),
                            },
                            {
                                field: 'assignedEmployee',
                                headerName: 'Assigned Employee',
                                minWidth: 200,
                                valueGetter: params =>
                                    params.row.assignedEmployee?.user.displayName,
                                renderCell: params => (
                                    <VehicleAssignedEmployeeDropdown vehicle={params.row} />
                                ),
                            },
                            {
                                field: 'actions',
                                headerName: 'Actions',
                                disableColumnMenu: true,
                                disableExport: true,
                                sortable: false,
                                renderCell: params => {
                                    return (
                                        <VehicleGridActionMenu
                                            key={params.row.id}
                                            vehicleId={params.row.id}
                                        />
                                    )
                                },
                            },
                        ]}
                        components={{
                            Toolbar: DatagridToolbarExportAndFilter,
                        }}
                    />
                </Box>
            </Card>
        </>
    )
}
