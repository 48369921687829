import React, { useCallback, useEffect } from 'react'
import { useMapboxMap } from '../../hooks/useMap'
import { LngLatPair } from '../../lib/geo'

type Props = {
    name: string
    coordinates: LngLatPair[]
    color?: string
}

export const MapPath: React.FC<Props> = ({ name, coordinates, color = '#888' }) => {
    const map = useMapboxMap()
    const addSourceAndLayer = useCallback(() => {
        map.addSource(name, {
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates,
                },
            },
        })
        map.addLayer({
            id: name,
            type: 'line',
            source: name,
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': color,
                'line-width': 6,
            },
        })
    }, [name, map, coordinates])
    const removeSourceAndLayer = useCallback(() => {
        if (map.getSource(name)) {
            if (map.getLayer(name)) {
                map.removeLayer(name)
            }
            map.removeSource(name)
        }
    }, [name, map, coordinates])
    useEffect(() => {
        if (map.loaded()) {
            removeSourceAndLayer()
            addSourceAndLayer()
        } else {
            map.once('load', () => {
                addSourceAndLayer()
            })
        }
    }, [name, map, coordinates])
    return null
}
