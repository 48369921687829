import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'
import { useState } from 'react'
import { TicketObject } from '../../lib/types/gqlTypeAbbreviations'
import { MenuButton } from '../inputs/MenuButton'
import { BulkTicketAssignCustomerModal } from '../ticket/BulkTicketAssignCustomerModal'
import { BulkTicketAssignInvoiceModal } from '../ticket/BulkTicketAssignInvoiceModal'

type Props = {
    selectedTickets: TicketObject[]
    refetch: () => any
}

export const DashboardTicketsDatagridToolbar: React.FC<Props> = ({ selectedTickets, refetch }) => {
    const [showUpdateCustomer, setShowUpdateCustomer] = useState(false)
    const [showUpdateInvoice, setShowUpdateInvoice] = useState(false)
    return (
        <>
            <BulkTicketAssignCustomerModal
                open={showUpdateCustomer}
                onClose={() => setShowUpdateCustomer(false)}
                tickets={selectedTickets}
                refetch={refetch}
            />
            <BulkTicketAssignInvoiceModal
                open={showUpdateInvoice}
                onClose={() => setShowUpdateInvoice(false)}
                tickets={selectedTickets}
                refetch={refetch}
            />
            <GridToolbarContainer>
                <GridToolbarExport />
                {selectedTickets.length > 0 && (
                    <MenuButton
                        idName="bulk-ticket-edit"
                        buttonText={`Bulk Operations (${selectedTickets.length})`}
                        items={[
                            {
                                text: 'Assign Customer',
                                callback: () => {
                                    setShowUpdateCustomer(true)
                                },
                            },
                            {
                                text: 'Add To Invoice',
                                callback: () => {
                                    setShowUpdateInvoice(true)
                                },
                            },
                        ]}
                    />
                )}
            </GridToolbarContainer>
        </>
    )
}
