import { Button, ButtonProps, Grid, Modal, Typography } from '@mui/material'
import { SimpleModal } from './SimpleModal'
import { Cancel } from '@mui/icons-material'
import { useState } from 'react'

type Props<E> = {
    item?: E
    isOpen: boolean
    close: () => void
    onConfirm: (item?: E) => void | Promise<void>
    title?: string
    message?: string
    btnText?: string
    btnColor?: ButtonProps['color']
    btnIcon?: ButtonProps['startIcon']
}

export function ConfirmActionModal<E = void>({
    item,
    isOpen,
    close,
    onConfirm,
    title = 'Confirmation',
    message = 'Are you sure?',
    btnColor,
    btnText = 'Confirm',
    btnIcon,
}: Props<E>) {
    const [loading, setLoading] = useState(false)
    return (
        <Modal open={isOpen} onClose={close}>
            <SimpleModal onClickOutside={close} width="md">
                <Typography variant="h5" marginBottom={2}>
                    {title}
                </Typography>
                <Typography>{message}</Typography>
                <Grid marginTop={2} container spacing={2}>
                    <Grid item sm={6}>
                        <Button fullWidth startIcon={<Cancel />} onClick={close} disabled={loading}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color={btnColor}
                            startIcon={btnIcon}
                            disabled={loading}
                            onClick={() => {
                                setLoading(true)
                                if (onConfirm) {
                                    onConfirm(item)
                                }
                                setLoading(false)
                                close()
                            }}
                        >
                            {btnText}
                        </Button>
                    </Grid>
                </Grid>
            </SimpleModal>
        </Modal>
    )
}
