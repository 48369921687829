import { Add } from '@mui/icons-material'
import { Box, Button, Card, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useSupplierMaterialsQuery } from '../../../../graphql/gen/hooks'
import { SupplierMaterialsQuery } from '../../../../graphql/gen/operations'
import { useVisibilityState } from '../../../../hooks/useVisibilityState'
import { usdFormatter } from '../../../../lib/formatting'
import { isMobile } from '../../../../lib/navigator'
import { EditMaterialModal } from './EditMaterialModal'

export const DashboardMaterialsPage: React.FC = () => {
    const [editingMaterial, setEditingMaterial] = useState<
        SupplierMaterialsQuery['supplierMaterials'][0] | null
    >(null)
    const { isVisible, show: openModal, hide: _closeModal } = useVisibilityState()
    const closeModal = useCallback(() => {
        setEditingMaterial(null)
        _closeModal()
    }, [_closeModal, setEditingMaterial])
    const { data, refetch } = useSupplierMaterialsQuery()
    const materials = useMemo(() => {
        return data?.supplierMaterials ?? []
    }, [data])
    return (
        <>
            <EditMaterialModal
                editMaterial={editingMaterial}
                open={isVisible || Boolean(editingMaterial)}
                onClose={closeModal}
                refetch={refetch}
            />
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <Typography variant="h5" marginBottom={isMobile ? 2 : 0}>
                        Materials
                    </Typography>
                    <Button variant="contained" onClick={openModal} startIcon={<Add />}>
                        Add Material
                    </Button>
                </Box>
                <Box flexGrow={1} marginTop={3}>
                    <DataGrid
                        autoHeight={isMobile}
                        rows={materials}
                        columns={[
                            {
                                field: 'materialDisplay',
                                headerName: 'Material',
                                minWidth: 150,
                                flex: 1,
                                valueGetter: params =>
                                    `${params.row.unitPlural} of ${params.row.materialName}`,
                            },
                            {
                                field: 'materialPerTicket',
                                headerName: 'Default Per Ticket',
                                minWidth: 150,
                                flex: 1,
                                valueGetter: params =>
                                    `${params.row.expectedAmountPerTicket} ${params.row.unitPlural}`,
                            },
                            {
                                field: 'price',
                                headerName: 'Price',
                                type: 'numeric',
                                minWidth: 150,
                                flex: 1,
                                valueGetter: params => params.row.pricePerUnit,
                                valueFormatter: params => usdFormatter.format(params.value),
                            },
                            {
                                field: 'btn',
                                headerName: '',
                                minWidth: 150,
                                renderCell: params => {
                                    return (
                                        <Button
                                            variant="contained"
                                            onClick={() => setEditingMaterial(params.row)}
                                        >
                                            Edit
                                        </Button>
                                    )
                                },
                            },
                        ]}
                    />
                </Box>
            </Card>
        </>
    )
}
