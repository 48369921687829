import { CalendarMonth, Construction } from '@mui/icons-material'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import moment from 'moment'
import { useMemo } from 'react'
import { useEmployeeShiftsQuery } from '../../../../graphql/gen/hooks'
import { EmployeeShiftsQuery } from '../../../../graphql/gen/operations'
import { mergeArrays } from '../../../../lib/arrayLogic'
import { groupBy } from '../../../../lib/groupBy'
import { formatMinuteDurationString, normalizeShift } from '../../../../lib/shiftLogic'

type EmployeeObject = Omit<EmployeeShiftsQuery['listEmployees'][0], 'activeShift' | 'pastShifts'>

type GlobalShiftObject = EmployeeShiftsQuery['listEmployees'][0]['activeShift'] & {
    employee: EmployeeObject
}

const flipEmployeeResponseObject = (obj: EmployeeShiftsQuery['listEmployees'][0]) => {
    const shifts: GlobalShiftObject[] = []
    const employee = {
        id: obj.id,
        user: {
            id: obj.user.id,
            displayName: obj.user.displayName,
        },
    }
    if (obj.activeShift) {
        shifts.push({
            ...obj.activeShift,
            employee,
        })
    }
    obj.pastShifts.forEach(shift => shifts.push({ ...shift, employee }))
    return shifts
}

export const DashboardEmployeesShiftsPage: React.FC = () => {
    const { data } = useEmployeeShiftsQuery()
    const allShifts = useMemo<GlobalShiftObject[]>(() => {
        if (!data?.listEmployees) {
            return []
        }
        const shifts: GlobalShiftObject[] = []
        data?.listEmployees.forEach(emp => {
            flipEmployeeResponseObject(emp).forEach(shift => shifts.push(shift))
        })
        return shifts
    }, [data])
    const normalizedShifts = useMemo(
        () => mergeArrays(allShifts.map(shift => normalizeShift(shift, shift.employee))),
        [allShifts],
    )
    const shiftsByDt = useMemo(() => groupBy(normalizedShifts, shift => shift.dt), [
        normalizedShifts,
    ])
    const dtsWithShifts = useMemo(() => Object.keys(shiftsByDt).sort().reverse(), [shiftsByDt])
    return (
        <>
            {dtsWithShifts.map(dtStr => {
                const dt = moment(dtStr)
                const dtShifts = shiftsByDt[dtStr]
                return (
                    <Box key={dtStr} marginY={2}>
                        <Typography variant="h5">{dt.format('dddd MMM Do, YYYY')}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Employee</TableCell>
                                    <TableCell width={150}>Start</TableCell>
                                    <TableCell width={150}>End</TableCell>
                                    <TableCell width={150}>Duration</TableCell>
                                    <TableCell width={150}> </TableCell>
                                    <TableCell width={150}> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dtShifts.map(shift => {
                                    return (
                                        <TableRow key={shift.shiftId}>
                                            <TableCell>{shift.employee.user.displayName}</TableCell>
                                            <TableCell>{shift.start.format('h:mm A')}</TableCell>
                                            <TableCell>
                                                {shift.ongoing && !shift.isSplit
                                                    ? '-'
                                                    : shift.end.format('h:mm A')}
                                            </TableCell>
                                            <TableCell>
                                                {formatMinuteDurationString(shift.totalMins)}
                                            </TableCell>
                                            <TableCell>
                                                {shift.isSplit && (
                                                    <Tooltip title="Multi-day shift">
                                                        <CalendarMonth sx={{ opacity: 0.5 }} />
                                                    </Tooltip>
                                                )}
                                                {shift.ongoing && (
                                                    <Tooltip title="Currently Working">
                                                        <Construction sx={{ opacity: 0.5 }} />
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button variant="contained" disabled>
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                )
            })}
        </>
    )
}
