import LogRocket from 'logrocket'
import setUpLogRocketReact from 'logrocket-react'
import { appConfig } from '../appConfig'

if (appConfig.isProduction) {
    LogRocket.init(appConfig.logRocketKey)
    setUpLogRocketReact(LogRocket)
}

export { LogRocket }
