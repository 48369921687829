import { useMemo } from 'react'
import { useCompanyTicketsQuery } from '../graphql/gen/hooks'
import { useJob } from './useJob'

export const useJobTickets = () => {
    const { job } = useJob()
    const { data, refetch } = useCompanyTicketsQuery({
        variables: {
            params: {
                jobId: job.id,
            },
        },
    })
    const tickets = useMemo(() => data?.companyTickets ?? [], [data?.companyTickets])
    return { tickets, refetch }
}
