import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorView } from '../../../components/ErrorView'
import { Link } from '../../../components/Link'
import { useAuth } from '../../../hooks/useAuth'
import { useFetch } from '../../../hooks/useFetch'
import { LoadingView } from '../../../components/layouts/LoadingView'

export const IntuitOauthCallbackPage: React.FC = () => {
    const { firebaseUser, loading } = useAuth()
    const navigate = useNavigate()
    const { fetch: post, status } = useFetch('/api/intuit/claim_token', {
        method: 'POST',
        data: {
            redirectUri: window.location.href,
        },
    })
    useEffect(() => {
        const cachedTokenUrl = localStorage.getItem('claim_token_href')
        if (firebaseUser && cachedTokenUrl !== window.location.href) {
            localStorage.setItem('claim_token_href', window.location.href)
            post()
        }
    }, [firebaseUser, loading])
    useEffect(() => {
        if (status === 200) {
            navigate('/dashboard/integrations')
        }
    }, [status])
    if (!loading && !firebaseUser) {
        return (
            <ErrorView title="Not Signed In">
                <Link to="/signin">Go To Sign In Page</Link>
            </ErrorView>
        )
    }
    return <LoadingView />
}
