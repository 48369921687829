import { Cancel, Save } from '@mui/icons-material'
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material'
import { SimpleModal } from '../../../../components/SimpleModal'
import {
    useCompanySavedContactsQuery,
    useCreateSavedContactMutation,
    useUpdateSavedContactMutation,
} from '../../../../graphql/gen/hooks'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuickBooksCustomers } from '../../../../hooks/useQuickBooksCustomers'
import { UnmanagedSelect } from '../../../../components/inputs/Select/UnmanagedSelect'
import { CompanySavedContactsQuery } from '../../../../graphql/gen/operations'
import { useIsQuickBooksEnabled } from '../../../../hooks/useIsQuickBooksEnabled'

type Props = {
    existingContact?: CompanySavedContactsQuery['companySavedContacts'][0]
    open: boolean
    onClose: () => void
}
export const EditSavedContactModal: React.FC<Props> = ({ existingContact, open, onClose }) => {
    const { enabled: isQuickBooksEnabled } = useIsQuickBooksEnabled()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [quickBooksCustomerId, setQuickBooksCustomerId] = useState<string | null>(null)
    const allQuickBooksCustomers = useQuickBooksCustomers()
    const { data: alreadySavedContacts } = useCompanySavedContactsQuery()
    const usableQuickBooksCustomers = useMemo(() => {
        const alreadyAssignedQuickBooksCustomerIds = new Set<string>()
        alreadySavedContacts?.companySavedContacts.forEach(contact =>
            alreadyAssignedQuickBooksCustomerIds.add(contact.quickBooksCustomerId),
        )
        return allQuickBooksCustomers.filter(
            customer =>
                customer.Id === existingContact?.quickBooksCustomerId ||
                customer.Id === quickBooksCustomerId ||
                !alreadyAssignedQuickBooksCustomerIds.has(customer.Id),
        )
    }, [allQuickBooksCustomers, alreadySavedContacts?.companySavedContacts, quickBooksCustomerId])
    const selectedCustomer = useMemo(
        () => usableQuickBooksCustomers.find(c => c.Id === quickBooksCustomerId),
        [quickBooksCustomerId, usableQuickBooksCustomers],
    )
    const { enqueueSnackbar } = useSnackbar()
    const { refetch } = useCompanySavedContactsQuery({
        skip: true,
    })
    const [createSavedContact] = useCreateSavedContactMutation()
    const [updateSavedContact] = useUpdateSavedContactMutation()
    const [name, setName] = useState(existingContact?.name)
    const [phoneNumber, setPhoneNumber] = useState<string>()
    const [emailAddress, setEmailAddress] = useState<string>()
    const [notes, setNotes] = useState<string>()
    useEffect(() => {
        if (existingContact) {
            setQuickBooksCustomerId(existingContact?.quickBooksCustomerId)
            setName(existingContact?.name)
            setPhoneNumber(existingContact?.phoneNumber)
            setEmailAddress(existingContact?.emailAddress)
            setNotes(existingContact?.notes)
        }
    }, [existingContact])
    useEffect(() => {
        if (selectedCustomer) {
            setName(selectedCustomer.DisplayName)
            const phone = selectedCustomer.PrimaryPhone?.FreeFormNumber ?? ''
            if (phone) {
                const cleanPhone = phone.replaceAll(/[\(\)-\s]/g, '')
                setPhoneNumber(cleanPhone)
            }
            setEmailAddress(selectedCustomer.PrimaryEmailAddr?.Address)
            setNotes(selectedCustomer.Notes)
        }
    }, [selectedCustomer, setName, setPhoneNumber, setEmailAddress, setNotes])
    const onSubmit = useCallback(async () => {
        setIsSubmitting(true)
        if (existingContact) {
            await updateSavedContact({
                variables: {
                    id: existingContact.id,
                    input: {
                        name,
                        phoneNumber,
                        emailAddress,
                        notes,
                        quickBooksCustomerId,
                    },
                },
            })
            enqueueSnackbar(`Successfully updated ${name}`, {
                variant: 'success',
            })
        } else {
            await createSavedContact({
                variables: {
                    input: {
                        name,
                        phoneNumber,
                        emailAddress,
                        notes,
                        quickBooksCustomerId,
                    },
                },
            })
            enqueueSnackbar('Successfully saved new contact.', {
                variant: 'success',
            })
        }
        await refetch()
        onClose()
        setIsSubmitting(false)
    }, [onClose, refetch, name, phoneNumber, emailAddress, notes, quickBooksCustomerId])
    const isValid = useMemo(() => {
        return Boolean(name)
    }, [name, phoneNumber, emailAddress])
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Add a new contact</Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <UnmanagedSelect
                        placeholder="QuickBooks Customer"
                        value={selectedCustomer?.Id}
                        setValue={c => setQuickBooksCustomerId(c.toString())}
                        items={usableQuickBooksCustomers}
                        disabled={!allQuickBooksCustomers || !isQuickBooksEnabled}
                        getValueFn={c => c.Id}
                        getLabelFn={c => c.DisplayName ?? c.CompanyName}
                        selectProps={{
                            noOptionsMessage: () => {
                                if (allQuickBooksCustomers.length === 0) {
                                    return 'You have no QuickBooks customers.'
                                } else {
                                    return 'All QuickBooks customers have already been assigned. You might need to create a new one on QuickBooks first.'
                                }
                            },
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Contact name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={name}
                        onChange={e => setName(e.currentTarget.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Phone number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.currentTarget.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Email Address"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={emailAddress}
                        onChange={e => setEmailAddress(e.currentTarget.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        multiline
                        label="Notes"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={notes}
                        onChange={e => setNotes(e.currentTarget.value)}
                    />
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<Save />}
                                disabled={!isValid || isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
