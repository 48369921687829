import { useEffect, useState } from 'react'
import { LatLngObj } from '../lib/geo'

export const useGeoLocation = () => {
    const [pos, setPos] = useState<LatLngObj | null>(null)
    useEffect(() => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                pos =>
                    setPos({
                        lat: pos?.coords?.latitude,
                        lng: pos?.coords?.longitude,
                    }),
                err => {
                    console.error('Error while getting geolocation')
                    console.error((err as GeolocationPositionError).message)
                },
            )
        } else {
            console.error(`Browser GeoLocation is not available`)
        }
    }, [setPos])
    return {
        pos,
    }
}
