import { Box, Card, CircularProgress, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { MapboxMap } from '../../../components/map/MapboxMap'
import { MapDirections } from '../../../components/map/MapDirections'
import { MapVehicleMarkers } from '../../../components/map/MapVehicleMarkers'
import { DashboardOverviewAlerts } from '../../../components/views/DashboardOverviewAlerts'
import {
    useDashboardOverviewDataQuery,
    useSupplierMaterialsQuery,
} from '../../../graphql/gen/hooks'
import { DashboardOverviewDataQuery } from '../../../graphql/gen/operations'
import { JobStatus } from '../../../graphql/gen/schemas'
import { useCompany } from '../../../hooks/useCompany'
import { useIsQuickBooksEnabled } from '../../../hooks/useIsQuickBooksEnabled'
import { getCenterOfLocations } from '../../../lib/geo'
import { isMobile } from '../../../lib/navigator'

type LocationObj = DashboardOverviewDataQuery['companyJobs'][0]['origin']

export const DashboardOverviewPage: React.FC = () => {
    const { isTruckingCompany, isSupplierCompany } = useCompany()
    const { data, loading } = useDashboardOverviewDataQuery()
    const activeJobs = useMemo(() => {
        if (data) {
            return data.companyJobs.filter(job => job.status === JobStatus.InProgress)
        }
        return []
    }, [data])
    const allLocations = useMemo(() => {
        const locations: LocationObj[] = []
        activeJobs.forEach(job => {
            locations.push(job.origin)
            locations.push(job.destination)
        })
        return locations
    }, [activeJobs])
    const centerPoint = useMemo(() => {
        return getCenterOfLocations(
            allLocations.map(loc => ({
                lat: loc.latitude,
                lng: loc.longitude,
            })),
        )
    }, [allLocations])
    const savedLocationsCount = useMemo(() => {
        if (!data) {
            return 0
        }
        return data.companySavedLocations.length
    }, [data])
    const vehicleCount = useMemo(() => {
        if (!data) {
            return 0
        }
        return data.getCompany.vehicles.length
    }, [data])
    const { data: supplierMaterialsData } = useSupplierMaterialsQuery()
    const { enabled: isQuickBooksEnabled, loading: loadingQuickBooks } = useIsQuickBooksEnabled()
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: 3,
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={isMobile ? 'column' : 'row'}
            >
                <Typography variant="h5">Overview</Typography>
            </Box>
            <Box display="flex" flexDirection="column" flex="1 1" marginTop={3}>
                {!data || loading ? (
                    <Box display="flex" flex="1 1" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <DashboardOverviewAlerts
                            showNoLocationsAlert={isTruckingCompany && savedLocationsCount === 0}
                            showNoVehiclesAlert={isTruckingCompany && vehicleCount === 0}
                            showNoSupplierMaterialsAlert={
                                isSupplierCompany && !supplierMaterialsData?.supplierMaterials
                            }
                            showQuickBooksAlert={!isQuickBooksEnabled && !loadingQuickBooks}
                        />
                        <Box marginTop={2} display="flex" flex="1 1" minHeight={300}>
                            <MapboxMap defaultLocation={centerPoint}>
                                <MapVehicleMarkers />
                                {activeJobs.map(job => (
                                    <MapDirections
                                        key={job.id}
                                        originId={job.origin.id}
                                        destinationId={job.destination.id}
                                        color={
                                            '#' +
                                            Math.floor(Math.random() * 16777215)
                                                .toString(16)
                                                .padStart(6, '0')
                                        }
                                    />
                                ))}
                            </MapboxMap>
                        </Box>
                    </>
                )}
            </Box>
        </Card>
    )
}
