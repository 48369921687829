import styled from '@emotion/styled'
import { Box, Breakpoint, Card, Container } from '@mui/material'
import { forwardRef, useState } from 'react'
import { ChildrenProp } from '../lib/componentProps'

const ModalRoot = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: var(--app-height);
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SimpleModal: React.FC<
    ChildrenProp & {
        onClickOutside?: () => void
        width?: Breakpoint
    }
> = forwardRef(({ children, onClickOutside, width = 'sm' }, ref) => {
    const [isClickingInside, setIsClickingInside] = useState(false)
    return (
        <ModalRoot
            ref={ref}
            tabIndex={1}
            onClick={e => {
                if (isClickingInside) {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsClickingInside(false)
                } else {
                    onClickOutside()
                }
            }}
        >
            <Container
                maxWidth={width}
                onMouseDown={e => {
                    setIsClickingInside(true)
                }}
                onMouseUp={e => {
                    setIsClickingInside(false)
                }}
            >
                <Card
                    onClick={e => {
                        e.stopPropagation()
                    }}
                    sx={{
                        maxHeight: '90vh',
                        overflow: 'scroll',
                        padding: 3,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Card>
            </Container>
        </ModalRoot>
    )
})
