import { LngLatPair } from '../lib/geo'
import { useEffect, useState } from 'react'
import { runAsync } from '../lib/async'

export type MapboxDirectionsResponseRouteObject = {
    duration: number
    distance: number
    geometry: {
        coordinates: LngLatPair[]
        type: string
    }
}

export const useDirectionsData = (loc1Id: number, loc2Id: number) => {
    const [data, setData] = useState<MapboxDirectionsResponseRouteObject | null>(null)
    useEffect(() => {
        runAsync(async () => {
            const resp = await fetch(`/api/geo/directions/${loc1Id}/${loc2Id}`)
            const data = (await resp.json()) as MapboxDirectionsResponseRouteObject
            setData(data)
        })
    }, [loc1Id, loc2Id])
    return data
}
