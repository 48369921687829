import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useAuthState } from 'react-firebase-hooks/auth'
import { firebaseAuth } from '../../../connections/firebase'

export const TokenPage: React.FC = () => {
    const [firebaseUser] = useAuthState(firebaseAuth)
    const [token, setToken] = useState<string | undefined>()
    useEffect(() => {
        firebaseUser?.getIdToken().then(token => {
            setToken(token)
        })
    }, [firebaseUser])
    return (
        <div>
            {!firebaseUser && <div>You are not signed in!</div>}
            {token && (
                <div>
                    <Button
                        onClick={() =>
                            window.navigator?.clipboard?.writeText(token)
                        }
                    >
                        Copy to clipboard
                    </Button>
                </div>
            )}
            <pre>{JSON.stringify(firebaseUser, undefined, 4)}</pre>
        </div>
    )
}
