import { FormControlLabel, Switch } from '@mui/material'
import { useCallback } from 'react'
import { useThemeMode } from '../providers/ThemeProvider'

export const DarkModeControls = () => {
    const { isDarkMode, toggleMode } = useThemeMode()
    const toggleCallback = useCallback(toggleMode, [toggleMode])
    return (
        <FormControlLabel
            control={<Switch checked={isDarkMode} onChange={toggleCallback} />}
            label="Dark Mode"
        />
    )
}
