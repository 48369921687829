import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AuthLayout } from '../components/layouts/AuthLayout'
import { NotFound404View } from '../components/NotFound404'
import { SignOutPage } from './pages/SignOutPage'
import { SignInPage } from './pages/signin/SignInPage'
import { SignInSuccessPage } from './pages/signin/SignInSuccessPage'
import { TokenPage } from './pages/debug/TokenPage'
import { DashboardLayout } from '../components/layouts/DashboardLayout'
import { CompanyProvider } from '../components/layouts/CompanyProvider'
import { SignInWithPhonePage } from './pages/signin/SignInWithPhonePage'
import { SignInWithPhoneConfirmationPage } from './pages/signin/SignInWithPhoneConfirmationPage'
import { SignUpWithEmailAndPasswordPage } from './pages/signin/SignUpWithEmailAndPasswordPage'
import { SignInAddInfoPage } from './pages/signin/SignInAddInfoPage'
import { CreateCompanyPage } from './pages/CreateCompanyPage'
import { DashboardVehiclesPage } from './pages/dashboard/vehicles/DashboardVehiclesPage'
import { DashboardContactsPage } from './pages/dashboard/contacts/DashboardContactsPage'
import { DashboardLocationsPage } from './pages/dashboard/location/DashboardLocationsPage'
import { DashboardJobsPage } from './pages/dashboard/jobs/DashboardJobsPage'
import { JobProvider } from '../components/layouts/JobProvider'
import { JobPageLayout } from '../components/layouts/JobPageLayout'
import { JobOverviewPage } from './pages/dashboard/job/JobOverviewPage'
import { JobEmployeesPage } from './pages/dashboard/job/jobEmployees/JobEmployeesPage'
import { JobTicketsPage } from './pages/dashboard/job/jobTickets/JobTicketsPage'
import { ConsoleLayout } from '../components/layouts/ConsoleLayout'
import { UploadTicketPage } from './pages/console/UploadTicketPage'
import { ConsoleTicketsPage } from './pages/console/ConsoleTicketsPage'
import { ConsoleJobsPage } from './pages/console/jobs/ConsoleJobsPage'
import { ConsoleJobPage } from './pages/console/jobs/ConsoleJobPage'
import { DashboardOverviewPage } from './pages/dashboard/DashboardOverviewPage'
import { MonitorPage } from './pages/monitor/MonitorPage'
import { DashboardTicketsPage } from './pages/dashboard/DashboardTicketsPage'
import { ConsoleEarningsPage } from './pages/console/ConsoleEarningsPage'
import { JobMaterialsPage } from './pages/dashboard/job/jobMaterials/JobMaterialsPage'
import { EmployeeProvider } from '../components/layouts/EmployeeProvider'
import { EmployeePageLayout } from '../components/layouts/EmployeePageLayout'
import { EmployeeTicketsPage } from './pages/dashboard/employee/EmployeeTicketsPage'
import { EmployeeOverviewPage } from './pages/dashboard/employee/EmployeeOverviewPage'
import { JobInvoicePage } from './pages/dashboard/job/jobInvoice/JobInvoicePage'
import { HomePage } from './pages/HomePage'
import { DashboardIntegrationsPage } from './pages/dashboard/integrations/DashboardIntegrationsPage'
import { IntuitOauthCallbackPage } from './pages/callback/IntuitOauthCallbackPage'
import { LegalLayout } from '../components/layouts/LegalLayout'
import { LegalPolicyFrameView } from '../components/LegalPolicyFrameView'
import { POLICY_LINKS } from '../lib/constants/legalLinks'
import { GenericLayout } from '../components/layouts/GenericLayout'
import { DashboardBillingPage } from './pages/dashboard/DashboardBillingPage'
import { DashboardMaterialsPage } from './pages/dashboard/materials/DashboardMaterialsPage'
import { SupplierUploadTicketPage } from './pages/console/SupplierUploadTicketPage'
import { ConsoleSupplierTicketsPage } from './pages/console/ConsoleSupplierTicketsPage'
import { DashboardInvoicesPage } from './pages/dashboard/invoices/DashboardInvoicesPage'
import { InvoiceProvider } from '../components/layouts/InvoiceProvider'
import { InvoicePageLayout } from '../components/layouts/InvoicePageLayout'
import { InvoiceTicketsPage } from './pages/dashboard/invoice/InvoiceTicketsPage'
import { InvoiceOverviewPage } from './pages/dashboard/invoice/InvoiceOverviewPage'
import { ConsoleShiftsPage } from './pages/console/ConsoleShiftsPage'
import { ConsoleIndexNavigatorPage } from './pages/console/ConsoleIndexNavigatorPage'
import { DashboardEmployeesPageLayout } from '../components/layouts/DashboardEmployeesPageLayout'
import { DashboardEmployeesListPage } from './pages/dashboard/employees/DashboardEmployeesListPage'
import { DashboardEmployeesShiftsPage } from './pages/dashboard/employees/DashboardEmployeesShiftsPage'

export const AppRouter: React.FC = () => {
    const location = useLocation()
    return (
        <Routes>
            <Route index element={<HomePage />} />
            <Route path="legal" element={<LegalLayout />}>
                <Route index element={<Navigate to="/legal/terms" />} />
                <Route
                    path="terms"
                    element={<LegalPolicyFrameView uri={POLICY_LINKS.TERMS_AND_CONDITIONS} />}
                />
                <Route
                    path="privacy"
                    element={<LegalPolicyFrameView uri={POLICY_LINKS.PRIVACY_POLICY} />}
                />
                <Route path="eula" element={<LegalPolicyFrameView uri={POLICY_LINKS.EULA} />} />
                <Route
                    path="cookies"
                    element={<LegalPolicyFrameView uri={POLICY_LINKS.COOKIE_POLICY} />}
                />
            </Route>
            <Route
                path="monitor/:monitorId"
                element={
                    <GenericLayout>
                        <MonitorPage />
                    </GenericLayout>
                }
            />
            <Route
                path="console"
                element={
                    <CompanyProvider>
                        <ConsoleLayout />
                    </CompanyProvider>
                }
            >
                <Route index element={<ConsoleIndexNavigatorPage />} />
                <Route path="upload" element={<UploadTicketPage />} />
                <Route path="shifts" element={<ConsoleShiftsPage />} />
                <Route path="supplier_upload" element={<SupplierUploadTicketPage />} />
                <Route path="jobs" element={<ConsoleJobsPage />} />
                <Route path="tickets" element={<ConsoleTicketsPage />} />
                <Route path="supplier_tickets" element={<ConsoleSupplierTicketsPage />} />
                <Route path="contacts" element={<DashboardContactsPage />} />
                <Route
                    path="job/:jobId"
                    element={
                        <JobProvider>
                            <ConsoleJobPage />
                        </JobProvider>
                    }
                />
                <Route path="earnings" element={<ConsoleEarningsPage />} />
            </Route>
            <Route
                path="dashboard"
                element={
                    <CompanyProvider>
                        <DashboardLayout />
                    </CompanyProvider>
                }
            >
                <Route index element={<DashboardOverviewPage />} />
                <Route path="invoices" element={<DashboardInvoicesPage />} />
                <Route path="invoice">
                    <Route index element={<Navigate replace to="/dashboard/invoices" />} />
                    <Route
                        path=":invoiceId"
                        element={
                            <InvoiceProvider>
                                <InvoicePageLayout />
                            </InvoiceProvider>
                        }
                    >
                        <Route index element={<Navigate replace to="overview" />} />
                        <Route path="overview" element={<InvoiceOverviewPage />} />
                        <Route path="tickets" element={<InvoiceTicketsPage />} />
                    </Route>
                </Route>
                <Route path="tickets" element={<DashboardTicketsPage />} />
                <Route path="materials" element={<DashboardMaterialsPage />} />
                <Route path="jobs" element={<DashboardJobsPage />} />
                <Route path="job">
                    <Route index element={<Navigate replace to="/dashboard/jobs" />} />
                    <Route
                        path=":jobId"
                        element={
                            <JobProvider>
                                <JobPageLayout />
                            </JobProvider>
                        }
                    >
                        <Route index element={<Navigate replace to="overview" />} />
                        <Route path="overview" element={<JobOverviewPage />} />
                        <Route path="materials" element={<JobMaterialsPage />} />
                        <Route path="employees" element={<JobEmployeesPage />} />
                        <Route path="tickets" element={<JobTicketsPage />} />
                        <Route path="invoice" element={<JobInvoicePage />} />
                    </Route>
                </Route>
                <Route path="vehicles" element={<DashboardVehiclesPage />} />
                <Route path="employees" element={<DashboardEmployeesPageLayout />}>
                    <Route index element={<Navigate replace to="/dashboard/employees/list" />} />
                    <Route path="list" element={<DashboardEmployeesListPage />} />
                    <Route path="shifts" element={<DashboardEmployeesShiftsPage />} />
                </Route>
                <Route path="employee">
                    <Route index element={<Navigate replace to="/dashboard/employees" />} />
                    <Route
                        path=":employeeId"
                        element={
                            <EmployeeProvider>
                                <EmployeePageLayout />
                            </EmployeeProvider>
                        }
                    >
                        <Route index element={<Navigate replace to="overview" />} />
                        <Route path="overview" element={<EmployeeOverviewPage />} />
                        <Route path="tickets" element={<EmployeeTicketsPage />} />
                    </Route>
                </Route>
                <Route path="contacts" element={<DashboardContactsPage />} />
                <Route path="locations" element={<DashboardLocationsPage />} />
                <Route path="integrations" element={<DashboardIntegrationsPage />} />
                <Route path="billing" element={<DashboardBillingPage />} />
            </Route>
            <Route path="callback" element={<AuthLayout />}>
                <Route path="intuit_oauth" element={<IntuitOauthCallbackPage />} />
            </Route>
            <Route element={<AuthLayout />}>
                <Route path="signin">
                    <Route index element={<SignInPage />} />
                    <Route path="success" element={<SignInSuccessPage />} />
                    <Route path="phone" element={<SignInWithPhonePage />} />
                    <Route path="add_info" element={<SignInAddInfoPage />} />
                    <Route path="phone/confirm" element={<SignInWithPhoneConfirmationPage />} />
                </Route>
                <Route path="signup" element={<SignUpWithEmailAndPasswordPage />} />
                <Route path="signout" element={<SignOutPage />} />
                <Route path="create" element={<CreateCompanyPage />} />
            </Route>
            <Route
                path="*"
                element={
                    <NotFound404View
                        message={`The path "${location.pathname}" does not exist...`}
                    />
                }
            />
            <Route path="__debug">
                <Route path="token" element={<TokenPage />} />
            </Route>
        </Routes>
    )
}
