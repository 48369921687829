import { JobStatus } from '../graphql/gen/schemas'

export const convertJobStatusIntoSortOrder = (status: JobStatus) => {
    if (status === JobStatus.Pending) {
        return -1
    } else if (status === JobStatus.InProgress) {
        return 0
    } else if (status === JobStatus.Done) {
        return 100000
    }
    return -100000
}
