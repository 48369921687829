import { Cancel, Create } from '@mui/icons-material'
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material'
import { SimpleModal } from '../../../../components/SimpleModal'
import { useCreateInvoiceMutation } from '../../../../graphql/gen/hooks'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelectController } from '../../../../hooks/useSelectController'
import { useContacts } from '../../../../hooks/useContacts'
import { UnmanagedSelect } from '../../../../components/inputs/Select/UnmanagedSelect'

type Props = {
    open: boolean
    onClose: () => void
    refetch: () => any
    existingCount: number
}

const getDefaultInvoiceName = (num: number) => `Invoice #${num + 1}`

export const CreateInvoiceModal: React.FC<Props> = ({ open, onClose, refetch, existingCount }) => {
    const { enqueueSnackbar } = useSnackbar()
    const [createInvoice, { loading }] = useCreateInvoiceMutation()
    const [name, setName] = useState('')
    useEffect(() => {
        setName(getDefaultInvoiceName(existingCount))
    }, [existingCount, setName])
    const { contacts } = useContacts()
    const { selectedId: customerId, setId: setCustomerId } = useSelectController(
        contacts,
        c => c.id,
    )
    const onSubmit = useCallback(async () => {
        await createInvoice({
            variables: {
                name,
                customerId,
            },
        })
        onClose()
        enqueueSnackbar('Created new invoice', {
            variant: 'success',
        })
        refetch()
    }, [name, customerId])
    const isValid = useMemo(() => {
        return Boolean(name) && Boolean(customerId)
    }, [name, customerId])
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Create New Invoice</Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <Box marginBottom={2}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Invoice Name"
                            value={name}
                            onChange={e => setName(e.currentTarget.value)}
                        />
                    </Box>
                    <UnmanagedSelect
                        placeholder="Customer"
                        value={customerId}
                        setValue={c => setCustomerId(c)}
                        items={contacts}
                        getValueFn={c => c.id}
                        getLabelFn={c => c.name}
                    />
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                startIcon={<Create />}
                                disabled={!isValid || loading}
                                onClick={onSubmit}
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
