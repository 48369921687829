import { useTheme } from '@mui/material'
import { useCallback, useMemo } from 'react'
import Select, { defaultTheme, Props as SelectProps } from 'react-select'

export type SelectValueType = string | number

export type SelectOption<E> = {
    value: E
    label: string
}

export type BaseSelectProps<E, Y> = {
    name?: string
    placeholder?: string
    disabled?: boolean
    specialOptions?: SelectOption<Y>[]
    items: E[]
    getValueFn: (item: E) => Y
    getLabelFn: (item: E) => string
    selectProps?: SelectProps
}

export type UnmanagedSelectProps<E, Y> = BaseSelectProps<E, Y> & {
    ref?: any
    value: Y
    setValue: (value: Y) => any
}

export const SPECIAL_OPTIONS: Record<string, SelectOption<string>> = {
    NONE: {
        value: 'none',
        label: 'None',
    },
}

export const SPECIAL_OPTIONS_NUM: Record<string, SelectOption<number>> = {
    NONE: {
        value: -1,
        label: 'None',
    },
}

export function UnmanagedSelect<E, Y>({
    ref,
    name,
    disabled,
    placeholder = 'Select',
    specialOptions = [],
    items,
    getValueFn,
    getLabelFn,
    value,
    setValue,
    selectProps,
}: UnmanagedSelectProps<E, Y>) {
    const theme = useTheme()
    const colors = theme.palette
    const currentItem = useMemo(
        () => items.find(i => getValueFn(i) === value),
        [items, value, getValueFn],
    )
    const convertItemIntoOption = useCallback(
        (item: E) => ({
            value: getValueFn(item),
            label: getLabelFn(item),
        }),
        [getValueFn, getLabelFn],
    )
    const options = useMemo(
        () => [...specialOptions, ...items.map(item => convertItemIntoOption(item))],
        [specialOptions, items, convertItemIntoOption],
    )
    return (
        <Select
            ref={ref}
            name={name}
            placeholder={placeholder}
            isDisabled={disabled}
            options={options}
            value={currentItem ? convertItemIntoOption(currentItem) : null}
            onChange={(opt: SelectOption<Y>) => setValue(opt.value)}
            menuPortalTarget={document.body}
            theme={{
                colors: {
                    danger: colors.error.main,
                    dangerLight:
                        theme.palette.mode === 'dark' ? colors.error.dark : colors.error.light,
                    primary: colors.primary.main,
                    primary75: colors.primary.main,
                    primary50:
                        theme.palette.mode === 'dark' ? colors.primary.dark : colors.primary.light,
                    primary25:
                        theme.palette.mode === 'dark' ? colors.primary.dark : colors.primary.light,
                    neutral0:
                        theme.palette.mode === 'dark'
                            ? colors.grey[900]
                            : colors.background.default,
                    neutral5: colors.grey[theme.palette.mode === 'dark' ? 900 : 100],
                    neutral10: colors.grey[theme.palette.mode === 'dark' ? 800 : 200],
                    neutral20: colors.grey[theme.palette.mode === 'dark' ? 700 : 300],
                    neutral30: colors.grey[theme.palette.mode === 'dark' ? 600 : 400],
                    neutral40: colors.grey[theme.palette.mode === 'dark' ? 500 : 500],
                    neutral50: colors.grey[theme.palette.mode === 'dark' ? 400 : 600],
                    neutral60: colors.grey[theme.palette.mode === 'dark' ? 300 : 700],
                    neutral70: colors.grey[theme.palette.mode === 'dark' ? 200 : 800],
                    neutral80: colors.grey[theme.palette.mode === 'dark' ? 100 : 900],
                    neutral90: colors.grey[theme.palette.mode === 'dark' ? 50 : 900],
                },
                borderRadius: defaultTheme.borderRadius,
                spacing: defaultTheme.spacing,
            }}
            styles={{
                container: prev => ({ ...prev, width: '100%' }),
                menu: prev => ({ ...prev, zIndex: 2000 }),
                menuPortal: prev => ({ ...prev, zIndex: 9999 }),
                control: prev => ({
                    ...prev,
                    padding: 10,
                    borderColor: colors.grey[theme.palette.mode === 'dark' ? 800 : 400],
                }),
            }}
            {...selectProps}
        />
    )
}
