export type LatLngObj = {
    lat: number
    lng: number
}

export const DEFAULT_MAP_LOCATION: LatLngObj = {
    lat: 28.538336,
    lng: -81.379234,
}

export type GeocoderResultContextItemObject = {
    id: string
    text: string
}

export type GeocoderResultObject = {
    result: {
        address: string
        center: [number, number]
        place_name: string
        text: string
        context: GeocoderResultContextItemObject[]
    }
}

export type LocationAddressObject = {
    address: string
    city: string
    state: string
    zip: string
    lat: number
    lng: number
    name: string
}

export const deriveLocationContextFromGeocoderResult = (result: GeocoderResultObject) => {
    const obj = result.result
    const address = [obj.address, obj.text].join(' ')
    const loc: LocationAddressObject = {
        address: address ?? '',
        name: address ?? '',
        city: obj.context.find(ctx => ctx.id.startsWith('place.'))?.text ?? '',
        state: obj.context.find(ctx => ctx.id.startsWith('region.'))?.text ?? '',
        zip: obj.context.find(ctx => ctx.id.startsWith('postcode.'))?.text ?? '',
        lat: result.result.center[1],
        lng: result.result.center[0],
    }
    return loc
}

export const getCenterOfLocations = (locations: LatLngObj[]): LatLngObj => {
    if (locations.length === 0) {
        return DEFAULT_MAP_LOCATION
    }
    let totalLat = 0
    let totalLng = 0
    for (const loc of locations) {
        totalLat += loc.lat
        totalLng += loc.lng
    }
    return {
        lat: totalLat / locations.length,
        lng: totalLng / locations.length,
    }
}

type SavedLocationObject = {
    latitude: number
    longitude: number
}

export const convertLocationToLatLng = (loc: SavedLocationObject): LatLngObj => ({
    lat: loc.latitude,
    lng: loc.longitude,
})

export type LngLatPair = [number, number]

export const convertLocationToLngLatPair = (loc: SavedLocationObject): LngLatPair => [
    loc.longitude,
    loc.latitude,
]

export const convertLatLngObjectToLngLatPair = (obj: LatLngObj): LngLatPair => [obj.lng, obj.lat]

export const generateDirectionsLink = (loc1: string, loc2: string) => {
    return `https://www.google.com/maps/dir/?api=1&origin=${loc1}&destination=${loc2}`
}

export const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180

export const distanceInKmBetweenGeoPoints = (p1: LatLngObj, p2: LatLngObj) => {
    const earthRadiusKm = 6371

    const dLat = degreesToRadians(p2.lat - p1.lat)
    const dLon = degreesToRadians(p2.lng - p1.lng)

    const lat1 = degreesToRadians(p1.lat)
    const lat2 = degreesToRadians(p2.lat)

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return earthRadiusKm * c
}
