import { appConfig } from '../appConfig'
import { initializeApp, getApps } from 'firebase/app'
import {
    getAuth,
    connectAuthEmulator,
    indexedDBLocalPersistence,
    setPersistence,
    useDeviceLanguage,
} from 'firebase/auth'
import { LogRocket } from './logrocket'
import * as Sentry from '@sentry/react'

const existingApps = getApps()

const firebaseApp =
    existingApps.length > 0
        ? existingApps[0]
        : initializeApp(
              {
                  apiKey: appConfig.firebase.apiKey,
                  authDomain: appConfig.firebase.authDomain,
                  projectId: appConfig.firebase.projectId,
                  appId: appConfig.firebase.appId,
              },
              'FleetMage',
          )

const firebaseAuth = getAuth(firebaseApp)

firebaseAuth.onAuthStateChanged(user => {
    if (user) {
        LogRocket.identify(user.uid, {
            name: user.displayName,
            email: user.email,
            phone: user.phoneNumber,
        })
        Sentry.setUser({
            id: user.uid,
            username: user.displayName,
            email: user.email,
        })
    }
})

setPersistence(firebaseAuth, indexedDBLocalPersistence)
useDeviceLanguage(firebaseAuth)

if (appConfig.firebase.emulatorUrl) {
    connectAuthEmulator(firebaseAuth, appConfig.firebase.emulatorUrl, {
        disableWarnings: true,
    })
}

export { firebaseApp, firebaseAuth }
