import { ExpandMore } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import moment from 'moment'
import { useMemo } from 'react'
import { CompanyEmployeeQuery } from '../../graphql/gen/operations'
import { useEmployee } from '../../hooks/useEmployee'
import { useWhoAmI } from '../../hooks/useWhoAmI'
import { mergeArrays } from '../../lib/arrayLogic'
import { calcLogic } from '../../lib/calcLogic'
import { groupBy } from '../../lib/groupBy'
import { normalizeShift } from '../../lib/shiftLogic'

type Props = {
    shifts: CompanyEmployeeQuery['companyEmployee']['pastShifts']
}

export const EmployeeShiftsList: React.FC<Props> = ({ shifts }) => {
    const { whoAmI } = useWhoAmI()
    const normalizedShifts = useMemo(
        () => mergeArrays(shifts.map(shift => normalizeShift(shift, whoAmI.employeeAccount!))),
        [shifts],
    )
    const groupedShifts = useMemo(() => groupBy(normalizedShifts, shift => shift.dt), [
        normalizeShift,
    ])
    const sortedDtKeys = useMemo(() => Object.keys(groupedShifts).sort().reverse(), [groupedShifts])
    return (
        <>
            {sortedDtKeys.map(dtStr => {
                const dt = moment(dtStr)
                const shifts = groupedShifts[dtStr].sort((a, b) => (a.end.isAfter(b.end) ? -1 : 1))
                const totalWork = shifts.reduce((total, cur) => total + cur.totalMins, 0)
                return (
                    <Accordion key={dtStr}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography fontWeight="bold" marginRight={4}>
                                {dt.format('ddd MMM Do')}
                            </Typography>
                            <Typography>{(totalWork / 60).toFixed(1)} hours worked</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Start</TableCell>
                                        <TableCell>End</TableCell>
                                        <TableCell>Time</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shifts.map(shift => {
                                        const diffStr = calcLogic(() => {
                                            const minsStr = `${shift.diffMins}m`
                                            if (shift.diffHrs > 0) {
                                                return `${shift.diffHrs}h ${minsStr}`
                                            }
                                            return minsStr
                                        })
                                        return (
                                            <TableRow key={shift.start.format()}>
                                                <TableCell>
                                                    <Typography>
                                                        {shift.start.format('ddd MMM Do')}
                                                    </Typography>
                                                    <Typography>
                                                        {shift.start.format('h:mm A')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {shift.end.format('ddd MMM Do')}
                                                    </Typography>
                                                    <Typography>
                                                        {shift.end.format('h:mm A')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    {shift.ongoing
                                                        ? `On-Going - ${diffStr}`
                                                        : diffStr}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </>
    )
}
