import { Cancel, Save } from '@mui/icons-material'
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { SimpleModal } from '../../../../components/SimpleModal'
import { useCreateVehicleMutation } from '../../../../graphql/gen/hooks'
import { useCompany } from '../../../../hooks/useCompany'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'

type Props = {
    open: boolean
    onClose: () => void
}

const formSchema = yup.object().shape({
    vehicleId: yup.string().required('Vehicle ID is required'),
    description: yup.string(),
})

export const AddVehicleModal: React.FC<Props> = ({ open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { refetch } = useCompany()
    const [createVehicle] = useCreateVehicleMutation()
    const { handleSubmit, register, formState } = useForm<{
        vehicleId: string
        description: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
    })
    const formErrors = formState.errors
    const onSubmit = handleSubmit(async data => {
        await createVehicle({
            variables: {
                vehicleId: data.vehicleId,
                description: data.description,
            },
        })
        await refetch()
        onClose()
        enqueueSnackbar('Successfully saved new vehicle.', {
            variant: 'success',
        })
    })
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">Add new vehicle</Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Vehicle ID (Plate, VIN, Code, etc)"
                        autoComplete="vehicleId"
                        error={Boolean(formErrors.vehicleId)}
                        helperText={
                            formErrors.vehicleId ? (
                                <Typography>{formErrors.vehicleId.message}</Typography>
                            ) : (
                                'This can be any text that will help you uniquely identify this vehicle.'
                            )
                        }
                        {...register('vehicleId')}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Description (Optional)"
                        autoComplete="description"
                        error={Boolean(formErrors.description)}
                        helperText={
                            formErrors.description ? (
                                <Typography>{formErrors.description.message}</Typography>
                            ) : null
                        }
                        {...register('description')}
                    />
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<Save />}
                                disabled={!formState.isValid || formState.isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
