import './globalImports'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { AppRoot } from './AppRoot'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { appConfig } from './appConfig'
import { LogRocket } from './connections/logrocket'

if (appConfig.isProduction) {
    Sentry.init({
        dsn: appConfig.sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        debug: !appConfig.isProduction,
    })
    LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
            scope.setExtra('sessionURL', sessionURL)
        })
    })
}

const setAppHeightConstant = () => {
    window.document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
}

setAppHeightConstant()

window.addEventListener('resize', setAppHeightConstant)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<AppRoot />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
