import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Grid,
    Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { formatMaterialAmountPhrase } from '../../../lib/formatting'
import { isMobile } from '../../../lib/navigator'
import { TicketModal } from '../../../components/ticket/TicketModal'
import { groupBy } from '../../../lib/groupBy'
import { ExpandMore } from '@mui/icons-material'
import { useFilteredTickets } from '../../../hooks/useFilteredTickets'
import { LoadingView } from '../../../components/layouts/LoadingView'

export const ConsoleSupplierTicketsPage: React.FC = () => {
    const { tickets, refetch, loading } = useFilteredTickets({
        startDt: moment().utc().subtract(1, 'month').toString(),
    })
    const ticketsByDate = useMemo(
        () => groupBy(tickets, ticket => moment(ticket.timestamp).format('YYYY-MM-DD')),
        [tickets],
    )
    const orderedDtKeys = useMemo(
        () => Object.keys(ticketsByDate).sort().reverse(),
        [ticketsByDate],
    )
    const [viewingTicketId, setViewingTicketId] = useState<number | null>(null)
    const closeModal = useCallback(() => {
        setViewingTicketId(null)
    }, [setViewingTicketId])
    const viewingTicket = useMemo(() => {
        if (viewingTicketId != null) {
            return tickets.find(ticket => ticket.id === viewingTicketId)
        }
    }, [viewingTicketId, tickets])
    return (
        <>
            {viewingTicket && (
                <TicketModal
                    onClose={closeModal}
                    open={typeof viewingTicketId === 'number'}
                    ticketId={viewingTicket.id}
                    refetch={refetch}
                />
            )}
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                    <Typography variant="h5">My Tickets</Typography>
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1} marginTop={3}>
                    {loading && <LoadingView />}
                    {orderedDtKeys.map(dtStr => {
                        const dt = moment(dtStr)
                        const tickets = [...ticketsByDate[dtStr]].sort((a, b) => {
                            if (a.timestamp < b.timestamp) {
                                return -1
                            } else {
                                return 1
                            }
                        })
                        return (
                            <Accordion defaultExpanded key={dtStr} disableGutters elevation={0}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6">
                                                {dt.format('dddd MMM Do')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {tickets.length} ticket{tickets.length > 1 ? 's' : ''}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box display="flex" flexGrow={1}>
                                        <DataGrid
                                            rows={tickets}
                                            autoHeight
                                            disableSelectionOnClick
                                            hideFooter
                                            columns={[
                                                {
                                                    field: 'timestamp',
                                                    headerName: 'Timestamp',
                                                    minWidth: 150,
                                                    valueGetter: params =>
                                                        moment(params.row.timestamp).format(
                                                            'YYYY-MM-DD-HH-mm',
                                                        ),
                                                    renderCell: params => (
                                                        <Typography>
                                                            {moment(params.row.timestamp).format(
                                                                'h:mm A',
                                                            )}
                                                        </Typography>
                                                    ),
                                                },
                                                {
                                                    field: 'identifier',
                                                    headerName: 'Code',
                                                    minWidth: 150,
                                                    valueGetter: params => params.row.identifier,
                                                },
                                                {
                                                    field: 'transporter',
                                                    headerName: 'Transporter',
                                                    minWidth: 150,
                                                    flex: 1,
                                                    valueGetter: params =>
                                                        params.row.transporter?.name ?? '-',
                                                },
                                                {
                                                    field: 'customer',
                                                    headerName: 'Customer',
                                                    minWidth: 150,
                                                    flex: 1,
                                                    valueGetter: params =>
                                                        params.row.customer?.name ?? '-',
                                                },
                                                {
                                                    field: 'material',
                                                    headerName: 'Material',
                                                    minWidth: 200,
                                                    valueGetter: params => {
                                                        const m = params.row.material
                                                        return formatMaterialAmountPhrase(
                                                            m.materialName,
                                                            m.unitSingular,
                                                            m.unitPlural,
                                                            params.row.materialAmount,
                                                        )
                                                    },
                                                },
                                                {
                                                    field: 'viewBtn',
                                                    headerName: '',
                                                    disableExport: true,
                                                    minWidth: 150,
                                                    renderCell: params => {
                                                        return (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() =>
                                                                    setViewingTicketId(
                                                                        params.row.id,
                                                                    )
                                                                }
                                                            >
                                                                View
                                                            </Button>
                                                        )
                                                    },
                                                },
                                            ]}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Box>
            </Card>
        </>
    )
}
