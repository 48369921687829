import { useContext } from 'react'
import { MapContext } from '../components/map/MapboxMap'

export const useMapboxMap = () => {
    const map = useContext(MapContext)
    if (!map) {
        throw new Error('You are calling useMap outside of <MapboxMap />.')
    }
    return map
}
