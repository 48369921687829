import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Grid,
    Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useMyTicketsQuery } from '../../../graphql/gen/hooks'
import { formatMaterialAmountPhrase, usdFormatter } from '../../../lib/formatting'
import { isMobile } from '../../../lib/navigator'
import { TicketModal } from '../../../components/ticket/TicketModal'
import { groupBy } from '../../../lib/groupBy'
import { MyTicketsQuery } from '../../../graphql/gen/operations'
import { ExpandMore } from '@mui/icons-material'

export const ConsoleTicketsPage: React.FC = () => {
    const { data, refetch } = useMyTicketsQuery()
    const tickets = useMemo(() => {
        if (data?.whoAmI?.employeeAccount?.tickets) {
            return [...data.whoAmI.employeeAccount.tickets].sort((a, b) => {
                if (a.timestamp > b.timestamp) {
                    return -1
                } else if (a.timestamp < b.timestamp) {
                    return 1
                }
                return 0
            })
        }
        return []
    }, [data])
    const ticketsByDate = useMemo(
        () => groupBy(tickets, ticket => moment(ticket.timestamp).format('YYYY-MM-DD')),
        [tickets],
    )
    const orderedDtKeys = useMemo(
        () => Object.keys(ticketsByDate).sort().reverse(),
        [ticketsByDate],
    )
    const calculateEarnings = useCallback(
        (tickets: MyTicketsQuery['whoAmI']['employeeAccount']['tickets']) => {
            return tickets.map(ticket => ticket.employeeEarnings).reduce((sum, cur) => sum + cur, 0)
        },
        [],
    )
    const [viewingTicketId, setViewingTicketId] = useState<number | null>(null)
    const closeModal = useCallback(() => {
        setViewingTicketId(null)
    }, [setViewingTicketId])
    const viewingTicket = useMemo(() => {
        if (viewingTicketId != null) {
            return tickets.find(ticket => ticket.id === viewingTicketId)
        }
    }, [viewingTicketId, tickets])
    return (
        <>
            {viewingTicket && (
                <TicketModal
                    onClose={closeModal}
                    open={typeof viewingTicketId === 'number'}
                    ticketId={viewingTicket.id}
                    refetch={refetch}
                />
            )}
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                }}
            >
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                    <Typography variant="h5">My Tickets</Typography>
                </Box>
                <Box display="flex" flexDirection="column" flexGrow={1} marginTop={3}>
                    {orderedDtKeys.map(dtStr => {
                        const dt = moment(dtStr)
                        const tickets = [...ticketsByDate[dtStr]].sort((a, b) => {
                            if (a.job?.name < b.job?.name) {
                                return -1
                            } else if (a.job?.name > b.job?.name) {
                                return 1
                            }
                            return 0
                        })
                        const dayEarnings = calculateEarnings(tickets)
                        return (
                            <Accordion defaultExpanded key={dtStr} disableGutters elevation={0}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Grid container>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="h6">
                                                {dt.format('dddd MMM Do')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {tickets.length} ticket{tickets.length > 1 ? 's' : ''}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            {usdFormatter.format(dayEarnings)} in earnings
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box display="flex" flexGrow={1}>
                                        <DataGrid
                                            rows={tickets}
                                            autoHeight
                                            disableSelectionOnClick
                                            hideFooter
                                            columns={[
                                                {
                                                    field: 'identifier',
                                                    headerName: 'Code',
                                                    minWidth: 150,
                                                    valueGetter: params => params.row.identifier,
                                                },
                                                {
                                                    field: 'job',
                                                    headerName: 'Job',
                                                    minWidth: 200,
                                                    valueGetter: params => params.row.job?.name,
                                                },
                                                {
                                                    field: 'timestamp',
                                                    headerName: 'Timestamp',
                                                    minWidth: 200,
                                                    valueGetter: params =>
                                                        moment(params.row.timestamp).format(
                                                            'YYYY-MM-DD-HH-mm',
                                                        ),
                                                    renderCell: params => (
                                                        <Typography>
                                                            {moment(params.row.timestamp).format(
                                                                'h:mm A',
                                                            )}
                                                        </Typography>
                                                    ),
                                                },
                                                {
                                                    field: 'material',
                                                    headerName: 'Material',
                                                    minWidth: 200,
                                                    flex: 1,
                                                    valueGetter: params => {
                                                        const m = params.row.material
                                                        return formatMaterialAmountPhrase(
                                                            m.materialName,
                                                            m.unitSingular,
                                                            m.unitPlural,
                                                            params.row.materialAmount,
                                                        )
                                                    },
                                                },
                                                {
                                                    field: 'earnings',
                                                    headerName: 'Earnings',
                                                    minWidth: 150,
                                                    valueGetter: params =>
                                                        params.row.employeeEarnings,
                                                    valueFormatter: params =>
                                                        usdFormatter.format(params.value),
                                                },
                                                {
                                                    field: 'viewBtn',
                                                    headerName: '',
                                                    disableExport: true,
                                                    minWidth: 150,
                                                    renderCell: params => {
                                                        return (
                                                            <Button
                                                                variant="contained"
                                                                onClick={() =>
                                                                    setViewingTicketId(
                                                                        params.row.id,
                                                                    )
                                                                }
                                                            >
                                                                View
                                                            </Button>
                                                        )
                                                    },
                                                },
                                            ]}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Box>
            </Card>
        </>
    )
}
