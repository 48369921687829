import { Cancel, Save } from '@mui/icons-material'
import { Box, Button, Divider, Grid, Modal, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { floatRegex } from '../../../../../lib/constants/regex'
import { useUpdateJobMaterialMutation } from '../../../../../graphql/gen/hooks'
import { SimpleModal } from '../../../../../components/SimpleModal'
import { JobQuery } from '../../../../../graphql/gen/operations'
import { formatMaterialAmount } from '../../../../../lib/formatting'

type Props = {
    open: boolean
    onClose: () => void
    refetch: () => void
    material: JobQuery['job']['materials'][0]
}

const formSchema = yup.object().shape({
    pricePerUnit: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the price per unit of material'),
    pricePerUnitEmployee: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the employee earnings per unit of material'),
    expectedMaterialPerTicket: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the expected units of material per ticket'),
    expectedAmountForJob: yup
        .string()
        .matches(floatRegex, 'Must be a valid number')
        .required('You must specify the expected units of material for the job'),
})

export const UpdateJobMaterialModal: React.FC<Props> = ({ open, onClose, refetch, material }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { handleSubmit, register, formState, setValue, watch } = useForm<{
        expectedMaterialPerTicket: string
        expectedAmountForJob: string
        pricePerUnit: string
        pricePerUnitEmployee: string
    }>({
        resolver: yupResolver(formSchema),
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            expectedMaterialPerTicket: formatMaterialAmount(
                material.expectedAmountPerTicket,
            ).toString(),
            expectedAmountForJob: formatMaterialAmount(material.expectedAmountTotal).toString(),
            pricePerUnit: formatMaterialAmount(material.effectivePricePerUnit).toString(),
            pricePerUnitEmployee: formatMaterialAmount(material.pricePerUnitEmployee).toString(),
        },
    })
    const formErrors = formState.errors
    const [updateJobMaterial] = useUpdateJobMaterialMutation()
    const onSubmit = handleSubmit(async data => {
        await updateJobMaterial({
            variables: {
                materialId: material.id,
                materialInput: {
                    expectedAmountPerTicket: parseFloat(data.expectedMaterialPerTicket),
                    expectedAmountForJob: parseFloat(data.expectedAmountForJob),
                    pricePerUnit: parseFloat(data.pricePerUnit),
                    pricePerUnitEmployee: parseFloat(data.pricePerUnitEmployee),
                },
            },
        })
        await refetch()
        onClose()
        enqueueSnackbar('Successfully created new material', {
            variant: 'success',
        })
    })
    return (
        <Modal open={open} onClose={onClose}>
            <SimpleModal onClickOutside={onClose} width="md">
                <Typography variant="h5">
                    Update material - {material.unitPlural} of {material.materialName}
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                    <Divider sx={{ marginY: 2 }} />
                    <Typography marginBottom={2} variant="h5">
                        Expected work
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="number"
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                label={`Expected ${material.unitPlural} of ${material.materialName} per ticket`}
                                error={Boolean(formErrors.expectedMaterialPerTicket)}
                                helperText={
                                    formErrors.expectedMaterialPerTicket ? (
                                        <Typography>
                                            {formErrors.expectedMaterialPerTicket.message}
                                        </Typography>
                                    ) : null
                                }
                                {...register('expectedMaterialPerTicket')}
                                sx={{
                                    marginRight: 2,
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="number"
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                label={`Expected ${material.unitPlural} of ${material.materialName} for the entire job`}
                                error={Boolean(formErrors.expectedAmountForJob)}
                                helperText={
                                    formErrors.expectedAmountForJob ? (
                                        <Typography>
                                            {formErrors.expectedAmountForJob.message}
                                        </Typography>
                                    ) : null
                                }
                                {...register('expectedAmountForJob')}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginY: 2 }} />
                    <Typography variant="h5">Revenue</Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="number"
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                label={`$ Price per ${material.unitSingular} of ${material.materialName}`}
                                error={Boolean(formErrors.pricePerUnit)}
                                helperText={
                                    formErrors.pricePerUnit ? (
                                        <Typography>{formErrors.pricePerUnit.message}</Typography>
                                    ) : null
                                }
                                {...register('pricePerUnit')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="number"
                                inputProps={{
                                    inputMode: 'decimal',
                                }}
                                label={`$ Employee earnings per ${material.unitSingular} of ${material.materialName}`}
                                error={Boolean(formErrors.pricePerUnitEmployee)}
                                helperText={
                                    formErrors.pricePerUnitEmployee ? (
                                        <Typography>
                                            {formErrors.pricePerUnitEmployee.message}
                                        </Typography>
                                    ) : null
                                }
                                {...register('pricePerUnitEmployee')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                startIcon={<Cancel />}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<Save />}
                                disabled={!formState.isValid || formState.isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleModal>
        </Modal>
    )
}
