import { useContext } from 'react'
import { JobContext } from '../components/layouts/JobProvider'
import { useMapFromArray } from './useMapFromArray'

export const useJob = () => {
    const { job, refetch } = useContext(JobContext)
    const materialMap = useMapFromArray(job?.materials ?? [], material => material.id)
    if (!job) {
        throw new Error('You are calling useJob outside of <JobProvider />.')
    }
    return { job, materialMap, refetch }
}
